import { Bars2Icon, ExclamationCircleIcon, EyeIcon, EyeSlashIcon, LockClosedIcon, PhoneIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef } from 'react';
import { SCountHidden, SCountSub, SSideBar } from 'store/CustomizeApp/Appearance/sideBarAtom';

import AccessibilityIcon from '@mui/icons-material/Accessibility';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import { IMAGES } from 'utility/constant';
import { useAtom } from 'jotai';
import Tooltip from 'components/Common/Tooltip';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const SideBar: React.FC = () => {

	const [sideBarList, setSideBarList] = useAtom(SSideBar);
	const [hiddenCount, setHiddenCount] = useAtom(SCountHidden);
	const [subCount, setSubCount] = useAtom(SCountSub);

	const ContactUs = () => {
		return (
			<PhoneIcon className='w-6 h-6 text-[#707070] 2xl:w-6 2xl:h-6 md:w-6 md:h-6 sm:w-6 sm:h-6 max-sm:w-6 max-sm:h-6 ' />
		);
	};
	const Privacy = () => {
		return (
			<LockClosedIcon className='w-6 h-6 text-[#707070] 2xl:w-6 2xl:h-6 md:w-6 md:h-6 sm:w-6 sm:h-6 max-sm:w-8 max-sm:h-8 ' />
		);
	};
	const TermsOfUse = () => {
		return (
			<ExclamationCircleIcon className='w-6 h-6 text-[#707070] 2xl:w-6 2xl:h-6 md:w-6 md:h-6 sm:w-6 sm:h-6 max-sm:w-6 max-sm:h-5 ' />
		);
	};
	const Accessibility = () => {
		return (
			<AccessibilityIcon className='w-6 h-6 text-[#707070] 2xl:w-6 2xl:h-6 md:w-6 md:h-6 sm:w-6 sm:h-6 max-sm:w-5 max-sm:h-5 ' />
		);
	};

	const sideBarListPreview = (sideBarList.map((nav) => {
		return {
			id: nav.id, label: nav.label, isHidden: nav.isHidden, isSub: nav.isSub,
			icon: nav.label === 'Contact Us' ? ContactUs()
				: nav.label === 'Privacy and Policy' ? Privacy()
					: nav.label === 'Terms of Use' ? TermsOfUse()
						: nav.label === 'Accessibility' ? Accessibility()
							: ''
		};
	}));

	const onDragEnd = (result: any) => {
		if (!result.destination) {
			return; // Item dropped outside the list
		}

		const copyListItems = [...sideBarListPreview];
		const [reorderedItem] = copyListItems.splice(result.source.index, 1);
		copyListItems.splice(result.destination.index, 0, reorderedItem);

		setSideBarList(copyListItems);
	};

	// const dragItem = useRef();
	// const dragOverItem = useRef();

	// const dragStart = (e: React.DragEvent<HTMLDivElement>, position: any) => {
	// 	dragItem.current = position ;
	// };

	// const dragEnter = (e: React.DragEvent<HTMLDivElement>, position: any) => {
	// 	dragOverItem.current = position;
	// };

	// const drop = () => {
	// 	const copyListItems = [...sideBarListPreview];
	// 	const dragItemContent = copyListItems[dragItem.current === undefined ? 0 : dragItem.current];
	// 	copyListItems.splice(dragItem.current === undefined ? 0 : dragItem.current, 1);
	// 	copyListItems.splice(dragOverItem.current === undefined ? 0 : dragOverItem.current, 0, dragItemContent);
	// 	dragItem.current = undefined;
	// 	dragOverItem.current = undefined;
	// 	setSideBarList(copyListItems);	
	// };

	const onClickEyeIcon = (index: number) => {
		const newNavList = [...sideBarListPreview];
		if (hiddenCount < 3 && !newNavList[index].isHidden) {
			setHiddenCount(hiddenCount + 1);
			newNavList[index].isHidden = true;
			setSideBarList(newNavList);
		} else if (hiddenCount <= 3 && newNavList[index].isHidden) {
			setHiddenCount(hiddenCount - 1);
			newNavList[index].isHidden = false;
			setSideBarList(newNavList);
		}
	};

	const onClickIndentIcon = (index: number) => {
		const newNavList = [...sideBarListPreview];
		if (subCount < 3 && !newNavList[index].isSub && newNavList[index].label !== sideBarListPreview[Math.min(sideBarListPreview.findIndex((side) => !side.isHidden))].label) {
			setSubCount(subCount + 1);
			newNavList[index].isSub = true;
			setSideBarList(newNavList);
		} else if (subCount <= 3 && newNavList[index].isSub) {
			setSubCount(subCount - 1);
			newNavList[index].isSub = false;
			setSideBarList(newNavList);
		}
	};

	useEffect(() => {
		setHiddenCount(sideBarListPreview.filter((side) => side.isHidden).length);
		setSubCount(sideBarListPreview.filter((side) => side.isSub).length);
		sideBarListPreview.map((side, idx) => {
			if (side.isSub && sideBarListPreview[idx].label === sideBarListPreview[Math.min(sideBarListPreview.findIndex((side) => !side.isHidden))].label) {
				const newNavList = [...sideBarListPreview];
				newNavList[idx].isSub = false;
				setSideBarList(newNavList);
			}
		});
	}, [sideBarListPreview]);

	return (
		<div className='bg-white rounded-lg my-8 mr-6 w-full 2xl:my-8 2xl:mr-6 2xl:w-full lg:w-full lg:-ml-0 md:w-[93.5vw] md:mx-auto sm:w-[93.5vw] sm:-ml-[10vw] max-sm:w-[85vw]'>
			<div className='w-full px-10 rounded 2xl:px-10 md:px-10 sm:px-8 max-sm:px-[4vw]'>
				<div className='py-5 flex-row'>
					<div className='flex justify-between items-center pt-2'>
						<span className='text-[#707070] font-bold text-2xl block mb-4 2xl:mb-4 md:mb-7 sm:mb-7 max-sm:mb-7'>Side Bar</span>
						<div className='flex gap-3 items-baseline'>
							{/* -----------Video Tutorial and Help Icons------------ */}
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.CameraIcon} alt='camera icon' />
							</Tooltip>
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.InfoIcon} alt='info icon' />
							</Tooltip>
							{/* ---------------------------------------------------- */}

						</div>
					</div>

					{/* {sideBarListPreview.map((nav, index) => (
						<div key={index}
							onDragStart={(nav) => dragStart(nav, index)}
							onDragEnter={(nav) => dragEnter(nav, index)}
							onDragEnd={drop}
							className='flex items-center cursor-pointer '>
							{nav.isSub ?
								<FormatIndentIncreaseIcon
									onClick={() => onClickIndentIcon(index)}
									className='text-[#707070] mr-4 rotate-180 ml-14 2xl:ml-14 md:ml-14 sm:ml-14 max-sm:ml-6' fontSize='large' />
								:
								<FormatIndentIncreaseIcon
									onClick={() => onClickIndentIcon(index)}
									className='text-[#707070] mr-4' fontSize='large' />
							}
							<div className='rounded-md border-2 border-gray-300 w-1/2 h-16 flex justify-between px-3 items-center my-2 cursor-grab hover:bg-gray-300 2xl:px-3 2xl:w-1/2 lg:px-3 lg:w-[50vw] md:px-[10px] md:w-[80vw] sm:px-[10px] sm:w-[80vw] max-sm:px-[10px] max-sm:w-[80vw]' draggable>
								<div className='flex gap-4 items-center 2xl:gap-4 max-sm:gap-2'>
									{nav.icon}
									<span className='text-md text-[#707070] 2xl:text-md max-sm:text-sm'>{nav.label}</span>
								</div>
								<div className='flex gap-3 items-center'>
									<div onClick={() => onClickEyeIcon(index)}>
										{nav.isHidden ?
											<EyeSlashIcon className='w-6 h-6 text-[#707070] cursor-pointer 2xl:w-6 2xl:h-6 max-sm:w-5 max-sm:h-5' />
											:
											<EyeIcon className='w-6 h-6 text-[#707070] cursor-pointer 2xl:w-6 2xl:h-6 max-sm:w-5 max-sm:h-5' />
										}
									</div>
									<Bars2Icon className='w-5 h-5 text-[#707070] 2xl:w-5 2xl:h-5 max-sm:w-4 max-sm:h-4 ' />
								</div>
							</div>
						</div>
					))} */}

					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="navList">
							{(provided) => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									{sideBarListPreview.map((nav, index) => (
										<Draggable key={index} draggableId={`nav-${index}`} index={index}>
											{(provided) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}	
													className='flex flex-row items-center'												
												>
													{nav.isSub ?
														<FormatIndentIncreaseIcon
															onClick={() => onClickIndentIcon(index)}
															className='text-[#707070] mr-4 rotate-180 ml-14 2xl:ml-14 md:ml-14 sm:ml-14 max-sm:ml-6' fontSize='large' />
														:
														<FormatIndentIncreaseIcon
															onClick={() => onClickIndentIcon(index)}
															className='text-[#707070] mr-4' fontSize='large' />
													}
													<div className="rounded-md border-2 border-gray-300 w-1/2 h-16 flex justify-between px-3 items-center my-2 cursor-grab hover:bg-gray-300 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-full sm:w-full max-sm:w-[110%]" draggable>
														<div className='flex gap-4 items-center 2xl:gap-4 max-sm:gap-2'>
															{nav.icon}
															<span className='text-md text-[#707070] 2xl:text-md max-sm:text-sm'>{nav.label}</span>
														</div>
														<div className='flex gap-3 items-center'>
															<div onClick={() => onClickEyeIcon(index)}>
																{nav.isHidden ?
																	<EyeSlashIcon className='w-6 h-6 text-[#707070] cursor-pointer 2xl:w-6 2xl:h-6 max-sm:w-5 max-sm:h-5' />
																	:
																	<EyeIcon className='w-6 h-6 text-[#707070] cursor-pointer 2xl:w-6 2xl:h-6 max-sm:w-5 max-sm:h-5' />
																}
															</div>
															<Bars2Icon className='w-5 h-5 text-[#707070] 2xl:w-5 2xl:h-5 max-sm:w-4 max-sm:h-4 ' />
														</div>
													</div>
												</div>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>

				</div>
			</div>
		</div>
	);
};

export default SideBar;