import React from 'react-router-dom';
import ChooseIndustryPage from 'pages/ChooseIndustryPage';
import ContactUs from 'pages/Contacts';
import CustomizeApp from 'pages/CustomizeApp';
import LandingPage from 'pages/LandingPage';
import Pricing from 'pages/Pricing';
import { RouteObject } from 'react-router-dom';
import Container from './container';
import ThankyouMessage from 'pages/Auth/SignUp/ThankyouMessage';
import { ResellerPage } from 'pages/ResellerPage';
import Publish from 'pages/Publish';
import SignIn from 'pages/Auth/SignIn';
import SignUp from 'pages/Auth/SignUp';
import { BuildYourApp } from 'pages/LetUsBuildYourApp';
import ApproverSignIn from 'pages/Auth/Approver';
import VerifyEmail from 'pages/Auth/VerifyEmail';
import ForgotEmail from 'pages/Auth/Forgot-Password/Input-Email';
import RequestEmailForgot from 'pages/Auth/Forgot-Password/RequestEmailSent';
import ForgotInputPassword from 'pages/Auth/Forgot-Password/InputPassword';
import SuccessfullRedirectionForgot from 'pages/Auth/Forgot-Password/SuccessfullRedirection';
import GoogleAuthCallback from 'pages/Auth/GoogleAuthCallback';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import TermsOfUseLanding from 'pages/TermsOfUse';
import LinkAccount from 'pages/Auth/LinkAccount';
import FAQs from 'pages/FAQs';
import AboutUs from 'pages/AboutUs';
import Accessibility from 'pages/Accessibility';
import Error403 from 'components/Common/Error/Error403';

export const PublicRoutes : RouteObject = {
	path: '/',
	element: <Container />,
	children: [
		{
			path:'/',
			element:<LandingPage />,
		},
		{
			path:'/about',
			element:<AboutUs />,
		},
		{
			path:'/pricing',
			element:<Pricing />,
		},
		{
			path:'/faqs',
			element:<FAQs />,
		},
		{
			path:'/contact-us',
			element:<ContactUs />,
		},
		{
			path:'/become-a-reseller',
			element:<ResellerPage />,
		},
		{
			path:'/build',
			element:<BuildYourApp />,
		},
		{
			path:'/thankyou-signup',
			element:<ThankyouMessage />,
		},
		{
			path:'/login',
			element:<SignIn />,
		},
		{
			path:'/signup',
			element:<SignUp />,
		},
		{
			path:'/approver/login',
			element:<ApproverSignIn />,
		},
		{
			path:'/verify-email',
			element:<VerifyEmail />,
		},
		{
			path:'/forgot-email',
			element:<ForgotEmail />,
		},
		{
			path:'/forgot-request-email',
			element:<RequestEmailForgot />,
		},
		{
			path:'/reset-password',
			element:<ForgotInputPassword />,
		},
		{
			path:'/success-forgot-password',
			element:<SuccessfullRedirectionForgot />,
		},
		{
			path:'/oauth2/callback',
			element:<GoogleAuthCallback />,
		},
		{
			path:'/privacy-policy',
			element:<PrivacyPolicy />,
		},
		{
			path:'/terms-of-use',
			element:<TermsOfUseLanding />,
		},
		{
			path:'/accessibility',
			element:<Accessibility/>,
		},
		{
			path: '/login-actions/google-sign-in',
			element: <LinkAccount />,
		},
		{
			path: '/error-403',
			element: <Error403 />,
		}
		
	],
};