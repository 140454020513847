import * as paymentApis from '../../../../services/requests/Payment/index';
import * as subscriptionAtom from '../../../../store/CustomizeApp/Payment/subscription';
import * as typeSchemas from '../../../../services/requests/Payment/schema';

import { ISubscriptionDetails, freePriceId, litePriceId, monthlyPriceId, subscriptionPlans } from 'pages/CustomizeApp/Payment/Content/oldIndex';
import { PromoCodeParams, PromoCodeResponse } from 'services/requests/CouponDiscount/schema';
import React, { useEffect, useState } from 'react';
import { classNames, stripeCurrencyToAmount, stripeCurrencyToPercent } from 'utility';
import { couponCodeApply, saveCardInfo } from 'store/Payment/successPaymentAtom';
import { showFailedAlert, showSuccessAlert } from 'utility/alerts';
import { useAtom, useAtomValue } from 'jotai';

import Accordion from '@mui/material/Accordion/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary/AccordionSummary';
import { ActiveStep } from 'store/CustomizeApp/Payment/activeStepAtom';
import { AxiosError } from 'axios';
import Box from '@mui/material/Box/Box';
import Button from 'components/Common/Buttons/Button';
import FormInput from 'components/Common/Forms/Inputs';
import { PromoCodeApi } from 'services/requests/CouponDiscount';
import { RadioPaymentMethod } from 'components/Common/RadioPaymentMethod';
import Typography from '@mui/material/Typography/Typography';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import Spinner from 'components/Common/Spinner';

const CustomExpandIcon = () => {
	return (
		<Box
			sx={{
				'.Mui-expanded & > .collapsIconWrapper': {
					display: 'none',
					color: 'black'
				},
				'.expandIconWrapper': {
					display: 'none',
				},
				'.Mui-expanded & > .expandIconWrapper': {
					display: 'block',
					color: 'black'
				}
			}}
		>
			<div className='expandIconWrapper'>-</div>
			<div className='collapsIconWrapper'>+</div>
		</Box>
	);
};

export const PaymentMethodForm: React.FC = () => {

	const { id } = useParams();

	const FREE_TRIAL_PRICE_ID                            = freePriceId;

	const [cardBrand]                                    = useAtom(subscriptionAtom.FCardBrand);
	const [cardNumberError]                              = useAtom(subscriptionAtom.FCardNumberError);
	const [cardExpiryError]                              = useAtom(subscriptionAtom.FExpiryError);
	const [cardCVCError]                                 = useAtom(subscriptionAtom.FCVCError);
	const [, setInlineError]                             = useAtom(subscriptionAtom.FInlineError);
	const [, setActiveStep]                              = useAtom(ActiveStep);
	const [couponApplied, setCouponApplied]        		 = useAtom(couponCodeApply);
	const [selectedPlanPriceId]							 = useAtom(subscriptionAtom.planPriceID);
	const [paymentMethodID]								 = useAtom(subscriptionAtom.paymentMethodID);
	const [paymentMethodError]							 = useAtom(subscriptionAtom.paymentMethodError);
	const [inputValue, setInputValue]			         = useAtom(subscriptionAtom.promoCodeValue);
	const [promoID, setPromoID]							 = useAtom(subscriptionAtom.promoID);
	const [couponAmount, setCouponAmount]				 = useAtom(subscriptionAtom.couponAmountCode);
	const [couponPercent, setCouponPercent]				 = useAtom(subscriptionAtom.couponPercentCode);
	const isSavedCard  									 = useAtomValue(saveCardInfo);
	const [hasCardId, setHasCardId] 					 = useState(false);
	const isDefaultSelected 							 = useAtomValue(subscriptionAtom.isDefaultSelected);

	const queryClient = useQueryClient();

	const formValidation = () => {
		if (selectedPlanPriceId === FREE_TRIAL_PRICE_ID //free trial
			|| cardBrand === '' 
			|| cardBrand === 'unknown'
		) return true;

		if (cardNumberError !== '') return true;
		if (cardExpiryError !== '') return true;
		if (cardCVCError	!== '') return true;

		return false;
	};

	// if(defaultId){
	// 	setCardId(true);
	// }

	useEffect(()=> {
		if(paymentMethodID !== '' && paymentMethodID !== undefined){
			setHasCardId(true);
		} else {
			setHasCardId(false);
		}

	}, [paymentMethodID]);

	const { mutate: setPaymentData, isLoading: isPaymentLoading } = 
	useMutation<typeSchemas.SubscriptionResponse, AxiosError<any>, typeSchemas.SubscriptionParams>(
		data => paymentApis.SubscribePerAppId(data), {
			onSuccess: () => {
				queryClient.invalidateQueries('pwa-apps');
				showSuccessAlert('You have successfully subscribed to the plan');
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			},
			onError: (error) => {
				const err = error?.response?.data?.error?.message;
				if (err) {
					const errParse = JSON.parse(err);
					const errMsg = errParse?.decline_code && errParse.message;
	
					setInlineError(errMsg); // optional, to delete if not needed
					showFailedAlert(errMsg);
				}
				else
				{
					setInlineError(error);
					showFailedAlert('Payment Failed');
				}
			}
		}
	);

	const {
		mutate: applyPromoCodeMu,
	} = useMutation<PromoCodeResponse, AxiosError, PromoCodeParams>(
		(data) => PromoCodeApi(id, data), {
			onSuccess: (data) => {
				setPromoID(data.Id);
				setCouponAmount(data.CouponAmountOff);
				setCouponPercent(data.CouponPercentOff);
				showSuccessAlert('Coupon code applied successfully.');	
				setCouponApplied(true);
			},
			onError: () => {
				showFailedAlert('Applied promo code invalid');
			}
		}
	);

	const handleSubmit = () => {
		if (paymentMethodError === undefined) {
			setPaymentData({
				appId:  id?.toString(),
				priceId:  selectedPlanPriceId, // to change to id int
				paymentMethodId: paymentMethodID,
				promoCode: promoID , // id of promo in string
			});
		} else {
			showFailedAlert('Payment Failed');
		}
	};
	
	const handleCoupon = () => {
		applyPromoCodeMu({code: inputValue});
	};

	// const handleCheck = () => {
	// 	log('CARD SELECTED: ', hasCardId);
	// 	log('CARD ID : ', paymentMethodID);
	// 	log('paymentMethodID != undefined: ', (paymentMethodID !== '' && paymentMethodID !== undefined));
	// 	log('isLoading? ' ,isLoading);
	// };

	const handleRemoveCoupon = () => {
		setInputValue('');
		setPromoID('');
		setCouponApplied(false);
		setCouponAmount(0);
		setCouponPercent(0);
	};
 
	const currencyFormat = (num: number) => {
		return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
	};

	return (
		<>
			<div className='flex gap-5 w-full justify-center 2xl:flex-row 2xl:w-full xl:flex-row xl:w-full lg:flex-row lg:w-full md:flex-col md:w-[65%] sm:flex-col sm:w-[80%] max-sm:flex-col max-sm:w-full'>
				<div className='bg-white shadow-lg pt-6 font-semibold px-9 rounded-[0.5rem] 2xl:pb-0 xl:pb-0 lg:pb-0 md:pb-10 sm:pb-10 max-sm:pb-10'>
					<span className='text-xl text-[#464255]'>Payment Method</span>
					<hr className='my-5 mb-7'/>
					{/* Radio Component */}
					<RadioPaymentMethod /> 
				</div>
				{subscriptionPlans.map((data: ISubscriptionDetails, index: number) => {
					if (data.PriceId === selectedPlanPriceId)
						return (
							<div key={index}>
								<div className='bg-white shadow-lg pt-[20.8px] px-9 pb-9 rounded-[0.5rem]'>
									<span className='text-lg font-semibold'>Order Summary</span>
									<hr className='my-4'/>
									<div className='flex justify-between'>
										<div>
											<span className='text-sm font-medium text-[#171414]'>{data.planTitle}</span>
											<p className='text-xs text-[#171414] mt-1'>
												${data.planPrice.toLocaleString()}/month, billed {data.planInterval.toLowerCase()}
											</p>
										</div>
										{ 
											selectedPlanPriceId === litePriceId 
												?
												<p className='text-sm font-semibold text-[#171414] mt-1 ml-[2rem]'>
													${(data.planPrice).toFixed(2)}
												</p> 
												: selectedPlanPriceId === monthlyPriceId ? 
													<p className='text-sm font-semibold text-[#171414] mt-1 ml-[2rem]'>
														${(data.planPrice * 3).toFixed(2)}
													</p> 
													: 
													<p className='text-sm font-semibold text-[#171414] mt-1 ml-[2rem]'>
															${(data.planPrice * 12).toFixed(2)}
													</p> 
										}
									</div>
									{ couponApplied &&
										<div className={'flex mt-3 mb-1 font-medium text-sm justify-between'}>
											<p>Discount ({couponPercent}%)</p>
											{	couponAmount ? 
												<p className='font-semibold text-sm'>-${stripeCurrencyToAmount(couponAmount).toFixed(2)}</p> :
												<p className='font-semibold text-sm'>
													- ${selectedPlanPriceId === litePriceId ? 
														stripeCurrencyToPercent(data.planPrice, couponPercent).toFixed(2) 
														: selectedPlanPriceId === monthlyPriceId ?  
															stripeCurrencyToPercent(data.planPrice * 3, couponPercent).toFixed(2) 
															: stripeCurrencyToPercent(data.planPrice * 12, couponPercent).toFixed(2)}
												</p>
											}
										</div>	
									}						
									<div className='flex flex-row justify-between'>
										<span className='font-semibold text-base mt-1'>Total</span>
										{couponAmount ? 
											<div>
												{ 
													selectedPlanPriceId === litePriceId 
														?
														<p className='text-base font-semibold text-[#171414] mt-1 ml-[4.20rem]'>
															{currencyFormat((data.planPrice) - stripeCurrencyToAmount(couponAmount))}
														</p> 
														: selectedPlanPriceId === monthlyPriceId ? 
															<p className='text-base font-semibold text-[#171414] mt-1 ml-[4.20rem]'>
																{currencyFormat((data.planPrice * 3) - stripeCurrencyToAmount(couponAmount))}
															</p> 
															: 
															<p className='text-base font-semibold text-[#171414] mt-1 ml-[4.20rem]'>
																{currencyFormat((data.planPrice * 12) - stripeCurrencyToAmount(couponAmount))}
															</p> 
												}
											</div>
											: couponPercent ?
												<div>
													{ 
														selectedPlanPriceId === litePriceId 
															?
															<p className='text-base font-semibold text-[#171414] mt-1 ml-[4.20rem]'>
																{currencyFormat(data.planPrice - stripeCurrencyToPercent(data.planPrice, couponPercent))}
															</p> 
															: selectedPlanPriceId === monthlyPriceId ? 
																<p className='text-base font-semibold text-[#171414] mt-1 ml-[4.20rem]'>
																	{currencyFormat((data.planPrice * 3) - stripeCurrencyToPercent(data.planPrice * 3, couponPercent))}
																</p> 
																: 
																<p className='text-base font-semibold text-[#171414] mt-1 ml-[4.20rem]'>
																	{currencyFormat((data.planPrice * 12) - stripeCurrencyToPercent(data.planPrice * 12, couponPercent))}
																</p> 
													}
												</div>
												: 
												<div className='flex flex-row gap-[145px]'>
													{ 
														selectedPlanPriceId === litePriceId 
															?
															<p className='text-base font-semibold text-[#171414] mt-1 ml-[4.20rem]'>
																${(data.planPrice).toFixed(2)}
															</p> 
															: selectedPlanPriceId === monthlyPriceId ? 
																<p className='text-base font-semibold text-[#171414] mt-1 ml-[4.20rem]'>
																	${(data.planPrice * 3).toFixed(2)}
																</p> 
																: 
																<p className='text-base font-semibold text-[#171414] mt-1 ml-[4.20rem]'>
																	${(data.planPrice * 12).toFixed(2)}
																</p> 
													}
												</div>
													
										}
									</div>
									<hr className='mt-5'/>
									{/* Accordion Component */}
									<Accordion  sx={{boxShadow: 'none', '&:before': {display: 'none'}, margin: '0px !important'}}>
										<AccordionSummary
											expandIcon={<CustomExpandIcon/>}
											aria-controls='panel1a-content'
											id='panel1a-header'
										>
											<Typography 
												sx={{
													color: '#171414', 
													fontFamily: 'Poppins', 
													fontSize: '0.875rem', 
													fontWeight: '600', 
													marginLeft: '-16px'
												}}>
													Coupon Code
											</Typography>
										</AccordionSummary>
										<AccordionDetails sx={{marginLeft: '-16px'}}>
											<div className='flex justify-between max-sm:block'>
												<FormInput 
													type='text' 
													placeholder='Enter Coupon Code' 
													className={classNames('text-[0.65rem] py-2 px-2 h-9 w-full', couponApplied ? 'bg-zinc-100' : 'bg-white')}
													value={inputValue}
													onChange={(e) => setInputValue(e)}
													disabled={couponApplied}
												/>
												{ !couponApplied ?
													<Button 
														variant='primary' 
														className='px-6 text-sm ml-2 -mr-2 h-9 max-sm:w-full max-sm:mr-0 max-sm:ml-0 max-sm:my-2'
														onClick={handleCoupon}
														disabled={!inputValue}>
													Apply
													</Button>
													: <Button 
														variant='gray' 
														className='px-[0.95rem] text-sm ml-2 -mr-2 h-9 font-semibold max-sm:w-full max-sm:mr-0 max-sm:ml-0 max-sm:my-2'
														onClick={handleRemoveCoupon}>
														REMOVE
													</Button>
												}
											</div>
										</AccordionDetails>
									</Accordion>		
									<hr className='my-2'/>
									<div className='mt-3 flex justify-center items-center'>
										<Button
											variant='primary'
											className={classNames(
												'my-5 px-[3.5rem] py-2 rounded-md text-white flex items-center justify-center gap-4' 
											)}
											// disabled={ isLoading || !hasCardId}
											disabled={!isDefaultSelected || isPaymentLoading}
											onClick={handleSubmit}
										>
											{isPaymentLoading && <Spinner className='' />}
											{isPaymentLoading ? 'Proceeding...' : 'Proceed'}
										</Button>
									</div>

									{/* <div className='mt-3 flex justify-center items-center'>
										<Button
											variant='primary'
											className={classNames(
												'my-5 px-[3.5rem] py-2 rounded-md text-white'
											)}
											//disabled={ isLoading || hasCardId }
											onClick={handleCheck}
										>
											Check
										</Button>
									</div> */}
								</div>
							</div>						
						);
				})}
			</div>
		</>
	);
};