/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import Switch from 'react-switch';
import { IMAGES, classNames } from 'utility';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

library.add(fab, far);

interface IContentModal {
	isContentModalShow: boolean;
	setContentModalShow: React.Dispatch<React.SetStateAction<boolean>>;
	onClickCancel: (e: any) => void;
	onClick: (data: string) => void;
}

export const ContentModal: React.FC<IContentModal> = ({
	isContentModalShow,
	setContentModalShow,
	onClickCancel,
	onClick,
}) => {
	const [isChecked, setIsChecked] = useState({ checked: false });
	const [filteredRows, setFilteredRows] = useState<Array<string>>([]);
	const [search, setSearch] = useState<string>('');

	const handleChange = () => {
		if (isChecked.checked === false) {
			setIsChecked({ checked: true });
		} else {
			setIsChecked({ checked: false });
		}
	};

	const farIcons = Object.keys(far).map(iconKey => ({
		key: iconKey,
		...far[iconKey]
	}));

	const fabIcons = Object.keys(fab).map(iconKey => ({
		key: iconKey,
		...fab[iconKey]
	}));

	const allIcons = [...farIcons, ...fabIcons];

	const parsedIcons = allIcons.map((icon) => {
		return `${icon.prefix} fa-${icon.iconName}`;
	}); 

	const uniqueParsedIcons = [...new Set(parsedIcons)];

	const handleSearch = () => {
		const query = search.toLowerCase();

		const filtered = uniqueParsedIcons.filter((icon) =>
			icon.toLowerCase().includes(query)
		);

		setFilteredRows(filtered);
	};

	useEffect(() => {
		setFilteredRows(uniqueParsedIcons);

		if (!isContentModalShow) setSearch('');
	}, [isChecked, isContentModalShow]);

	useEffect(() => {
		handleSearch();
	}, [search, isChecked]);

	const renderContentModal = (): JSX.Element => {
		return (
			<>
				<div className='sm:mx-auto sm:w-full max-w-2xl h-max rounded'>
					<div className='h-12 bg-slate-200 rounded-tl-lg rounded-tr-lg'>
						<div className='relative flex flex-row'>
							<span className='absolute flex-1 left-3 top-3 font-normal'>
								Select Icon
							</span>
							<button
								onClick={onClickCancel}
								className='absolute flex-1 right-2 top-1 font-bold opacity-60 text-sm'
							>
								<FontAwesomeIcon icon={faXmark} />
							</button>
						</div>
					</div>
					<div className='sm:rounded'>
						<div className='flex flex-row justify-between'>
							<div className='flex flex-row px-2 py-7'>
								<span className='text-sm px-4 text-[#46425599]'>
									Solid Icons
								</span>
								<Switch
									handleDiameter={20}
									uncheckedIcon={false}
									checkedIcon={false}
									height={15}
									width={52}
									onHandleColor={'#FF7F20'}
									offHandleColor={'#FF7F20'}
									onColor={'#DCE5FD'}
									offColor={'#C0C0C0'}
									onChange={handleChange}
									checked={isChecked.checked}
								/>
							</div>
							<form className='mx-4 max-sm:mx-0 rounded-full relative w-[16.516rem] h-[5vh] py-4'>
								<input
									onChange={(e) => setSearch(e.target.value)}
									className='max-sm:text-[8px] w-full h-full block rounded-full text-[10px] py-[18px] px-3 border focus:ring-1 focus:border-input-active outline-none'
									type='search'
									placeholder='Enter keyword to search icon'									
								/>
								<img
									className='flex absolute top-[25px] right-3 cursor-pointer'
									src={IMAGES.SearchIcon}
									alt='Search Icon'
								/>
							</form>
						</div>
						<div className={classNames(
							filteredRows.length > 0 ? 'grid grid-cols-8 auto-rows-min' : 'flex justify-center items-center',
							'mr-1 mb-6 px-6 h-[45vh] overflow-y-auto'
						)}>
							{
								filteredRows.length > 0 
								? isChecked.checked === true
									? filteredRows?.map((icon, key) => (
										<button
											key={key}
											onClick={() =>
												onClick(`${icon} fa-solid`)
											}
											className='cursor-pointer py-3'
										>
											<i
												className={`${icon} fa-solid fa-md text-[#CDCDCD] hover:text-primary`}
											/>
										</button>
									))
									: filteredRows?.map((icon, key) => (
											<button
												key={key}
												onClick={() =>
													onClick(
														icon
													)
												}
												className='cursor-pointer py-3'
											>
												<i
													className={`${icon} fa-md text-[#CDCDCD] hover:text-primary`}
												/>
											</button>
									))
								:<EmptyState />
							}
						</div>
					</div>
				</div>
			</>
		);
	};
	return (
		<div>
			<Modal
				open={isContentModalShow}
				onClose={setContentModalShow}
				className='w-[32.838rem] max-[400px]:max-w-xs max-w-sm md:max-w-2xl'
			>
				{renderContentModal()}
			</Modal>
		</div>
	);
};

const EmptyState = () => {
	return (
		<div className='flex flex-col justify-center items-center w-full gap-2 p-4'>
			<img
				src={IMAGES.EmptyState}
				alt='Empty Search Results Logo'
				className='w-[10.438rem]'
			/>

			<h1 className='font-semibold text-2xl'>No Results Found</h1>

			<p className='text-center text-[#707070]'>
				It looks like we couldn&apos;t find any icons that match your search. Try searching again.
			</p>
		</div>
	);
};