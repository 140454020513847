import React from 'react';
import { Link } from 'react-router-dom';
import { IMAGES } from 'utility/constant';
import PubishTabPreviewLink from '../PreviewLink';

const AppInReview: React.FC = () => {

	return (
		<div className='w-full h-[90vh] flex flex-col justify-center items-center bg-[#F6F6F6]'>
			<div className='p-4'>
				<img
					className='w-[230px] h-[200px]'
					src={IMAGES.inReviewImage}
					alt='envelope image'
				/>
			</div>
			<div className='p-2 font-light'>
				<h1 className='text-cente text-2xl font-extrabold text-[#FF7F20] tracking-wide'>
          Congratulations!
				</h1>
			</div>
			<div className='flex items-center flex-col p-2 w-1/2 mb-7 text-[#707070] text-[15px] font-normal 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-w-full max-sm:w-full'>
				<p className='text-center'>
          You have successfully submitted your mobile app.
				</p>
				<p className='mt-4 text-center w-[80%] '>
				We’ll make sure to keep you in the loop once we’ve completed our <br /> review of your mobile app. In the meantime, feel free to check your <br /> dashboard for any updates on its status
				</p>
			</div>
			<div className='flex flex-row w-full py-5 pb-8 -mt-8 items-center justify-center gap-6 2xl:justify-center 2xl:ml-0 xl:justify-center xl:ml-0 lg:justify-center lg:ml-0 md:justify-center sm:justify-center max-sm:justify-center max-sm:px-[40px]'>
				<span>URL:</span>
				<div className='w-[35%] 2xl:w-[35%] xl:w-[35%] lg:w-[35%] md:w-[360px] sm:w-[360px] max-sm:w-[360px]'>
					<PubishTabPreviewLink />
				</div>
			</div>
			<Link
				id='nav-link'
				to='/dashboard'
				className='w-1/4 py-2 text-sm flex justify-center font-semibold items-center bg-[#E0781D] border rounded-[5px] shadow-sm focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed hover:bg-[#FF491A] border-transparent disabled:bg-primary text-white 2xl:w-[480px] xl:w-[480px] lg:w-[480px] md:w-[360px] sm:w-[360px] max-sm:w-[250px]'
			>
        Check Dashboard
			</Link>
		</div>
	);
};

export default AppInReview;
