import * as subscriptionAtom from '../../store/CustomizeApp/Payment/subscription';

import { AppFeatIsSaving, SAppNameChanges, SAppearanceTab, SBackgroundID, SColorID, SFaviconID, SFlashScreenID, SFontID, SIsAppRefresh, SIsAppearanceChanges, SIsAppearanceRefresh, SIsBackgroundChanges, SIsColorChanges, SIsFaviconChanges, SIsFlashChanges, SIsFontChanges, SIsLayoutChanges, SIsNavigationChanges, SIsOgTagsChanges, SIsSideBarChanges, SIsThemeChanges, SIsThemeLayoutRefresh, SNavigationID, SOgTagsID, SSideBarID, SisAppDone, SisBackgroundDone, SisColorDone, SisFaviconDone, SisFlashDone, SisFontDone, SisNavigationDone, SisOgTagsDone, SisSideBarDone, SisThemeLayoutDone } from 'store/CustomizeApp/Appearance';
import { FAbout, FAboutID, FIsAboutRefresh } from 'store/CustomizeApp/Features/aboutAtom';
import { FAccessibility, FAccessibilityID, FIsAccessibilityRefresh } from 'store/CustomizeApp/Features/accessibility';
import { FContact, FContactID, FIsContactRefresh } from 'store/CustomizeApp/Features/contact';
import { FContents, FContentsRefresh } from 'store/CustomizeApp/Features/contentAtom';
import { FDeleteAlbumImagesAtom, FGallery, FGalleryAlbumIdAtom, FGalleryImageAtom, FIsGalleryRefresh, FUpdateGallery } from 'store/CustomizeApp/Features/galleryAtom';
import { FDeleteSocialIdsAtom, FSocialMediasAtom, FsocialMediasRefreshAtom } from 'store/CustomizeApp/Features/socialMediaAtom';
import { FHelp, FHelpID, FIsHelpRefresh } from 'store/CustomizeApp/Features/helpAtom';
import { FHomeDescription, FHomeID, FHomeImgID, FHomePreviewImage, FHomeUploadFile, FIsHomeRefresh } from 'store/CustomizeApp/Features/homeAtom';
import { FIsAboutChanges, FIsAccessChanges, FIsContactChanges, FIsContentChanges, FIsFeaturesChange, FIsGalleryChanges, FIsHelpChanges, FIsHomeChanges, FIsNotifChanges, FIsOffersChanges, FIsPrivacyChanges, FIsSocialMediaChanges, FIsStoreChanges, FIsTermsChanges, SFeaturesTab } from 'store/CustomizeApp/Features';
import { FIsOfferRefresh, FTempIds, FTempOffers } from 'store/CustomizeApp/Features/offersAtom';
import { FIsPrivacyRefresh, FPrivacy, FPrivacyID } from 'store/CustomizeApp/Features/privacy';
import { FIsStoreRefresh, FStore, FStoreID } from 'store/CustomizeApp/Features/storeAtom';
import { FIsTermsRefresh, FTerms, FTermsID } from 'store/CustomizeApp/Features/terms';
import React, { useEffect, useState } from 'react';
import { SBBackgroundCategory, SBBackgroundColor, SBBackgroundFile, SBBackgroundImage, SBBackgroundRefresh, SBBlurBackgroundImage, SBCustomBackgroundID, SBDefaultBackgroundImageID, SBIsCustomBackgroundFocus, SBOpacityBackgroundImage, SBPreviewBackgroundImage } from 'store/CustomizeApp/Appearance/backgroundAtom';
import { SBackgroundColor, SBackgroundImage, SBlurBackgroundImage, SCustomBackgroundID, SCustomBgFocus, SFlashBackgroundID, SFlashCustomBackgroundFile, SFlashLogoFile, SIcon, SIndustrySubCategory, SIndustySubCategories, SIsFlashRefresh, SLogoID, SOpacityFlashBackgroundImage, SOverlayColor, SOverlayValue, SPreviewBackgroundImage } from 'store/CustomizeApp/Appearance/flashScreenAtom';
import { SSubCategErrorMessage, SSubCategErrorModalShow } from 'store/subCategEmptyAtom';
import { couponCodeApply, saveCardInfo } from 'store/Payment/successPaymentAtom';
import { useAtom, useSetAtom } from 'jotai';
import { useMutation, useQueryClient } from 'react-query';

import { ActiveStep } from 'store/CustomizeApp/Payment/activeStepAtom';
import { DeleteModal } from 'components/Common/Modals/DeleteModal';
import { FIsAlreadySubscribed } from 'store/CustomizeApp/Payment/subscription';
import { FIsNotifcationRefresh } from 'store/CustomizeApp/Features/notificationAtom';
import { IMAGES } from 'utility/constant';
import { Link, useNavigate } from 'react-router-dom';
import { SIsColorRefresh } from 'store/CustomizeApp/Appearance/colorAtom';
import { SIsFontRefresh } from 'store/CustomizeApp/Appearance/fontAtom';
import { SIsNavRefresh } from 'store/CustomizeApp/Appearance/navigationAtom';
import { SIsSidebarRefresh } from 'store/CustomizeApp/Appearance/sideBarAtom';
import { SLayoutId } from 'store/CustomizeApp/Appearance/layoutAtom';
import { SThemeId } from 'store/CustomizeApp/Appearance/themeAtom';
import { classNames } from 'utility';
import { deleteAppsById } from 'services/requests/AccountManagement/DeleteApp';
import { selectedImages } from 'pages/CustomizeApp/Features/Gallery';
import { showSuccessAlert } from 'utility/alerts';
import { stepperAtom } from 'store/CustomizeApp/Stepper';
import { useMediaQuery } from 'react-responsive';
import { SFaviconImgID, SFaviconPreviewImage, SFaviconUploadFile, SIsFaviconRefresh } from 'store/CustomizeApp/Appearance/imagesAtoms/favicon';
import { SOgTagsPreviewImage, SOgTagsImgID, SIsOgTagsRefresh, SOgTagsUploadFile } from 'store/CustomizeApp/Appearance/imagesAtoms/ogTagsAtoms';

interface IManageAppCard {
  appID: number;
  appName: string;
  image?: string | undefined;
  className?: string;
  openSidebar?: () => void;
  activeCard?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>; 
  status? : string;
  bgColor? : string;
}

const ManageAppCard: React.FC<IManageAppCard> = ({openSidebar, image, activeCard, appName, appID, status}) => {
	const navigate = useNavigate();

	const [ deleteModalShow, setDeleteModalShow ] = useState(false);
	const [ appStatus, setAppStatus ] = useState('');
	const [ appTextColor, setappTextColor ] = useState('');

	const [, setCurrentStep] = useAtom(stepperAtom);
	const [, setSelectedAppearanceTab] =
		useAtom(SAppearanceTab);
	const [, setSelectedFeatures] = useAtom(SFeaturesTab);
	const [, setSelectedBackgroundID] = useAtom(SBackgroundID);
	const setIsAppearanceChanges = useSetAtom(SIsAppearanceChanges);
	const setAppDone = useSetAtom(SisAppDone);

	const setIsSaving = useSetAtom(AppFeatIsSaving);
	const setIsAppRefresh = useSetAtom(SIsAppRefresh);

	//Atoms for Flash Screen
	const setSubCategories = useSetAtom(SIndustySubCategories);
	const [, setFlashLogoID] = useAtom(SLogoID);
	const [, setFlashCustomBgID] = useAtom(SCustomBackgroundID);
	const [, setSelectedFlashScreenID] =
		useAtom(SFlashScreenID);
	const [, setSelectedNavigationID] = useAtom(SNavigationID);
	const [, setSelectedSideBarID] = useAtom(SSideBarID);
	const [, setSelectedColorID] = useAtom(SColorID);
	const [, setSelectedFontID] = useAtom(SFontID);
	const [, setFlashBackgroundColor] =
		useAtom(SBackgroundColor);
	const [, setFlashOverlayColor] = useAtom(SOverlayColor);
	const [, setFlashOverlayOpacity] = useAtom(SOverlayValue);
	const [, setFlashBackgroundImageOpacity] = useAtom(
		SOpacityFlashBackgroundImage
	);
	const [, setFlashBackgroundImageBlur] =
		useAtom(SBlurBackgroundImage);
	const [, setFlashBackgroundID] = useAtom(SFlashBackgroundID);
	const [, setFlashLogoURL] = useAtom(SIcon);
	const [, setIsFlashCustomBgFocus] =
		useAtom(SCustomBgFocus);
	const [, setFlashPreviewBackground] = useAtom(SPreviewBackgroundImage);
	const [, setFlashBackgroundImage] = useAtom(SBackgroundImage);
	const [category , setCategory] = useAtom(SIndustrySubCategory);
	const [, setIsFlashRefresh] = useAtom(SIsFlashRefresh);
	const setFlashCustomBackgroundFile 	  = useSetAtom(SFlashCustomBackgroundFile);
	const setFlashLogoFile 	              = useSetAtom(SFlashLogoFile);
	const setSubCategModalShow  = useSetAtom(SSubCategErrorModalShow);
	const setSubCategErrorMessage = useSetAtom(SSubCategErrorMessage);
	const setAppearanceRefresh = useSetAtom(SIsAppearanceRefresh);

	//Atoms for Layout
	const [, setSelectedLayoutID] = useAtom(SLayoutId);

	//Atoms for Theme
	const [, setSelectedThemeID] = useAtom(SThemeId);

	const setThemeLayoutRefresh = useSetAtom(SIsThemeLayoutRefresh);

	//Atoms for Background
	const [, setOpacityBackgroundImage] = useAtom(
		SBOpacityBackgroundImage
	);
	const [, setBlurBackgroundValue] = useAtom(
		SBBlurBackgroundImage
	);
	const [, setColorBackground] = useAtom(SBBackgroundColor);
	const [, setBackgroundID] = useAtom(SBDefaultBackgroundImageID);
	const [, setCustomBackgroundID] =
		useAtom(SBCustomBackgroundID);
	const [, setAppPreviewBackground] = useAtom(SBPreviewBackgroundImage);
	const [, setIsBgRefresh] = useAtom(SBBackgroundRefresh);
	const [, setCustomBackgroundFocus] = useAtom(
		SBIsCustomBackgroundFocus
	);
	const [, setBackgroundImage] = useAtom(SBBackgroundImage);
	const [, setBackgroundCategory] = useAtom(SBBackgroundCategory);
	const setBackgroundFile = useSetAtom(SBBackgroundFile);

	//Atoms for Navigation
	const [, setNavRefresh] = useAtom(SIsNavRefresh);

	//Atoms for Sidebar
	const [, setSidebarRefresh] = useAtom(SIsSidebarRefresh);

	//Atoms for Color
	const [, setIsColorRefresh] = useAtom(SIsColorRefresh);

	//Atoms for Font
	const [, setIsFontRefresh] = useAtom(SIsFontRefresh);

	//Atoms for Favicon
	const [, setSelectedFaviconID] = useAtom(SFaviconID);
	const [, setFaviconImg]        = useAtom(SFaviconPreviewImage);
	const [, setFaviconImgID]	   = useAtom(SFaviconImgID);
	const [, setIsFaviconRefresh]  = useAtom(SIsFaviconRefresh);
	const [, setFaviconImgFile]	   = useAtom(SFaviconUploadFile);
	const setFaviconChanges		   = useSetAtom(SIsFaviconChanges);

	//Atoms for OgTags
	const [, setSelectedOgTagsID] = useAtom(SOgTagsID);
	const [, setOgTagsImg]        = useAtom(SOgTagsPreviewImage);
	const [, setOgTagsImgID]	  = useAtom(SOgTagsImgID);
	const [, setIsOgTagsRefresh]  = useAtom(SIsOgTagsRefresh);
	const [, setOgTagsImgFile]	  = useAtom(SOgTagsUploadFile);
	const setOgTagsChanges        = useSetAtom(SIsOgTagsChanges);
	
	//Atoms for Home
	const [, setHomeID] = useAtom(FHomeID);
	const [, setHomeImg] = useAtom(FHomePreviewImage);
	const [, setHomeDesc] = useAtom(FHomeDescription);
	const [, setHomeImgID] = useAtom(FHomeImgID);
	const [, setIsHomeRefresh] = useAtom(FIsHomeRefresh);
	const [, setImageFile] = useAtom(FHomeUploadFile);

	//Atoms for Store
	const [, setStoreName]              = useAtom(FStore);
	const [, setStoreID]                = useAtom(FStoreID);
	const [, setIsStoreRefresh]         = useAtom(FIsStoreRefresh);

	//Atoms for About
	const [, setAboutDesc] = useAtom(FAbout);
	const [, setAboutID] = useAtom(FAboutID);
	const [, setIsAboutRefresh] = useAtom(FIsAboutRefresh);

	//Atoms for Help
	const [, setHelpDesc] = useAtom(FHelp);
	const [, setHelpID] = useAtom(FHelpID);
	const [, setIsHelpRefresh] = useAtom(FIsHelpRefresh);

	//Atom for Privacy
	const [, setPrivacyDesc] = useAtom(FPrivacy);
	const [, setPrivacyID] = useAtom(FPrivacyID);
	const [, setIsPrivacyRefresh] = useAtom(FIsPrivacyRefresh);

	//Atom for Terms
	const [, setTermsDesc] = useAtom(FTerms);
	const [, setTermsID] = useAtom(FTermsID);
	const [, setIsTermsRefresh] = useAtom(FIsTermsRefresh);

	//Atom for Accessibility
	const [, setAccessibilityDesc] = useAtom(FAccessibility);
	const [, setAccessibilityID] = useAtom(FAccessibilityID);
	const [, setIsAccessRefresh] = useAtom(FIsAccessibilityRefresh);

	//Atoms for Contact
	const [, setContactDesc] = useAtom(FContact);
	const [, setContactID] = useAtom(FContactID);
	const [, setIsContactRefresh] = useAtom(FIsContactRefresh);

	//Atoms for Gallery
	const [, setAlbum] 					= useAtom(FGallery);
	const [, setUpdateAlbum] 					= useAtom(FUpdateGallery);
	const [, setIsGalleryRefresh]						= useAtom(FIsGalleryRefresh);
	const [, getGalleryAlbumId] = useAtom(FGalleryAlbumIdAtom);
	const [, setImageUploadId] = useAtom(FDeleteAlbumImagesAtom);
	const [,setAccordionImages] = useAtom(FGalleryImageAtom);
	const [, setSelectedImages] = useAtom(selectedImages);

	
	//Atoms for Social Media
	const [,setSocialMedias] = useAtom(FSocialMediasAtom);
	const [,setIsSocMedRefresh] = useAtom(FsocialMediasRefreshAtom);
	const [,setSocialMediaIds] = useAtom(FDeleteSocialIdsAtom);

	//Atoms for Offers
	const setOffer = useSetAtom(FTempOffers);
	const setIsOfferRefresh = useSetAtom(FIsOfferRefresh);
	const setOfferDeleteId = useSetAtom(FTempIds);
	
	//Atoms for Notifications

	const [, setIsNotifRefresh] = useAtom(FIsNotifcationRefresh);

	//Content Refresh
	const [, setContentRefresh]	                    = useAtom(FContentsRefresh);
	const [contentList, setContentList]             = useAtom(FContents);

	const setFlashDone = useSetAtom(SisFlashDone);
	const setThemeLayoutDone = useSetAtom(SisThemeLayoutDone);
	const setBackgroundDone = useSetAtom(SisBackgroundDone);
	const setNavDone = useSetAtom(SisNavigationDone);
	const setSidebarDone = useSetAtom(SisSideBarDone);
	const setColorDone = useSetAtom(SisColorDone);
	const setFontDone = useSetAtom(SisFontDone);
	const setFaviconDone = useSetAtom(SisFaviconDone);
	const setOgTagsDone = useSetAtom(SisOgTagsDone);


	//Pay
	const setIsAlreadySubscribed 						 = useSetAtom(FIsAlreadySubscribed);
	const setActiveStep 								 = useSetAtom(ActiveStep);
	const [, setCouponAmount]							 = useAtom(subscriptionAtom.couponAmountCode);
	const [, setCouponPercent]							 = useAtom(subscriptionAtom.couponPercentCode);
	const [, setPaymentMethodID]						 = useAtom(subscriptionAtom.paymentMethodID);
	const setIsSavedCard								 = useSetAtom(saveCardInfo);
	const setCouponApplied								 = useSetAtom(couponCodeApply);
	const [, setInputValue]			         			 = useAtom(subscriptionAtom.promoCodeValue);

	const setAppChanges = useSetAtom(SAppNameChanges);
	const setFlashScreenChanges = useSetAtom(SIsFlashChanges);
	const setLayoutChanges = useSetAtom(SIsLayoutChanges);
	const setThemeChanges = useSetAtom(SIsThemeChanges);
	const setBackgroundChanges = useSetAtom(SIsBackgroundChanges);
	const setNavChanges = useSetAtom(SIsNavigationChanges);
	const setSidebarChanges = useSetAtom(SIsSideBarChanges);
	const setColorChanges = useSetAtom(SIsColorChanges);
	const setFontChanges = useSetAtom(SIsFontChanges);

	const setFeaturesChanges = useSetAtom(FIsFeaturesChange);
	const setHomeChanges = useSetAtom(FIsHomeChanges);
	const setStoreChanges = useSetAtom(FIsStoreChanges);
	const setAboutChanges = useSetAtom(FIsAboutChanges);
	const setContentChanges = useSetAtom(FIsContentChanges);
	const setPrivacyChanges = useSetAtom(FIsPrivacyChanges);
	const setTermsChanges = useSetAtom(FIsTermsChanges);
	const setAccessChanges = useSetAtom(FIsAccessChanges);
	const setContactChanges = useSetAtom(FIsContactChanges);
	const setGalleryChanges = useSetAtom(FIsGalleryChanges);
	const setHelpChanges = useSetAtom(FIsHelpChanges);
	const setOfferChanges = useSetAtom(FIsOffersChanges);
	const setNotifChanges = useSetAtom(FIsNotifChanges);
	const setSocMedChanges = useSetAtom(FIsSocialMediaChanges);

	useEffect(() => {
		setTimeout(() => {
			if(status === 'LIV'){
				setAppStatus('Live');
				setappTextColor('bg-[#3EAB45]');
			}
			if(status === 'DRA'){
				setAppStatus('Draft');
				setappTextColor('bg-[#707070]');
			}
			if(status === 'INR'){
				setAppStatus('In Review');
				setappTextColor('bg-[#C17002]');
			}
			if(status === 'PAU'){
				setAppStatus('Paused');
				setappTextColor('bg-[#AD9D24]');
			}
			if(status === 'REJ'){
				setAppStatus('Rejected');
				setappTextColor('bg-[#BF1616]');
			}
			if(status === 'TRI'){
				setAppStatus('Trial Expired');
				setappTextColor('bg-[#707070]');
			}
		},100);
		
	},[]);

	const queryClient = useQueryClient();
	const { mutateAsync } = useMutation(deleteAppsById);

	const deleteApp = async () => {
		await mutateAsync(appID);
		showSuccessAlert('You have successfully deleted your mobile app.');
		queryClient.invalidateQueries('pwa-apps');
		setDeleteModalShow(false);
	};

	const handleClick = () => {
		setDeleteModalShow(!deleteModalShow);
	};
	
	// ---------------------------------------------- RESET ATOMS AND INVALIDATE ---------------------------------------------- //
	//Reset Flash Screen Atoms
	const resetFlashScreenAtoms = () => {
		setSubCategories([{label: '', value: 0}]);
		setFlashLogoURL(null);
		setFlashLogoID(0);
		setFlashCustomBgID(0);
		setFlashBackgroundImage(null);
		setFlashCustomBackgroundFile(null);
		setFlashLogoFile(null);
		setFlashBackgroundID(0);
		setFlashPreviewBackground(null);
		setFlashBackgroundColor('');
		setFlashOverlayColor('#000');
		setFlashOverlayOpacity(0);
		setFlashBackgroundImageOpacity(0);
		setFlashBackgroundImageBlur('0');
		setCategory(0);
		setIsFlashCustomBgFocus(false);
		setSubCategModalShow(false);
		setSubCategErrorMessage(false);
	};

	//Reset Background Atoms
	const resetBackgroundAtoms = () => {
		setBackgroundID(0);
		setCustomBackgroundID(0);
		setColorBackground('#fff');
		setAppPreviewBackground(null);
		setOpacityBackgroundImage(0);
		setBlurBackgroundValue(0);
		setIsBgRefresh(true);
		setCustomBackgroundFocus(false);
		setBackgroundImage(null);
		setBackgroundCategory(1);
		setBackgroundFile(null);
	};

	const resetFaviconOgTagsAtoms = () => {
		// setSelectedFaviconID(0);
		setFaviconImg(undefined);
		setFaviconImgID(0);
		// setIsFaviconRefresh(true);
		setFaviconImgFile(null);

		// setSelectedOgTagsID(0);
		setOgTagsImg(undefined);
		setOgTagsImgID(0);
		// setIsOgTagsRefresh(true);
		setOgTagsImgFile(null);
	};

	//Reset Appearance ID
	const resetAppearanceID = () => {
		setSelectedFlashScreenID(0);
		setSelectedLayoutID(0);
		setSelectedThemeID(0);
		setSelectedBackgroundID(0);
		setSelectedNavigationID(0);
		setSelectedSideBarID(0);
		setSelectedColorID(0);
		setSelectedFontID(0);
		setSelectedFaviconID(0);
		setSelectedOgTagsID(0);
		setFlashDone(false);
		setThemeLayoutDone(false);
		setBackgroundDone(false);
		setNavDone(false);
		setSidebarDone(false);
		setColorDone(false);
		setFontDone(false);
		setFaviconDone(false);
		setOgTagsDone(false);
	};

	//Reset Content List Atom
	const resetContentListAtom = () => {
		// contentList.map((content) => {
		// 	content.id = 0;
		// 	content.icon = 'default';
		// 	content.title = 'Content';
		// 	content.description = '';
		// 	content.toDelete = false;
		// });
		
		setContentList([]);
	};

	//Reset Features
	const resetFeatures = () => {
		setSelectedFeatures('Home');
		setHomeImg(undefined);
		setImageFile(null);
		setHomeDesc('');
		setStoreName('');
		setAboutDesc('');
		setHelpDesc('');
		setPrivacyDesc('');
		setTermsDesc('');
		setAccessibilityDesc('');
		setContactDesc('');
		setHomeID(0);
		setHomeImgID(0);
		setStoreID(0);
		setAboutID(0);
		setPrivacyID(0);
		setTermsID(0);
		setAccessibilityID(0);
		setContactID(0);
		setHelpID(0);
		setIsStoreRefresh(true);
		setIsAboutRefresh(true);
		setIsAccessRefresh(true);
		setIsContactRefresh(true);
		setIsHelpRefresh(true);
		setIsHomeRefresh(true);
		setIsPrivacyRefresh(true);
		setIsTermsRefresh(true);
		resetContentListAtom();
		setContentRefresh(true);
		setIsGalleryRefresh(true);
		setIsNotifRefresh(true);
		setIsOfferRefresh(true);
		setIsSocMedRefresh(true);
		setAlbum([]);
		setAccordionImages([]);
		setUpdateAlbum([]);
		getGalleryAlbumId([]);
		setImageUploadId([]);
		setSocialMedias([]);
		setSocialMediaIds([]);
		setOffer([]);
		setOfferDeleteId([]);
		setSelectedImages([]);
	};

	const invalidateAppearance = () => {
		resetAppearanceID();
		resetFlashScreenAtoms();
		resetBackgroundAtoms();
		resetFaviconOgTagsAtoms();
		setCategory(0);
		setCurrentStep(0);
		setSelectedAppearanceTab('flashScreen');
		setIsAppRefresh(true);
		setAppearanceRefresh(true);
		setThemeLayoutRefresh(true);
		setIsFlashRefresh(true);
		setNavRefresh(true);
		setSidebarRefresh(true);
		setIsColorRefresh(true);
		setIsFontRefresh(true);
		setIsFaviconRefresh(true);
		setIsOgTagsRefresh(true);
		setAppDone(false);
		setIsAppearanceChanges(false);
		queryClient.invalidateQueries('app');
		queryClient.invalidateQueries('appearances');
		queryClient.invalidateQueries('appearanceLive');
		queryClient.invalidateQueries('categories');
		queryClient.invalidateQueries('layout');
		queryClient.invalidateQueries('theme');
		queryClient.invalidateQueries('flashScreen');
		queryClient.invalidateQueries('flashScreenLive');
		queryClient.invalidateQueries('flashScreenbackgrounds');
		queryClient.invalidateQueries('background');
		queryClient.invalidateQueries('backgroundLive');
		queryClient.invalidateQueries('backgrounds');
		queryClient.invalidateQueries('navigations');
		queryClient.invalidateQueries('navigationsLive');
		queryClient.invalidateQueries('sidebar');
		queryClient.invalidateQueries('color');
		queryClient.invalidateQueries('colorLive');
		queryClient.invalidateQueries('font');
		queryClient.invalidateQueries('fontLive');
		queryClient.invalidateQueries('sidebar');
		queryClient.invalidateQueries('sidebarLive');
		queryClient.invalidateQueries('favicon');
		queryClient.invalidateQueries('faviconLive');
		queryClient.invalidateQueries('ogTags');
		queryClient.invalidateQueries('ogTagsLive');
	};

	const invalidateFeatures = () => {
		resetFeatures();
		queryClient.invalidateQueries('home');
		queryClient.invalidateQueries('homeLive');
		queryClient.invalidateQueries('store');
		queryClient.invalidateQueries('storeLive');
		queryClient.invalidateQueries('about');
		queryClient.invalidateQueries('aboutLive');
		queryClient.invalidateQueries('accessibility');
		queryClient.invalidateQueries('accessibilityLive');
		queryClient.invalidateQueries('Contact Us');
		queryClient.invalidateQueries('contactLive');
		queryClient.invalidateQueries('help');
		queryClient.invalidateQueries('helpLive');
		queryClient.invalidateQueries('privacyPolicy');
		queryClient.invalidateQueries('privacyPolicyLive');
		queryClient.invalidateQueries('termsOfUse');
		queryClient.invalidateQueries('termsOfUseLive');
		queryClient.invalidateQueries('content');
		queryClient.invalidateQueries('contentLive');
		queryClient.invalidateQueries('gallery');
		queryClient.invalidateQueries('galleryLive');
		queryClient.invalidateQueries('notifications');
		queryClient.invalidateQueries('notificationsLive');
		queryClient.invalidateQueries('getOffers');
		queryClient.invalidateQueries('getOffersLive');
		queryClient.invalidateQueries('SocialMedias');
		queryClient.invalidateQueries('SocialMediasLive');
	};

	const resetChanges = () => {
		setAppChanges(false);
		setFlashScreenChanges(false);
		setLayoutChanges(false);
		setThemeChanges(false);
		setBackgroundChanges(false);
		setNavChanges(false);
		setSidebarChanges(false);
		setColorChanges(false);
		setFontChanges(false);
		setFaviconChanges(false);
		setOgTagsChanges(false);

		setFeaturesChanges(false);
		setHomeChanges(false);
		setStoreChanges(false);
		setAboutChanges(false);
		setContentChanges(false);
		setPrivacyChanges(false);
		setTermsChanges(false);
		setAccessChanges(false);
		setContactChanges(false);
		setGalleryChanges(false);
		setHelpChanges(false);
		setOfferChanges(false);
		setNotifChanges(false);
		setSocMedChanges(false);
	};

	const invalidatePay = () => {
		setIsAlreadySubscribed(false);
		setActiveStep(0);
		setCouponAmount(0);
		setCouponPercent(0);
		setPaymentMethodID('');
		setIsSavedCard(false);
		setCouponApplied(false);
		setInputValue('');
	};
	
	const invalidate = () => {
		invalidateAppearance();
		invalidateFeatures();
		invalidatePay();
		resetChanges();
		setIsSaving(false);
	};

	useEffect(() => {
		invalidate();
	}, [appID]); 

	const lowerSmViewPort = useMediaQuery({ maxWidth: 540 });

	return(
		<div 
			className={
				classNames(
					activeCard === true ? 'border-[#F97316] ' : '',
					status !== 'TRI' ? 'hover:outline-[#F97316] hover:outline-[5px]' : '',
					'relative block border-transparent bg-white h-[229px] outline outline-transparent w-[182px] rounded-[14px]'
				)
			}
		>
			<span className={`${appTextColor} absolute text-white text-xs p-[0.4rem] right-0 w-[55%] flex justify-center items-center rounded-bl-[14px] rounded-tr-[14px]`}>{appStatus}</span>
			{/* <div className='flex flex-col items-center py-[30px] mt-[10px]' onClick={openSidebar} /> */}
			<div 
				className={classNames(
					status === 'TRI' ? 'opacity-70 pointer-events-none' : 'cursor-pointer',
					'flex flex-col items-center py-[30px] mt-[10px] py-[35px]'
				)}
				
				// For the Open Sidebar or Opening Side bar
				onClick={openSidebar}
			>
				<div className=
					'h-[80px] w-full flex items-center justify-center mt-2 px-3'
				>
					{image && <img src={image} className='h-full w-1/2 rounded-full'/>}
				</div>
				<p className=
					'text-[#464255] text-xs mt-[5px] font-bold'
				>{appName}</p>
			</div>

			{
				status === 'TRI' ?
					<footer 
						className='flex h-[50px] gap-[30px] justify-center items-center bg-[#E0781D] py-[7px] px-[12px] rounded-b-[14px] cursor-pointer'
						onClick={() => navigate(`/edit/${appID}`)}
					>
						<p className='text-center text-xs text-white font-semibold'>Upgrade Plan</p>
					</footer>
					:
					<footer className=
						'flex h-[50px] gap-[30px] justify-around bg-[#007CA8] py-[7px] px-[12px] rounded-b-[14px]'
					>
						<Link 
							id='nav-link' 
							to={`/edit/${appID}`} 
							className='flex flex-col items-center cursor-pointer' 
							onClick={() => invalidate()}>
							<img src={IMAGES.EditIcon} alt='Edit Icon'/>
							<p className='mt-[5px] text-xs text-white font-light'>Edit</p>
						</Link>
						<div className='flex flex-col items-center cursor-pointer' onClick={handleClick} >
							<img className='h-[15px]'
								src={IMAGES.DeleteIcon} alt='Delete Icon'/>
							<p className='mt-[5px] text-center text-xs text-white font-light'>Delete</p>
						</div>
					</footer>
			}

			<DeleteModal type='' isDeleteModalShow={deleteModalShow} setDeleteModalShow={handleClick} onClickCancel={handleClick} onClickDelete={deleteApp} />
		</div>
	);
};

export default ManageAppCard;