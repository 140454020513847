import {
	GetAllAppResponse,
	GetAppResponse,
} from 'services/requests/apps/schema';
import React, {
	ChangeEvent,
	useEffect,
	useLayoutEffect,
	useState,
} from 'react';
import {
	SEditProfileFirstName,
	SEditProfileImage,
	SEditProfileImagePreview,
	SEditProfileLastName,
	SEditProfileUserId,
} from '../../store/EditProfile/editProfileAtom';
import {
	UserTokenResponse,
	UserTokenSchema,
} from 'services/requests/user/schema';
import { getAllApps, getAllAppsNA } from 'services/requests/apps';
import { getUserById, getUserByToken } from 'services/requests/user';
import { useAtom, useSetAtom } from 'jotai';

import App from 'App';
import { AxiosError } from 'axios';
import Button from 'components/Common/Buttons/Button';
import EmptyDashboard from './EmptyDashboard';
import { IMAGES } from 'utility/constant';
import ManageAppCard from 'components/ManageAppCard';
import { SearchNotFound } from 'components/SearchNotFound';
import SideBar from './SideBar';
import SideBarAnimation from './SideBar/SideBarAnimation';
import { appIds } from 'store/getAppsAtom';
import { classNames } from 'utility';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import PartialFooter from 'components/Common/Footer/PartialFooter';

const ManageAppPage: React.FC = () => {
	const [editUser, setEditUser] = useAtom(SEditProfileUserId);
	const [editFirstName, setEditFirstName] = useAtom(SEditProfileFirstName);
	const [editLastName, setEditLastName] = useAtom(SEditProfileLastName);
	const [editImage, setEditImage] = useAtom(SEditProfileImage);
	const [editImagePreview, setEditImagePreview] = useAtom(SEditProfileImagePreview);
	const [queryInput, setQueryInput] = useState<string>('');
	const [isNoAppMatch, setIsNoAppMatch] = useState<boolean>(false);
	const token = localStorage.getItem('bearerToken');
	// const { data, isLoading, error } = useQuery('pwa-apps', getAllApps,);

	const [open, setOpen] = useState(false);
	const [filteredRows, setFilteredRows] = useState<GetAllAppResponse[]>([]);
	const setAppIds = useSetAtom(appIds);

	//Get specific App

	const [filteredApp, setFilteredApp] = useState<GetAppResponse[]>([]);

	//Specific Responsiveness

	const lowerSmViewPort = useMediaQuery({ maxWidth: 475 });
	const normalSmViewPort = useMediaQuery({ maxWidth: 640 });

	const { data, isLoading } = useQuery<GetAllAppResponse, AxiosError>({
		queryKey:['pwa-apps'], 
		queryFn: getAllApps,
		onError: ()=>	setFilteredRows([]),
		staleTime: Infinity,
		onSuccess() {
			setFilteredRows([]);
		},
	});
	
	const { data: editProfileByToken } = useQuery<UserTokenResponse, AxiosError>({
		queryKey: ['editProfileByToken'],
		queryFn: () => getUserByToken({ token: token }),
		retry: 0,
	});

	// const { data: editProfileData } = useQuery(['user', editUser], () =>
	// 	getUserById(editUser)
	// );

	const { data: editProfileData } = useQuery({
		queryKey: ['user', editUser],
		queryFn: () => getUserById(editUser),
		retry: 0,
		enabled: editUser != undefined
	});

	// useEffect(() => {
	// 	if (editProfileByToken) {
	// 		setEditUser(editProfileByToken.userID);
	// 	}
	// 	if (editProfileData) {
	// 		localStorage.setItem('userData', JSON.stringify(editProfileData));
	// 		setEditFirstName(editProfileData.user.firstName);
	// 		setEditLastName(editProfileData.user.lastName);
	// 		setEditImage(editProfileData.user.imageURL);
	// 		setEditImagePreview(editProfileData.user.imageURL);
	// 	}
	// }, [editProfileByToken, editProfileData]);

	const navigate = useNavigate();

	
	const [sidebarAppID, setSidebarAppID] = useState(0);
	const [sidebarAppName, setSidebarAppName] = useState('');
	const [sidebarAppImage, setSidebarAppImage] = useState('');

	const handleSidebar = () => {
		setOpen(!open);
		// setSidebarAppImage();

	};

	useLayoutEffect(() => {
		document.body.style.backgroundColor = '#f6f6f6';
	});

	useEffect(() => {
		if (data?.rows) {
			setFilteredRows([{ rows: data.rows }]);
			setAppIds(data.rows.map((app) => {return{appID: app.appID};}));			
		}
	}, [data?.rows]);

	const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
		const query = event.target.value.toLowerCase();
		if (data?.rows) {
			const filtered = data.rows.filter((row) =>
				row.name.toLowerCase().includes(query)
			);
			if (filtered.length === 0){
				setIsNoAppMatch(true);
			} else {
				setIsNoAppMatch(false);
			}
			setFilteredRows([{ rows: filtered }]);	
		}
		setQueryInput(query);
	};

	if (isLoading) {
		return <div>Loading...</div>;
	}

	// Retrieve Telemetry Data in Sidebar by App ID

	// const [selectedAppID, setSelectedAppID] = useState<number | null>(null);;
	// const [selectedAppName, setSelectedAppName] = useState<string | null>(null);;
	// const [selectedAppImage, setSelectedAppImage] = useState<string | null>(null);;

	// const selectedAppValues = (idValue:number, nameValue: string, imageValue: string) => {
	// 	setSelectedAppID(idValue);
	// 	setSelectedAppName(nameValue);
	// 	setSelectedAppImage(imageValue);
		
	// }

	return (
		<div className={`h-max bg-[#F6F6F6] ${open ? '2xl:w-[60%] xl:w-full lg:w-full md:w-full sm:w-full max-sm:w-full' : 'w-full'}`}>
			{data?.rows === undefined ? (
				<EmptyDashboard />
			) : (
				<div className='flex py-[10px]'>
					<div className={`w-full flex h-max flex-col ${
						open === false ? 'items-center' : 'items-center ml-[65px] 2xl:ml-[20%] xl:ml-0 lg:ml-0 md:ml-0 sm:ml-0 max-sm:ml-0'}`}>
						<div className={classNames(
							lowerSmViewPort
								? 'w-full px-[3%]'
								: '2xl:w-[870px] xl:w-[870px] lg:w-[870px] md:w-[635px]',

							normalSmViewPort
								? 'w-full px-[3%]'
								: '')}>
						
							{/* 'w-full flex flex-col 2xl:px-[27.6%] xl:px-[17.9%] lg:px-[8%] md:px-[8%] sm:px-[8%] max-sm:px-[3%]' */}
							{/* 2xl:w-[100vw] 2xl:ml-[55.7vw] xl:w-[100vw] xl:ml-[45%] lg:w-[100vw] lg:ml-[24%] md:w-[92vw] md:ml-[2%] sm:w-[90vw] sm:ml-[1%] max-sm:w-[90vw] max-sm:ml-[1%] */}
							<div className='text-start'>
								<p className='text-[#26355E] text-xl font-bold'>Manage</p>
							</div>
							<div className='flex flex-row w-full mt-[22px] gap-[17px] 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col sm:justify-center sm:items-center max-sm:flex-col max-sm:justify-center max-sm:items-center'>
								<form className='bg-white rounded-[9px] relative w-full'>
									<input
										onChange={handleSearch}
										className='w-full h-full block rounded-[9px] text-xs py-[18px] pl-[15px] pr-[41px] border focus:ring-1 focus:border-input-active outline-none'
										type='search'
										onKeyDown={(e)=>{
											if(e.key === 'Enter'){
												e.preventDefault();
											}
										}}
										placeholder='Search'
									/>
									<img
										className='flex absolute top-4 right-3 cursor-pointer'
										src={IMAGES.SearchIcon}
										alt='Search Icon'
									/>
								</form>
								<Button
									variant='primary'
									className='pr-[20px] pl-[50px] relative text-xs font-semibold rounded-[9px] 2xl:w-[200px] xl:w-[200px] lg:w-[200px] md:w-[200px] sm:h-[50px] sm:w-[175px] max-sm:h-[50px] max-sm:w-full whitespace-nowrap'
									onClick={() => navigate('/SelectIndustry')}
								>
									<img
										className='absolute top-[9px] left-[10px] w-[30px] h-[30px]'
										src={IMAGES.PlusCircleIcon}
										alt='plus circle icon'
									/>
									Create New App
								</Button>
							</div>
							<div className={classNames('w-full mt-[50px] mb-[42px] gap-[42px] 2xl:grid-cols-4 2xl:w-full xl:grid-cols-4 xl:w-full lg:grid-cols-4 lg:w-full md:grid-cols-3 md:w-full sm:grid-cols-3 sm:w-full max-sm:col-span-1 max-sm:place-items-center',
								lowerSmViewPort
									? 'grid grid-cols-1'
									: isNoAppMatch && queryInput ? '' : 'flex flex-wrap grid flex-row grid-cols-2')}>	
								{
									isNoAppMatch && queryInput && <SearchNotFound />
								}
								{filteredRows?.map((data) => {
									return data.rows.map((row) => {
										return (
											<div 
												key={row.appID}
												onClick={() => {
													setSidebarAppID(row.appID), 
													setSidebarAppName(row.name), 
													setSidebarAppImage(row.logoUrl);
												}}>
												<ManageAppCard
													key={row.appID}
													status={row.status}
													activeCard={open}
													openSidebar={handleSidebar}
													image={row.logoUrl ? row.logoUrl : IMAGES.PlaceholderIcon}
													appName={row.name}
													appID={row.appID}
												/>
											</div>
										);
									});
								})}
							</div>
							<SideBarAnimation open={open} setOpen={handleSidebar}>
								{/* To get the App ID and replace rows value for ID, Name and Image */}
								<SideBar appID={sidebarAppID} appName={sidebarAppName} appImage={sidebarAppImage}/>
							</SideBarAnimation>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ManageAppPage;