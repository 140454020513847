import React, { useState } from 'react';
import { Button } from 'components/Common/Buttons';
import { IMAGES } from 'utility/constant';
import SignIn from 'pages/Auth/SignIn';
import ApproverTopbar from './UserApproverTopBar';
import { Link, NavLink } from 'react-router-dom';
import { useAtomValue, atom, useAtom } from 'jotai';
import { globalVariableAtom, setGlobalVariableAtom } from 'store/authAtom';
import { useNavigate, useLocation } from 'react-router-dom';

const links = [
	{ title: 'Pricing', links: '/pricing' },
	{ title: 'Contact Us', links: '/contact-us' },
	{ title: 'Become a Reseller', links: '/become-a-reseller' },
	{ title: 'Let us Build Your App', links: '/build' },
];

const linksSignedIn = [
	{ title: 'Dashboard', links: '/dashboard' },
	{ title: 'Help', links: '/help' },
	{ title: 'Become a Reseller', links: '/become-a-reseller' },
	{ title: 'Let us Build Your App', links: '/build' },
];

const TopbarApprover: React.FC = () => {
	const [isSignedIn, setIsSignedIn] = useState(false);
	const [globalVariable, setGlobalVariable] = useAtom(globalVariableAtom);
	const navigate = useNavigate();

	// React.useEffect(() => {
	// 	if(!isSignedIn){
	// 		setGlobalVariable('initial value');
	// 	}
	// }, [isSignedIn]);

	// const handleClick = () => {
	// 	navigate('/login');
	// };

	const options = [{ value: 'Logout', label: 'Log Out' }];

	const handleSelect = (value: string) => {
		if (value === 'Logout') {
			localStorage.removeItem('token');
			localStorage.removeItem('bearerToken');
			localStorage.removeItem('userData');
			setTimeout(() => {
				window.location.reload();
				navigate('/');
			}, 100);
		}
	};

	// const handleBack = () => {
	// 	setGlobalVariable('initial value');
	// };

	return (
		<div className='shadow-md w-full relative z-10 top-0 left-0'>
			<div className='flex items-end justify-end bg-[#3C5491] py-4 px-10'>
				<ul className='flex list-none items-center'>
					{/* { !isLogin ? 
						links.map((link, key) => (
							location.pathname === '/login' || location.pathname === '/thankyou-signup' || location.pathname === '/signup' ?
								null
								:
								<li key={key} className='text-[#4663AC] font-semibold text-base pr-16'>
									<NavLink className={({ isActive }: {isActive: boolean}) => isActive ? activeLink : normalLink} to={link.links}
									>{link.title}
									</NavLink>
								</li>
						)) : 
						linksSignedIn.map((link, key) => (
							<li key={key} className='text-[#4663AC] font-semibold text-base pr-16'>
								<NavLink className={({ isActive }: {isActive: boolean}) => isActive ? activeLink : normalLink} to={link.links}
								>{link.title}
								</NavLink>
							</li>
						))
					} */}
					{/* { !isLogin ?
						location.pathname === '/login' || location.pathname === '/thankyou-signup' || location.pathname === '/signup' ? null :
							<Button className='text-white bg-[#4663AC] px-12 py-2 text-base font-semibold' variant='primary' onClick={handleClick}>Sign In</Button>
						: <ApproverTopbar options={options} onSelect={handleSelect} />
					} */}
					<ApproverTopbar options={options} onSelect={handleSelect} />
				</ul>
			</div>
		</div>
	);
};

export default TopbarApprover;
