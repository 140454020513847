import React, { useEffect, useLayoutEffect, useState } from 'react';
import { IMAGES } from 'utility';
import Footer from 'components/Common/Footer';
import AccordionFAQs from 'components/Accordion/AccordionFAQs';
import list from 'postcss/lib/list';
import { NavLink } from 'react-router-dom';

const FaQsLanding: React.FC =() => {

	useEffect(() => {
		window.scrollTo(0,0);
	},[]);

	useLayoutEffect(() => {
		document.body.style.backgroundColor = '#FFFFFF';
	});

	const [list, setList] = useState([
		{
			question: 'How does SnapToApp Work?',
			answer: 'SnapToApp is an innovative app builder platform designed to cater to various users, from small business owners to established organizations. With its user-friendly interface, you can create and customize mobile apps without any coding knowledge.'
		},
		{
			question: 'How can I customize my app using SnapToApp?',
			answer: 'You can easily customize your app\'s appearance using our diverse range of templates. Simply choose a template that aligns with your vision, and you\'re ready to personalize it further.'
		},
		{
			question: 'Can I add my own elements to the app interface?',
			answer: 'Absolutely! SnapToApp offers a drag-and-drop feature that lets you seamlessly integrate icons, text, and other elements of your choice into your app\'s interface. It\'s a quick and intuitive way to design your app.'
		},
		{
			question: 'What happens after I\'ve created my app?',
			answer: 'Once you\'ve designed your app and are satisfied with its appearance, you can submit it for review. Our team will assess it, and upon approval, your app will be published and available for download.'
		},
		{
			question: 'How long does the review process take?',
			answer:'The review process duration can vary depending on the complexity of your app. We strive to ensure a timely review and aim to have your app live as soon as possible.'
		},
		{
			question: 'Can I update my app after it\'s been published?',
			answer: 'Certainly! You can update your app\'s content, design, and features even after it\'s been published. Simply make the desired changes using SnapToApp\'s interface, and your updated app will go live after review.'
		},
		{
			question: 'Is SnapToApp suitable for both beginners and experienced users?',
			answer: 'Yes, SnapToApp is designed to be user-friendly for beginners while offering advanced customization options for experienced users. It caters to a wide range of users with varying levels of technical expertise. SnapToApp also caters to people who don\'t have experience in coding, making the mobile app builder more inclusive to a lot of people with varying skill levels.'
		},
		{
			question: 'Can I use SnapToApp to create apps for different platforms?',
			answer: 'Currently, SnapToApp only focuses on creating apps for mobile platforms.'
		},
		{
			question: 'What if I want to build apps for multiple businesses or clients?',
			answer: 'If you\'re interested in building apps for multiple businesses or clients, consider our reseller program. This program allows you to create and manage apps for others, making it an excellent option for freelancers and agencies.'
		},
		{
			question: 'How can I become a reseller with SnapToApp?',
			answer: <p>Becoming a reseller with SnapToApp is a fantastic opportunity to offer app-building services to your clients. To learn more about our reseller program and its benefits, click <u className='text-[#C34115]'>here</u></p>
		}
	]);
      
	return (
		<div className='w-full h-full'>
			<div id='my-header' className='bg-white flex justify-center items-center flex-col'>
				<div className='bg-white relative w-screen h-[50vh] mb-20 max-sm:mb-10 flex justify-center items-center'>
					<img className='object-cover w-full h-full' src={IMAGES.FaqsImage} />
					<span className='absolute font-bold text-white text-[60px] max-sm:text-[30px] text-center tracking-wider'>Frequently Asked Questions</span>
				</div>

				<div className='list mb-20'>
					{/*---------------*/}
					{list.map((item, key) => <AccordionFAQs key={key} datas={item}/>)}
					{/*---------------*/}
				</div>
				
				<div className='bg-orange-100 w-full'>
					<div className='mt-5 mb-5 flex justify-center items-center flex-col'>
						<span className='font-bold pt-20 text-black text-[35px] max-sm:text-[25px] max-sm:text-center tracking-wider px-4'>
							Can&apos;t find what you are looking for?
						</span>
						<div className='mt-5 max-sm:mt-20 mb-5 flex justify-center items-center flex-col'>
							<span className='absolute font-bold text-[#666666] text-[15px] max-sm:text-center tracking-wider px-5'>
								If your question isn&apos;t covered in our FAQs, reach out to our support team through our contact page.
							</span>
						</div>			
					</div>
				</div>
				<div className='bg-orange-100 w-full'>
					<div className='mt-5 mb-20'>
						<div className='flex justify-center items-center flex-col'>
							<NavLink to={'/contact-us'}>
								<button className='px-10 py-3 text-sm font-bold tracking-wide text-white bg-[#e0781d] rounded focus:outline-none'>
							Contact Us
								</button>
							</NavLink>
						</div>
					</div>
				</div>
			</div>		
		</div>
	);

};

export default FaQsLanding;