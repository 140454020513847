import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AlphaPicker, ChromePicker, ColorResult } from 'react-color';
import { ArrowUpTrayIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { BlurLinear, Opacity } from '@mui/icons-material';
import { SAppearanceTab, SIndustryID, SIsAppearanceChanges, SIsFlashChanges } from 'store/CustomizeApp/Appearance';
import { SBackgroundColor, SBackgroundColorOptions, SBackgroundImage, SBlurBackgroundImage, SCustomBackgroundID, SCustomBgFocus, SFlashBackgroundID, SFlashCustomBackgroundFile, SFlashLogoFile, SIcon, SIndustrySubCategory, SIndustySubCategories, SIsSubCatFetched, SLogoID, SOpacityFlashBackgroundImage, SOpacityValue, SOverlayColor, SOverlayValue, SPreviewBackgroundImage } from 'store/CustomizeApp/Appearance/flashScreenAtom';
import { classNames, useClickOutside } from 'utility/common';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { AxiosError } from 'axios';
import Dropdown from 'components/Common/Dropdown';
import { GetDefaultBackgroundsResponse } from 'services/requests/appearance/background/schema';
import { GetSubCategoryIndustryResponse } from 'services/requests/appearance/flash_screen/schema';
import { IMAGES } from 'utility/constant';
import { SBBackgroundImage } from 'store/CustomizeApp/Appearance/backgroundAtom';
import { SSubCategErrorMessage } from 'store/subCategEmptyAtom';
import { Slider } from '@mui/material';
import { getDefaultBackgrounds } from 'services/requests/appearance/background';
import { getIndustrySubCategoriesByIndustryID } from 'services/requests/appearance/flash_screen';
import rgbHex from 'rgb-hex';
import { showFailedAlert } from 'utility/alerts';
import { stepperAtom } from 'store/CustomizeApp/Stepper';
import { useQuery } from 'react-query';
import Tooltip from 'components/Common/Tooltip';


const FlashScreenPage: React.FC = () => {
	
	const popover 				  = useRef(null);
	const iconInput 			  = useRef<HTMLInputElement>(null);
	const backgroundInput 		  = useRef<HTMLInputElement>(null);

	const [icon, setIcon]		  				 		   = useAtom(SIcon);
	const [category, setCategory] 						   = useAtom(SIndustrySubCategory);
	const [flashColor, setFlashColor] 	 			       = useAtom(SBackgroundColor);
	const [flashColorOptions, setFlashColorOptions]		   = useAtom(SBackgroundColorOptions);
	const [flashOverlay, setFlashOverlay]		  	  	   = useAtom(SOverlayColor);
	const [blur, setBlur]         						   = useAtom(SBlurBackgroundImage);
	const [flashBackgroundImage, setFlashBackgroundImage]  = useAtom(SBackgroundImage);
	const [previewBackground, setPreviewBackground] 	   = useAtom(SPreviewBackgroundImage);
	const [industrySubCategories, setIndustryCategories]   = useAtom(SIndustySubCategories);
	const [isOpenCustomBackground, toggleCustomBackground] = useState(false);
	const [isOpenOverlay, toggleOverlay] 		  		   = useState(false);
	const [backgroundID, setBackgroundID] 				   = useAtom(SFlashBackgroundID);
	const [opacity, setOpacity]   						   = useAtom(SOpacityValue);
	const [isCustomBgFocus, setIsCustomBgFocus] 		   = useAtom(SCustomBgFocus);
	const [flashBackgroundOpacity, setOpacityFlashBackgroundImage] = useAtom(SOpacityFlashBackgroundImage);
	const industryID 				      = useAtomValue(SIndustryID);
	const [, setIconID]		  			  = useAtom(SLogoID);
	const [, setCustomBackgroundID]	      = useAtom(SCustomBackgroundID);
	const [, setOverlayValue] 	  		  = useAtom(SOverlayValue);
	const [background,setBackgroundFromBackground]  = useAtom(SBBackgroundImage);
	const setFlashLogoFile 	              = useSetAtom(SFlashLogoFile);
	const setFlashCustomBackgroundFile 	  = useSetAtom(SFlashCustomBackgroundFile);
	const setSubCatFetch			 	  = useSetAtom(SIsSubCatFetched);
	const setIsChanges                    = useSetAtom(SIsAppearanceChanges);
	const setFlashChanges 				  = useSetAtom(SIsFlashChanges);

	//------Start For Custom Add Color-------------------------------

	const [colorStack, setColorStack] = useState<string[]>([]);
	const [selectedColor, setSelectedColor] = useState<string>('#FFFFFF');
	const [showAddColorPicker, setShowAddColorPicker] = useState<boolean>(false);
	const [clickedColor, setClickedColor] = useState<string>('');
	const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

	const handleColorChanging = (color: ColorResult) => {
		setSelectedColor(color.hex);
	};

	const handleColorChange = (color: string) => {
		const newColorStack = [color, ...colorStack.slice(0, 2)];
		setColorStack(newColorStack);
		setSelectedColor(color);

		const concatenatedColors = newColorStack.join(',');
		setFlashColorOptions(concatenatedColors);
	};

	const togglePicker = () => {
		setShowAddColorPicker(!showAddColorPicker);
	};

	const generateChildDivs = () => {
		return colorStack.map((color, index) => (
			<div
				key={index}
				// className='w-[25px] h-[25px] mr-2 border flex items-center justify-center cursor-pointer text-lg'
				className={`w-[25px] h-[25px] mr-4 2xl:mr-4 xl:mr-4 lg:mr-4 md:mr-4 sm:mr-2 max-sm:mr-2 border flex items-center justify-center cursor-pointer text-lg ${
					index === selectedIndex ? 'border-2 border-[#FF7F20]' : ''
				}`}
				style={{ background: color }}
				onClick={() => {
					setFlashColor(color);
					setSelectedIndex(index);
				}}
			></div>
		));
	};

	//-------------------------------------------------

	const closeColorBackground 			= useCallback(() => setShowAddColorPicker(false), []);
	const closeCustomBackground = useCallback(() => toggleCustomBackground(false), []);
	const closeOverlay 			= useCallback(() => toggleOverlay(false), []);
	
	useClickOutside(popover, closeColorBackground);
	useClickOutside(popover, closeCustomBackground);
	useClickOutside(popover, closeOverlay);

	const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;

	const {data: defaultBackgrounds } = useQuery<GetDefaultBackgroundsResponse, AxiosError>({
		queryKey: ['backgrounds'],
		queryFn: () => getDefaultBackgrounds({limit: 1000}),
		retry: 0,
	});
	
	const {data: subCategories, refetch, isFetched: subCatFetched} = useQuery<GetSubCategoryIndustryResponse, AxiosError>({
		queryKey: ['categories'],
		queryFn: () => getIndustrySubCategoriesByIndustryID(industryID, {limit: 1000}),
		retry: 0,
		enabled: false,
		onSuccess: (data) => {
			if(data?.rows){
				setSubCatFetch(subCatFetched);
			} 
		}
	});

	// useEffect(()=> {
	// 	if(subCatFetched){
	// 		// if(subCategories?.rows !== null){
	// 		setIsChanges(true);
	// 		setFlashChanges(true);
	// 		// }	
	// 	}
	// }, [subCatFetched]);

	const onChangeIndustry = (category: string) => {
		setCategory(parseInt(category));
		if(isCustomBgFocus === false) setFlashBackgroundImage(null);
		if(!background) setBackgroundFromBackground(null);
		setBackgroundID(0);
	};

	const onClickBackgroundImage = (url: string, defaultBgID: number) => {
		backgroundID === defaultBgID ?
			(setFlashBackgroundImage(null), setBackgroundID(0))
			:
			(setFlashBackgroundImage(url), setBackgroundID(defaultBgID), setIsCustomBgFocus(false));
	};

	const onClickUploadIconHandler = () => {
		iconInput.current?.click();
	};

	const onClickUploadBackgroundHandler = () => {
		previewBackground === null ?
			(backgroundInput.current?.click(),
			setIsCustomBgFocus(false))
			:
			isCustomBgFocus ? (setIsCustomBgFocus(false), setFlashBackgroundImage(null)) :
				(setIsCustomBgFocus(true),
				setBackgroundID(0),
				setFlashBackgroundImage(previewBackground)
				);
	};

	const handleSelectIcon = (event: any) => {
		const file = event.target.files[0];
		const fileReader = new FileReader();
	
		fileReader.onload = (event : any) => {
			const image : any = new Image();
			image.src = event.target.result;
		
			if (file.type.match(imageTypeRegex)) {
				image.onload = () => {
					if (file.size <= (1048576 * 5)) {
						setFlashLogoFile(file);
						setIcon(fileReader.result);	
					} else if (file.size > (1048576 * 5)) {
						showFailedAlert('Please upload an image file that is less than 5MB in size.');
					}
				};
			} else{
				showFailedAlert('File must be JPEG or PNG.');
			}
		};
		fileReader.readAsDataURL(file);
	};
	
	const handleSelectImage = (event: any) => {
		const file = event.target.files[0];
		const fileReader = new FileReader();
	
		fileReader.onload = (event : any) => {
			const image : any = new Image();
			image.src = event.target.result;
		
			if (file.type.match(imageTypeRegex)) {
				image.onload = () => {
					if (file.size <= (1048576 * 5)) {
						setFlashCustomBackgroundFile(file);
						setIsCustomBgFocus(true);
						setPreviewBackground(fileReader.result);
						setFlashBackgroundImage(fileReader.result);
						setBackgroundID(0);
					} else if (file.size > (1048576 * 5)) {
						showFailedAlert('Please upload an image file that is less than 5MB in size.');
					}
				};
			} else{
				showFailedAlert('File must be JPEG or PNG.');
			}
		};
		fileReader.readAsDataURL(file);
	};

	const handleSliderChange = (event: Event, newValue: number | number[]) => {
		setBlur(newValue);
	};
	
	useEffect(() => {
		if(industryID){
			refetch();
			if(subCategories)setIndustryCategories(subCategories?.rows.map((value) => {return {label: value.name, value: value.subcategoryID};}));
		}
	}, [subCategories, industryID]);

	useEffect(() => {
		if (flashBackgroundImage === null) {
			setOpacityFlashBackgroundImage(1);
			setBlur(0);	
		}
	}, [flashBackgroundImage]);

	useEffect(() => {
		if (flashColorOptions != null) {
			const colorArray = flashColorOptions.split(',');
			setColorStack(colorArray);
		}
	}, [flashColorOptions]);


	const [currentStep, setCurrentStep] = useAtom(stepperAtom);
	const [selectedAppearanceTab, setSelectedAppearanceTab] = useAtom(SAppearanceTab);

	const [ subCategErrorMessage, setSubCategErrorMessage ] = useAtom(SSubCategErrorMessage);

	// useEffect(() => {
	// 	log(subCategErrorMessage);
	// })
	
	return (
		<div className='rounded-lg my-4 mr-6 w-full'>
			<div className='w-full px-10 rounded bg-white 2xl:px-10 xl:px-10 lg:px-10 md:px-10 sm:px-3 max-sm:px-3'>
				<div className='py-5 flex-row'>
					<div className='flex justify-between items-center pt-2'>
						<span className='text-[#707070] font-bold text-2xl block'>Flash Screen</span>
						<div className='flex gap-3 items-baseline'>
							{/* -----------Video Tutorial and Help Icons------------ */}
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.CameraIcon} alt='camera icon' />
							</Tooltip>
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.InfoIcon} alt='info icon' />
							</Tooltip>
							{/* ---------------------------------------------------- */}
						</div>
					</div>
					<span className='text-sm text-gray-500 font-light'>This image will be displayed on the initial app loading screen (splash screen)</span>
					<div className='flex gap-8 py-10 w-full 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col max-sm:flex-col h-[27rem] lg:h-[20rem]'>
						
						{/* For Select Industry Sub Category */}
						<div className='flex flex-col mr-[40%] max-sm:mr-0'>
							<span className='text-[#707070] font-bold text-2xl block mb-2 max-sm:text-xl'>Industry Subcategory</span>
							<span className='text-[#212529] text-sm mb-4'>Select Industry Subcategory</span>
							<Dropdown disabled={!subCatFetched} 
								className={subCategErrorMessage ? 
									'w-1 border-[#BF1616]' : 
									'mr-1 2xl:w-[270.6px] 2xl:h-[42px] xl:w-[270.6px] xl:h-[42px] lg:w-[270.6px] lg:h-[42px] md:w-[270.6px] md:h-[42px] sm:w-[270.6px] sm:h-[42px] max-sm:w-[270.6px] max-sm:h-[42px] border-gray-300'} 
								onChange={(value) => { onChangeIndustry(value); setSubCategErrorMessage(false);}} 
								options={industrySubCategories} 
								value={!subCatFetched ? 'Loading...' : category} 
								placeholder={!subCatFetched ? 'Loading...' : 'Select Options'}/>
							{/* For no subcategory selected */}
							{
								subCategErrorMessage === true ?
									<div className='text-[1.4vh] ml-2.5 mt-1 text-[#BF1616]'>Please select subcategory</div>
									:
									null
							}
						</div>

						{/* For Upload Flash Screen Logo Image */}
						<div className='flex flex-col'>
							<h1 className='text-[#707070] font-bold text-2xl block mb-2'>Logo</h1>
							<p className='text-[#212529] text-sm mb-4'>Please select image less than 10MB.</p>
							<div onClick={onClickUploadIconHandler} className='w-[134px] h-[130px] rounded-md border bg-[#F5F5F5] cursor-pointer flex items-center justify-center'>
								<input ref={iconInput} type='file' className='h-0 absolute' accept='.jpg, .jpeg, .png' onChange={(e) => handleSelectIcon(e)} onClick={(event)=> { 
									event.currentTarget.value = '';
								}}/>
								{
									icon ?
										<div>
											<img src={icon} alt='preview-icon' className='w-[134px] h-[130px] rounded border p-3'/>
										</div>
										:
										<div>
											<div className='flex justify-center'>
												<ArrowUpTrayIcon className='w-8 h-8 text-primary'/> 
											</div>
											<span className='text-primary text-xs block text-center mb-2'>Upload Logo</span>
											{/* <span className='text-[#9F9F9F] text-xs block text-center'>Please select image</span>
												<span className='text-[#9F9F9F] text-xs block text-center'>less than 5MB.</span> */}
										</div>
								}
							</div>
							{icon && 
							<div className='rounded-full text-[#D9D7D7] bg-white border-2 h-7 w-7 flex items-center justify-center cursor-pointer -ml-[15px] -mt-[140px]'
								onClick={() => { setFlashLogoFile(null);
									setIcon(null); 
									setIconID(0);}}>
								<XMarkIcon className='w-5 h-5 cursor-pointer'/>
							</div>
							}
						</div>
					</div>
					<div>
						<h1 className='text-[#707070] font-bold text-2xl block mb-5'>Background</h1>

						{/* Separator */}
						<hr className='' />
						{/* <div className='py-6 grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-4 max-sm:grid-cols-2 pl-2 border-t border-[#FDF2F2]'> */}
					
						{/* Main Background */}
						<div className='grid grid-cols-3 py-6 pl-2 w-[60%] 2xl:grid-cols-3 2xl:pl-2 xl:grid-cols-3 xl:pl-2 lg:grid-cols-2 lg:pl-2 md:grid-cols-2 md:pl-2 sm:grid-cols-1 sm:pl-0 max-sm:grid-cols-1 max-sm:pl-0 max-sm:w-[70%]'>
						
							{/* Background Upload/Color*/}
							<div className='flex-col'>
								<span className='text-[#464255] opacity-80]'>Background Color</span>
								<div className='w-full mb-[46.52px] mt-[22.65px]'>
									<div className='flex items-center gap-2'>										
										<div className='flex flex-row'>												
											<div className='flex flex-row'>
												{generateChildDivs()}
											</div>
											<div
												className='w-[25px] h-[25px] mr-2 border flex items-center justify-center cursor-pointer text-lg'
												onClick={togglePicker}>
												+
											</div>
											{showAddColorPicker && (
												<div ref={popover} className='z-10 -mb-[255px]'>
													<ChromePicker color={selectedColor} 
														onChange={handleColorChanging}
														// onChangeComplete={handleColorChange} 
													/>
													<button
														className='w-[226px] py-2 ml-[-1px] bg-white text-black text-xs rounded border border-gray-300 cursor-pointer shadow-md'
														onClick={() => handleColorChange(selectedColor)}
													>
														Add Color
													</button>
												</div>
											)}

											{clickedColor && (
												<div
													className='bg-gray-200 w-12 h-12'
													style={{ background: clickedColor }}
												></div>
											)}
										</div>
											
										{/* <PlusCircleIcon onClick={() => toggleCustomBackground(true)} className='h-7 w-7 text-primary cursor-pointer' aria-hidden='true'/> */}

									</div>
								</div>
								<div className='flex w-[180px] '>
									<div onClick={onClickUploadBackgroundHandler}
										className={classNames('w-[180px] h-[280px] 2xl:w-[180px] 2xl:h-[280px] xl:w-[140.62px] xl:h-[240.98px] lg:w-[147.1px] lg:h-[246.72px] md:w-[147.1px] md:h-[246.72px]  rounded-sm bg-[#F5F5F5] cursor-pointer flex items-center justify-center' , 
											isCustomBgFocus ? ' outline-[5px] outline-primary outline ' : 'outline-none')} style={{backgroundColor: `${flashColor}`}}>
										<input ref={backgroundInput} type='file' className='h-0 absolute' accept='.jpg, .jpeg, .png' onChange={(e) => handleSelectImage(e)} onClick={(event)=> { 
											event.currentTarget.value = '';
										}}/>
										{
											previewBackground ?
												<div className='flex'>
													<img src={previewBackground} alt='preview-background' className='w-[180px] h-[280px] 2xl:w-[180px] 2xl:h-[280px] xl:w-[140.62px] xl:h-[240.98px] lg:w-[147.1px] lg:h-[246.72px] md:w-[147.1px] md:h-[246.72px] sm:w-[180px] sm:h-[280px] max-sm:w-[180px] max-sm:h-[280px] object-cover'/>
												</div>
												:
												<div className='flex justify-center'>
													<div className='flex-row'>
														<div className=' flex justify-center'>
															<ArrowUpTrayIcon className='w-8 h-8 text-primary'/> 
														</div>
														<span className='text-primary text-xs block text-center'>Image</span>
														{/* <span className='text-primary text-xs block text-center'>Custom</span>
													<span className='text-primary text-xs block text-center'>Background</span>	 */}
													</div>
												</div>
										}
									</div>
									{previewBackground &&
								<div className='rounded-full text-[#D9D7D7] bg-white border-2 h-7 w-7 flex items-center justify-center cursor-pointer -ml-4 -mt-3'
									onClick={() => { setPreviewBackground(null); 
										setFlashBackgroundImage(null); 
										setFlashCustomBackgroundFile(null);
										setIsCustomBgFocus(false);
										setCustomBackgroundID(0);}
									}
								>
									<XMarkIcon className='w-5 h-5 cursor-pointer'/>
								</div>
									}
								</div>
							</div>
						
							{/* Overlay */}
							<div className='flex-col 2xl:pt-0 xl:pt-0 lg:pt-0 md:pt-0 sm:pt-[31.41px] max-sm:pt-[31.41px]'>
								<span className='text-[#464255] opacity-80]'>Overlay</span>
								<div className=' mb-[47.52px] mt-[29.65px] 2xl:mb-[47.52px] xl:mb-[47.52px] lg:mb-[43.52px] md:mb-[43.52px] col-start-2 flex items-center '>
								
									
									<div className={'w-5 h-5 mr-3 border-2 cursor-pointer'} style={{backgroundColor: `${flashOverlay}`}} onClick={() => toggleOverlay(true)}/>
									
									{/*
									<div className='flex flex-row w-[60%] 2xl:w-[150px] xl:w-[110px] lg:w-[150px] md:w-[145px] sm:w-[60%] max-sm:w-[60%]'>
										<AlphaPicker />
									</div> */}
										
									<div className='flex flex-row w-[60%] 2xl:w-[150px] xl:w-[110px] lg:w-[150px] md:w-[145px] sm:w-[150px] max-sm:w-[150px]'>
										<AlphaPicker color={flashOverlay} onChange={(c) => {
											setFlashOverlay('#' + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a));
											if(c.rgb.a) setOverlayValue(c.rgb.a);
										}}/>
									</div>
									{isOpenOverlay &&
										<div ref={popover} className='absolute z-10 mt-52 ml-7'>
											<ChromePicker color={flashOverlay} disableAlpha onChange={(c) => {
												setFlashOverlay('#' + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a));
											}}/>
										</div> }
								</div>
								{/* background overlay */}
								{/*  2xl:ml-[4vw] xl:ml-[58%] lg: md:ml-[-5vw] sm:ml-[10%] max-sm:ml-[-5vw] */}
								<div className={'rounded w-[180px] h-[280px] 2xl:w-[180px] 2xl:h-[280px] xl:w-[140.62px] xl:h-[240.98px] lg:w-[147.1px] lg:h-[246.72px] md:w-[147.1px] md:h-[246.72px] border'} style={{backgroundColor: `${flashOverlay}`}}/>
							</div>
							
							<div className='flex flex-col mb-0 w-[250%] 2xl:w-[250%] 2xl:mb-0 xl:w-[35vw] xl:mb-0 lg:w-[50vw] lg:mb-0 md:w-[63vw] md:mb-0 sm:w-[60vw] sm:mb-0 max-sm:w-[80vw] max-sm:mb-10'>
								{/* Insert it here */}

								<div className='flex flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col max-sm:flex-col'>
								
									{/* Blur */}
									<div className='flex flex-col mr-[50.63px] 2xl:pt-0 xl:pt-0 lg:pt-[31.41px] md:pt-[31.41px] sm:pt-[31.41px] max-sm:pt-[31.41px]'>
									
										<span className='text-[#464255] opacity-80'>Blur</span>
										<div className='w-full flex items-center mb-[44.52px] mt-[23.65px] max-sm:w-[180px]'>
											<BlurLinear fontSize='medium' className='mr-2'/>
											<input type='number' min={0} max={10} value={blur}
												className={classNames(flashBackgroundImage === null || flashBackgroundImage === undefined ? 'pointer-events-none' : '', 'border-b w-8 border-black text-center focus:outline-none')}
												onChange={(value) => {
													if(parseInt(value.currentTarget.value) < 0 || !value.currentTarget.value){ setBlur(0);}
													else if(parseInt(value.currentTarget.value) > 10){ setBlur(10);}
													else setBlur(parseInt(value.currentTarget.value));
												}}/>
											<Slider  className={classNames(flashBackgroundImage === null || flashBackgroundImage === undefined ? 'pointer-events-none' : '', 'ml-6')} min={0} max={10} value={blur} onChange={handleSliderChange} size='small' style={{color: '#D9D7D7', width: 150}}/>
										</div>
									</div>

									{/* Opacity */}
									<div className='flex flex-col mr-10 2xl:pt-0 xl:pt-0 lg:pt-[31.41px] md:pt-[31.41px] sm:pt-[31.41px] max-sm:pt-[31.41px]'>
										<span className='text-[#464255] opacity-80'>Opacity</span>
										<div className='flex items-center mb-[44.52px] mt-[23.65px]'>
											<Opacity fontSize='medium' className='mr-2'/>
											<AlphaPicker className={classNames(flashBackgroundImage === null || flashBackgroundImage === undefined ? 'pointer-events-none' : '')}  width='150px' color={'#' + rgbHex(0, 0, 0, flashBackgroundOpacity)} onChange={(e) => {setOpacity(e.rgb);
												if(e.rgb.a) setOpacityFlashBackgroundImage(e.rgb.a);}}/>
										</div>
									</div>
								</div>
								<div className='flex flex-row w-full flex-row'>
									
									{/* insert here 2nd */}
									
									{/* first separator */}
									{/* <div className='px-24 2xl:px-[7vw] xl:px-[100%] lg:px-[100px] md:px-[10vw] sm:px-[12vw] max-sm:px-[12vw]'>
										<div className='rounded bg-gray-400 w-0.5 h-[265px]'/>
									</div> */}
														
									{/* 3 Image Recommendation */}
									<div className='grid grid-cols-3 col-span-2 gap-6 mr-4 w-full 2xl:grid-cols-3 2xl:gap-6 xl:grid-cols-3 xl:gap-6 lg:grid-cols-3 lg:gap-6 md:grid-cols-3 md:gap-6 sm:grid-cols-2 sm:gap-6 max-sm:grid-cols-2 max-sm:gap-2'>
										{
											defaultBackgrounds?.rows.map((item, idx) => 
												item.subcategoryID ===  category && <img key={idx} onClick={() => onClickBackgroundImage(item.imageURL, item.defaultBackgroundID)} 
												// className={classNames('w-[180px] h-[265px] 2xl:w-[180px] 2xl:h-[265px] xl:w-[140.62px] xl:h-[240.98px] lg:w-[147.1px] lg:h-[246.72px] md:w-[142.1px] md:h-[246.72px] sm:md:w-[142px] sm:h-[247px] max-sm:md:w-[142px] max-sm:h-[247px] w-cursor-pointer', 
													className={classNames('w-[180px] h-[280px] 2xl:w-[180px] 2xl:h-[280px] xl:w-[140.62px] xl:h-[240.98px] lg:w-[147.1px] lg:h-[246.72px] md:w-[142.1px] md:h-[246.72px] sm:w-[180px] sm:h-[265px] max-sm:w-[142px] max-sm:h-[247px] w-cursor-pointer',
														backgroundID ===  item.defaultBackgroundID ? ' outline-[5px] outline-primary outline ': 'outline-none')} 
													src={item.imageURL} alt='backgrounds' />
											)									
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FlashScreenPage;