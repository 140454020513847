import { IFile } from 'components/Common/Uploader';
import { atom } from 'jotai';
import { AccordionDataType } from 'pages/CustomizeApp/Features/Gallery';
import { DeleteAlbumImageIdType, GetGalleryRowsType, UpdateGalleryType } from 'services/requests/Features/Gallery/schema';

export const FGallery  = atom<AccordionDataType[] | []>([]);
export const FUpdateGallery = atom<UpdateGalleryType[]>([]);
export const FGalleryRows  = atom<GetGalleryRowsType[] | []>([]);
export const FIsGalleryRefresh = atom<boolean>(true);
export const FDeleteAlbumImagesAtom = atom<number[]>([]);

export interface IAccordionImages{
	albumId?: number
	title: string
	images: IImageUrls[]
	isTitleNew: boolean
	hasImgToUpload: boolean
	hasImgToDelete: boolean
}
export interface IImageUrls{
	imageUploadId?: number
	name:string
	url:string
}

export const FGalleryImageAtom = atom<IAccordionImages[] | []>([]);
export const FGalleryAlbumIdAtom = atom<number[]>([]);
