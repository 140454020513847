import { Button } from 'components/Common/Buttons';
import FormInput from 'components/Common/Forms/Inputs';
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { contactUsFormApi } from 'services/requests/ContactUsForm';
import {
	GetContactUsFormSchema,
	GetContactUsFormResponse,
	GetContactUsFormType,
} from 'services/requests/ContactUsForm/schema';
import { AxiosError } from 'axios';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { countryList } from 'utility';
import FormInputTextArea from 'components/Common/Forms/TextArea';
import Spinner from 'components/Common/Spinner';
import { showSuccessAlert } from 'utility/alerts';
import { log } from 'utility/log';

const ContactUsForm: React.FC = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);

	const navigate = useNavigate();
	const [captChaCheck, setCaptChaCheck] = useState(true);

	const { control, handleSubmit, formState } = useForm<GetContactUsFormType>({
		defaultValues: { name: '', email: '', company: '', country: '', message: '' },
		mode: 'onBlur',
		resolver: zodResolver(GetContactUsFormSchema),
	});

	const { mutate: contactUsFormMU } = useMutation<
		GetContactUsFormResponse,
		AxiosError,
		GetContactUsFormType
	>((data) => contactUsFormApi(data), {
		onSuccess: (data) => {
			log(data);
			showSuccessAlert('Message successfully sent!');
			setIsLoading(false);
			setIsDisabled(false);
			setTimeout(() => {
				navigate('/');
			}, 500);
		},
		onError: (error) => {
			log(error);
			setIsLoading(false);
			setIsDisabled(false);
		},
	});

	const onSubmit = (data: GetContactUsFormType) => {
		setIsLoading(true);
		setIsDisabled(true);
		contactUsFormMU(data);
	};

	const onChangeCaptcha = () => {
		setCaptChaCheck(false);
	};

	const onExpiredCaptcha = () => {
		setCaptChaCheck(true);
	};

	return (
		<div className='flex justify-center min-sm:pl-[5px] max-sm:pl-[5px] pl-[35px] h-[40vh] 2xl:w-[500px] 2xl:h-[110vh] xl:w-[550px] xl:h-[130vh] lg:[130vh] md:h-full sm:h-full max-sm:h-full'>
			<div className='w-[716px] h-[613px] rounded-[10px] py-[18px] px-[53px] md:px-[53px] sm:px-[20px] max-sm:px-[20px] 2xl:h-[110vh] xl:h-[130vh] lg:h-[613px] lg:w-[716px] md:h-full sm:h-full max-sm:h-full'>
				<h1 className='font-semibold text-[20px] mb-[39px] mt-[21px] text-[#464255]'>
					Contact Us
				</h1>
				<p className='font-normal text-sm mb-[55px] text-[#464255] max-sm:text-[13px]'>
					If you need our help or have questions about using our platform, don&apos;t
					hesitate to <br /> contact us by filling out the form below. We&apos;ll get
					back to you as soon as possible.
				</p>
				<form className='ml-[-10px]'>
					<div className='flex sm:w-[600px] max-sm:flex-col max-sm:justify-center max-sm:items-center'>
						<div className='w-full md:w-1/2 px-3 mb-6 md:mb-0 '>
							<label
								className='block tracking-wide text-gray-900 text-sm font-normal mb-[13px]'
								htmlFor='grid-first-name'
							>
								Name<span className='text-[#BF1616]'>*</span>
							</label>
							<Controller
								control={control}
								name='name'
								render={({ field: { name, onChange }, fieldState: { error } }) => (
									<FormInput
										onChange={onChange}
										name={name}
										error={error?.message}
										className='text-sm h-[39px]'
										type='text'
										placeholder='Full Name'
									/>
								)}
							/>
						</div>
						<div className='w-full md:w-1/2 max-sm:px-3 mb-6 md:mb-0'>
							<label
								className='block tracking-wide text-gray-900 text-sm font-normal mb-[13px]'
								htmlFor='grid-last-name'
							>
								Email Address<span className='text-[#BF1616]'>*</span>
							</label>
							<Controller
								control={control}
								name='email'
								render={({ field: { name, onChange }, fieldState: { error } }) => (
									<FormInput
										onChange={onChange}
										name={name}
										error={error?.message}
										className='text-sm h-[39px]'
										type='email'
										placeholder='you@example.com'
									/>
								)}
							/>
						</div>
					</div>
					<div className='flex mt-[31px] sm:w-[605px] max-sm:mt-[1px] max-sm:flex-col max-sm:justify-center max-sm:items-center'>
						<div className='max-sm:ml-1 w-full md:w-1/2 px-3 mb-6 md:mb-0 '>
							<label
								className='block tracking-wide text-gray-900 text-sm font-normal mb-[13px]'
								htmlFor='grid-company-name'
							>
								Company name<span className='text-[#BF1616]'>*</span>
							</label>
							<Controller
								control={control}
								name='company'
								render={({ field: { name, onChange }, fieldState: { error } }) => (
									<FormInput
										onChange={onChange}
										name={name}
										error={error?.message}
										className='text-sm h-[39px]'
										type='text'
										placeholder='Company name'
									/>
								)}
							/>
						</div>
						<div className='w-full md:w-1/2 max-sm:px-3 mb-6 md:mb-0'>
							<label
								className='block tracking-wide text-gray-900 text-sm font-normal mb-[13px]'
								htmlFor='grid-state'
							>
								Country<span className='text-[#BF1616]'>*</span>
							</label>
							<div className='flex justify-center h-[39px] text-black'>
								<Controller
									control={control}
									name='country'
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<div className='w-full'>
											<select
												className={`max-sm:w-[100%] text-[#183B56] w-[99%] h-[100%] pl-[8px] text-sm focus:outline-none rounded-md border ${
													error ? 'border-red-700 bg-red-100' : 'border-gray-300 bg-white'
												} focus:ring-1 focus:border-input-active`}
												onChange={onChange}
												value={value}
											>
												<option value='' hidden>Select a country</option>
												{countryList.map((index, key) => (
													<option key={key} value={index.value}>
														{index.name}
													</option>
												))}
											</select>
											<div className='mt-1 ml-1'>
												{error && <p className='text-red-700 text-xs'>{error.message}</p>}
											</div>
										</div>
									)}
								/>
							</div>
						</div>
					</div>
					<div className='max-sm:px-4 max-sm:w-full max-md:w-[615px] px-3 xl:pl-2.5 xl:pr-0.5 mt-[31px] max-sm:flex-col max-sm:justify-center max-sm:items-center'>
						<label
							className='block tracking-wide text-gray-900 text-sm font-normal mb-[13px]'
							htmlFor='grid-message'
						>
							Message<span className='text-[#BF1616]'>*</span>
						</label>
						<Controller
							control={control}
							name='message'
							resize-none
							text-justify
							render={({ field: { name, onChange }, fieldState: { error } }) => (
								<FormInputTextArea
									rows={2}
									onChange={onChange}
									name={name}
									error={error?.message}
									className='resize-none h-[110px] pr-[16px] pl-[16px] pb-[75px] text-sm rounded-[5px]'
									type='text'
									placeholder='Type your message...'
								/>
							)}
						/>
					</div>
					<div>
						<Controller
							control={control}
							name='adminEmail'
							defaultValue='julius.chan@hoolisoftware.com'
							render={({ field }) => <input type='hidden' {...field} />}
						/>
					</div>
					<div className='max-sm:px-4 px-3 mt-[31px]'>
						<ReCAPTCHA
							onChange={onChangeCaptcha}
							onExpired={onExpiredCaptcha}
							sitekey={import.meta.env.VITE_RECAPTCHA_PROD}
							size={'normal'}
						/>
					</div>
					<div className='flex justify-center mt-[50px] pl-3 max-sm:mt-[20px]'>
						<Button
							disabled={
								!formState.dirtyFields.name ||
								!formState.dirtyFields.email ||
								!formState.dirtyFields.company ||
								!formState.dirtyFields.country ||
								!formState.dirtyFields.message ||
								captChaCheck ||
								isLoading
							}
							className=' font-semibold text-[16px] w-[160px] h-[43px]'
							variant='primary'
							onClick={handleSubmit(onSubmit)}
							type='submit'
						>
							{isLoading ? (
								<div className='flex flex-row items-center justify-center'>
									Submit
									<Spinner className='ml-2' />
								</div>
							) : (
								<p>Submit</p>
							)}
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
};
export default ContactUsForm;
