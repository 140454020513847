


import { axiosInstance } from 'services/axios';
import { CreateGalleryType, CreateGalleryResponse, GetGalleryResponseType, UpdateGalleryResponseType, DeleteAlbumImageIdType, deleteAlbumImageSchema, UpdateGalleryParamsType } from './schema';

export const createGalleryApi = async (data: CreateGalleryType, id?: string) => {
	const { title, images } = data;
	const formData = new FormData();
	formData.append('title', title);
	images.forEach((image) => {
		formData.append('images',image);
	});
    
	const response = await axiosInstance.post(`/organizations/apps/${id}/albums`, formData, {
		headers: {
			'Content-type': 'multipart/form-data'
		}});  
	return response.data as CreateGalleryResponse;
};

export const updateGalleryApi = async (data: UpdateGalleryParamsType, id?: string) => {
	const { title, images } = data;
	const formData = new FormData();
	formData.append('title', title);
	images.forEach((image) => {
		formData.append('images',image);
	});
    
	const response = await axiosInstance.put(`/organizations/apps/${id}/albums/${data.albumId}`, formData, {
		headers: {
			'Content-type': 'multipart/form-data'
		}});  
	return response.data as UpdateGalleryResponseType;
};

export const getAllGalleryApiLive = async (id?:string) =>{
	const response = await axiosInstance.get(`/organizations/apps/${id}/albums?env=LIV`);  
	return response.data as GetGalleryResponseType;
};

export const getAllGalleryApi = async (id?:string) =>{
	const response = await axiosInstance.get(`/organizations/apps/${id}/albums`);  
	return response.data as GetGalleryResponseType;
};

export const deleteAlbumApi = async (id?:string, albumId?: number) =>{
	const response = await axiosInstance.delete(`/organizations/apps/${id}/albums/${albumId}`);  
	return response.data;
};

export const deleteAlbumImageApi = async (id?:string, params?: number[]) =>{
	const response = await axiosInstance.delete(`/organizations/apps/${id}/imageUploads`, {
		data: {imageUploadIDs: params}
	});  
	return response.data;
};
