import { EyeIcon, TrashIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { IMAGES, capitalizeWords } from 'utility';
import Pagination from './Pagination';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { GetAllResellerResponseType } from 'services/requests/Approver/schema';
import { getAllReseller } from 'services/requests/Approver/approval';
import { useAtom } from 'jotai';
import { resellerAtom, searchInput } from 'store/approverAtom';


const ResellerPanel:React.FC = () => {
	const [searchedInput, searchInputSet] = useAtom(searchInput);
	const [resellerData, setResellerData] = useAtom(resellerAtom);
	const searchedData = resellerData?.rows?.filter(res => res.name.toLocaleLowerCase().includes(searchedInput.toLocaleLowerCase()));

	const { refetch } = useQuery<GetAllResellerResponseType, AxiosError>({
		queryKey:['getAllReseller'],
		queryFn: () => getAllReseller(),
		onSuccess: (data) => setResellerData(data),
		retry:0
	});
	
	

	return (
		<div className='bg-[#FAFAFA] w-full p-10'>
			<div className='text-[#2C3E6B] text-[20px] font-bold'>Reseller</div>
			<div className='w-full '>
				<div className='relative flex flex-col'>
					<form className='absolute right-4 bg-white rounded-[9px] w-[250px]'>
						<input
							className='w-full h-full block rounded-[5px] text-xs py-[8px] pl-[15px] pr-[41px] border focus:ring-1 focus:border-input-active outline-none'
							type='search'
							placeholder='Search..'
							value={searchedInput}
							onChange={(e) => searchInputSet(e.target.value)}
						/>
						<img className='flex absolute top-2 right-3 cursor-pointer' src={IMAGES.SearchIcon} alt='Search Icon' />
					</form>
					<div className='mt-12 p-5 bg-white rounded-lg shadow-md'>
						<div className='flex flex-col'>
							<div className='overflow-x-auto sm:-mx-6 lg:-mx-8 no-scrollbar'>
								<div className='inline-block min-w-full py-2 sm:px-6 lg:px-8'>
									<div className='h-[48vh] overflow-y-auto no-scrollbar'>
										<table className='min-w-full text-left text-sm font-light'>
											<thead
												className='border-b bg-white font-medium text-xs'>
												<tr>
													<th scope='col' className='px-2 py-2'>Name</th>
													<th scope='col' className='px-6 py-2'>Email</th>
													<th scope='col' className='px-5 text-center py-2'>Phone Number</th>
													<th scope='col' className='px-6 py-2'>Website</th>
													<th scope='col' className='px-6 text-start py-2'>Country</th>
													<th scope='col' className='px-6 text-start py-2'>Message</th>
													<th scope='col' className='px-12 text-center py-2'>Actions</th>
												</tr>
											</thead>
											<tbody className='border-b bg-white font-medium text-xs'>
												{searchedData?.map((reseller, index) => (
													<tr key={reseller.resellerID} className='py-2 odd:bg-gray-100 odd:shadow-sm odd:border even:bg-white'>
														<td className='px-2 py-2'>{reseller.name}</td>
														<td className='px-2 text-center py-2'>{reseller.email}</td>
														<td className='text-center py-2'>{reseller.phoneNumber}</td>
														<td className='px-2 text-center py-2'>{reseller.website}</td>
														<td className='px-2 text-center py-2'>{reseller.country}</td>
														<td className='px-2 text-center py-2'>{reseller.message}</td>
														
														<td className='px-4 flex justify-center text-center gap-2 py-6 font-normal text-xs'>
															<button onClick={() => ('')} >
																<EyeIcon className='h-5 w-auto text-[#3C5491]'/>
															</button>
															<button onClick={() => ('')}>
																<TrashIcon className='h-5 w-auto text-[#3C5491]'/>
															</button>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
									<Pagination/>

								</div>
							</div>
						</div>
					</div>
				</div>
					
			</div>
		</div>
	);
};

export default ResellerPanel;