/* eslint-disable no-mixed-spaces-and-tabs */
import { BellIcon, DocumentTextIcon, ExclamationCircleIcon, GlobeAltIcon, LockClosedIcon, PhoneIcon, PhotoIcon, QuestionMarkCircleIcon, ReceiptPercentIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { SBBackgroundColor, SBBackgroundImage, SBBlurBackgroundImage, SBOpacityBackgroundImage } from 'store/CustomizeApp/Appearance/backgroundAtom';
import { SBackgroundColor, SBackgroundImage, SBlurBackgroundImage, SIcon, SOpacityFlashBackgroundImage, SOverlayColor, SOverlayValue } from 'store/CustomizeApp/Appearance/flashScreenAtom';
import { SIsSidebarOpen, SSideBar } from 'store/CustomizeApp/Appearance/sideBarAtom';
import { useAtom, useAtomValue } from 'jotai';

import AccessibilityIcon from '@mui/icons-material/Accessibility';
import Body from './Body';
import Footer from './Footer';
import Head from './Head';
import { SAppearanceTab } from 'store/CustomizeApp/Appearance';
import { SFeaturesTab } from 'store/CustomizeApp/Features';
import { classNames } from 'utility';
import { stepperAtom } from 'store/CustomizeApp/Stepper';
import { FContents } from 'store/CustomizeApp/Features/contentAtom';
import { FNotif } from 'store/CustomizeApp/Features/notificationAtom';
import { FTempOffers } from 'store/CustomizeApp/Features/offersAtom';
import { FSocialMediasAtom } from 'store/CustomizeApp/Features/socialMediaAtom';
import { FGalleryImageAtom } from 'store/CustomizeApp/Features/galleryAtom';
import { FHelp } from 'store/CustomizeApp/Features/helpAtom';

const AppPreviewComponent: React.FC = () => {
    
	const selectedAppearanceTab = useAtomValue(SAppearanceTab);
	const selectedBackgroundFromBackground = useAtomValue(SBBackgroundImage);
	const opacity      = useAtomValue(SBOpacityBackgroundImage);
	const blur         = useAtomValue(SBBlurBackgroundImage);
	const color 	   = useAtomValue(SBBackgroundColor);
	const sideBar      = useAtomValue(SSideBar);

	const [blurValue, setBlurValue] = useState('blur-none');

	// Sidebar items
	// TODO: optimize
	const contents		= useAtomValue(FContents);
	const notifs 		= useAtomValue(FNotif);
	const offers		= useAtomValue(FTempOffers);
	const socialMedias	= useAtomValue(FSocialMediasAtom);
	const gallery		= useAtomValue(FGalleryImageAtom);
	const help			= useAtomValue(FHelp);

	useEffect(() => {
		if(blur == '0' ) setBlurValue('backdrop-blur-none');
		if(blur == '1' ) setBlurValue('backdrop-blur-[1px]');
		if(blur == '2' ) setBlurValue('backdrop-blur-[2px]');
		if(blur == '3' ) setBlurValue('backdrop-blur-[3px]');
		if(blur == '4' ) setBlurValue('backdrop-blur-[4px]');
		if(blur == '5' ) setBlurValue('backdrop-blur-[5px]');
		if(blur == '6' ) setBlurValue('backdrop-blur-[6px]');
		if(blur == '7' ) setBlurValue('backdrop-blur-[7px]');
		if(blur == '8' ) setBlurValue('backdrop-blur-[8px]');
		if(blur == '9' ) setBlurValue('backdrop-blur-[9px]');
		if(blur == '10' ) setBlurValue('backdrop-blur-[10px]');
	},[blur]);

	// ------------------ FLASH SCREEN ------------------//
	const flashColor   = useAtomValue(SBackgroundColor);
	const flashImage   = useAtomValue(SBackgroundImage);
	const flashOpacity = useAtomValue(SOpacityFlashBackgroundImage);
	const flashBlur    = useAtomValue(SBlurBackgroundImage);
	const overlayColor = useAtomValue(SOverlayColor);
	const overlayValue = useAtomValue(SOverlayValue);
	const flashIcon    = useAtomValue(SIcon);

	const [flashblurValue, setFlashBlurValue] = useState('blur-none');
	
	useEffect(() => {
		if(flashBlur == '0' ) setFlashBlurValue('backdrop-blur-none');
		if(flashBlur == '1' ) setFlashBlurValue('backdrop-blur-[1px]');
		if(flashBlur == '2' ) setFlashBlurValue('backdrop-blur-[2px]');
		if(flashBlur == '3' ) setFlashBlurValue('backdrop-blur-[3px]');
		if(flashBlur == '4' ) setFlashBlurValue('backdrop-blur-[4px]');
		if(flashBlur == '5' ) setFlashBlurValue('backdrop-blur-[5px]');
		if(flashBlur == '6' ) setFlashBlurValue('backdrop-blur-[6px]');
		if(flashBlur == '7' ) setFlashBlurValue('backdrop-blur-[7px]');
		if(flashBlur == '8' ) setFlashBlurValue('backdrop-blur-[8px]');
		if(flashBlur == '9' ) setFlashBlurValue('backdrop-blur-[9px]');
		if(flashBlur == '10' ) setFlashBlurValue('backdrop-blur-[10px]');
	},[flashBlur]);
	
	const [stepper, setStepper]  = useAtom(stepperAtom);
	const [featTab, setFeatTab]  = useAtom(SFeaturesTab);
	const [isSidebarOpen, setIsSidebarOpen] = useAtom(SIsSidebarOpen);
	
	// ------------------ SIDEBAR ------------------//
	
	const sideBarItems  = (sideBar.map((nav) => {
		let icon = <></>;

		switch (nav.label) {
		case 'Contact Us': 
			icon = <PhoneIcon className='w-6 h-6 text-[#707070]'/>;
			break;
		case 'Privacy and Policy':
			icon = <LockClosedIcon className='w-6 h-6 text-[#707070]'/>;
			break;
		case 'Accessibility':
			icon = <AccessibilityIcon className='w-6 h-6 text-[#707070]'/>;
			break;
		case 'Terms of Use':
			icon = <ExclamationCircleIcon className='w-6 h-6 text-[#707070]'/>;
			break;
		}

		return {
			id: 		nav.id, 
			label: 		nav.label, 
			tab: 		nav.label,
			isHidden: 	nav.isHidden, 
			isSub: 		nav.isSub,
			icon:		icon,
		};
	}));

	if (contents.length > 1) {
		const filteredContents = contents.filter((content) => content.toDelete === false);

		const slicedContents = filteredContents.slice(1);

		const sideBarContents = (slicedContents.map((content, index) => {
			let icon = <></>;
	
			content.icon != 'default' ? 
				(
					icon = <i
						className={classNames(
				  'ml-[0.05rem] pt-1 w-6 h-6 text-[#707070] text-lg text-center',
				  `${content.icon}`
						)}
			  />
				) : (
					icon = <DocumentTextIcon className='w-6 h-6 text-[#707070]'/>
				);
	
			return {
				id: 		0, 
				label:		content.title,
				tab: 		`Content[${index + 1}]`, 
				isHidden: 	false, 
				isSub: 		false,
				icon:		icon,
			};
		})).filter(item => item !== null) as typeof sideBarItems;

		sideBarItems.unshift(...sideBarContents);
	}

	sideBarItems.push(
		{
			id: 		0, 
			label: 		'Notifications',
			tab: 		'Notifications',
			isHidden: 	!(notifs.rows.length > 0), 
			isSub: 		false,
			icon:		<BellIcon className='w-6 h-6 text-[#707070]'/>,
		},
		{
			id: 		0, 
			label: 		'Offers',
			tab: 		'Offers',
			isHidden: 	!(offers.length > 0), 
			isSub: 		false,
			icon:		<ReceiptPercentIcon className='w-6 h-6 text-[#707070]'/>,
		},
		{
			id: 		0, 
			label: 		'Social Media', 
			tab: 		'Social Media',
			isHidden: 	!(socialMedias.length > 0), 
			isSub: 		false,
			icon:		<GlobeAltIcon className='w-6 h-6 text-[#707070]'/>,
		},
		{
			id: 		0, 
			label: 		'Gallery',
			tab: 		'Gallery', 
			isHidden: 	!(gallery?.length > 0), 
			isSub: 		false,
			icon:		<PhotoIcon className='w-6 h-6 text-[#707070]'/>,
		},
		{
			id: 		0, 
			label: 		'Help',
			tab: 		'Help',
			isHidden: 	!(help?.length > 0), 
			isSub: 		false,
			icon:		<QuestionMarkCircleIcon className='w-6 h-6 text-[#707070]'/>,
		},
	);

	const onClickSidebar = (item: string) => {
		if(stepper === 3){
			setFeatTab(item); 
			setIsSidebarOpen(false);
		}else{
			setStepper(1);
			setFeatTab(item);
		}
	};
    
	return(
		selectedAppearanceTab === 'flashScreen' && stepper === 0 ? 
			<div className='w-full h-full relative z-10'>
				{flashImage ? 
					<div className='absolute w-full h-full rounded-2xl'
						style={{opacity: `${flashOpacity}`}}>
						{flashImage && <img src={flashImage} className='absolute w-full h-full rounded-2xl object-cover'/>}
					</div>
					:
					<div className='absolute w-full h-full rounded-2xl'
						style={{ backgroundColor: `${flashColor}`, opacity: `${flashOpacity}`}}>
					</div>
				}
		
				<div className={'absolute w-full h-full rounded-2xl bg-cover bg-transparent bg-blend-overlay'}
					style={{ backgroundColor: `${overlayColor}`, opacity: `${overlayValue}`}}
				/>
				<div className={classNames('absolute w-full h-full rounded-2xl', `${flashblurValue}`)}/>
				<div className='absolute flex items-center justify-center w-full h-full'>
					{flashIcon && <img className='w-24 h-24 rounded-full' src={flashIcon} alt='icon' />}
				</div>
			</div>
			:
			<div className='w-full h-[568px] 2xl:h-[568px] xl:h-[568px] lg:h-[568px] md:h-[420px] sm:h-[400px] max-sm:h-[400px] min-sm:h-[390px] relative bg-[#F7F7F7] rounded-2xl mt-4'>
				<div className={classNames('absolute w-full h-full rounded-2xl')}
					style={{ backgroundColor: `${color}`,  opacity: `${opacity}`}}>
					{selectedBackgroundFromBackground && <img src={selectedBackgroundFromBackground} className='w-full h-full rounded-2xl object-cover'/> }
				</div>
				
				<div className={classNames('absolute w-full h-full rounded-2xl', `${blurValue}`)}/>
				{
					(stepper === 0 || stepper === 3) && selectedAppearanceTab === 'sideBar' && isSidebarOpen &&
						<div className={classNames('flex flex-col w-3/4 h-full absolute bg-[#FFFFFF] z-10 rounded-bl-xl shadow-md pl-2')}>
							<div className="flex pr-3 py-1.5 border-b border-gray-200">
								<h1 className="text-lg font-medium">Menu</h1>

								<button
									className="ml-auto text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
									aria-label="Close menu"
									onClick={() => {
										setIsSidebarOpen(!isSidebarOpen);
									}}
								>
            						x
								</button>
							</div>

							<div className="flex flex-col pt-3 gap-1 overflow-y-auto no-scrollbar">
								{
									sideBarItems.map((item, idx) => (
										<div key={idx} onClick={() => onClickSidebar(item.tab)} className={classNames(item.isSub ? 'pl-3' :'', item.isHidden ? 'hidden' : '', 'cursor-pointer')}>
											<div className='flex gap-1 pb-3 items-center'>
												{item.icon}					
												<span className='text-sm text-[#707070]'>{item.label}</span>
											</div>
										</div>
									))
								}
							</div>
						</div>
				}
				<Head />
				<Body />
				{featTab === 'Home' || featTab === 'Store' || featTab === 'About' || featTab === 'Content[0]' ? <Footer/> : ''}
			</div>
	);
};

export default AppPreviewComponent;