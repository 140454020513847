import React, { useLayoutEffect, useState } from 'react';

import Button from 'components/Common/Buttons/Button';
import { IMAGES } from 'utility/constant';
import { VideoModal } from 'components/Common/Modals/VideoModal';
import { classNames } from 'utility';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

const LandingPageHome = () => {
	const navigate = useNavigate();
	const [ videoModalShow, setVideoModalShow ] = useState(false);
	
	const handleModal = () => {
		setVideoModalShow(!videoModalShow);
	};

	useLayoutEffect(() => {
		document.body.style.backgroundColor = '#F6F6F6';
	});

	
	const lowerSmViewPort = useMediaQuery({ maxWidth: 500 });
	const iphoneSE = useMediaQuery({maxWidth: 376});

	return (
		<div className='max-sm:w-[100%] max-sm:h-screen lg:h-screen h-fit w-full font-poppins'>
			<VideoModal isVideoModalShow={videoModalShow} setVideoModalShow={handleModal}  onClickCancel={handleModal}/>
			<div className='bg-[#FFF7EA] h-full w-full flex '>
				<div
					className='w-full h-full absolute bg-primary z-0 2xl:h-full xl:h-full lg:h-full md:h-full sm:h-full max-sm:h-full
				 	clip-path-custom-circle self-end items-end text-end content-end'
				/>
				<div className='flex z-0 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col max-sm:flex-col md:mx-[49px] mx-[31px] h-full items-center'>
					<div className='text-[#171414] md:w-[50%] sm:w-[60%] w-full lg:mt-[-120px] mt-[25px]'>
						<div className='w-full'>
							<h1 className='font-semibold text-5xl max-sm:text-3xl'>Your Mobile App in Minutes</h1>
						</div>
						<div className='font-normal text-[#464255] w-full'>
							<p className='sm:mt-[18px] mt-[18px]'>
								Want to bring your mobile app idea to life? Share your vision with us, or
								get it done all by yourself with our mobile app builder software. Start
								building your dream app today!
							</p>
						</div>
						<div className='flex md:mt-[49px] sm:flex-row flex-col md:items-start items-center mt-[32px] gap-5'>
							<Button
								variant='primary'
								className='text-base font-semibold px-[26px] py-[12px] xs:w-[159px] w-[80%] sm:w-[60%]'
								onClick={() => navigate('/signup')}
							>
								Get Started
							</Button>
							<Button
								className='text-base font-semibold px-[26px] py-[12px] border-[#464255] hover:bg-gray-100 bg-transparent xs:w-[159px] w-[80%] sm:w-[60%]'
								onClick={() => handleModal()}
							>
								Watch Demo
							</Button>
						</div>
					</div>
					<div className={classNames('flex lg:mt-0 lg:mb-0 mt-[25px] max-sm:mt-0 sm:mt-0 flex-shrink flex-row items-center lg:justify-end justify-center w-full', iphoneSE ? 'max-sm:-mt-20': '')}>
						<img
							className={classNames('z-10 w-[236px] h-[476px] 2xl:w-[236px] 2xl:h-[476px] xl:w-[236px] xl:h-[476px] lg:w-[196px] lg:h-[395px] md:w-[196px] md:h-[395px] sm:w-[166px] sm:h-[334px] max-sm:w-[166px] max-sm:h-[334px]',
							lowerSmViewPort ? 'max-sm:mt-[10%] max-sm:w-[122.22px] max-sm:h-[60%]' 
							: 'max-sm:mt-[30px]',
							)}
							src={IMAGES.landingPageHome2}
							alt='Landing Image'
						/>
						<img
							className={classNames('z-20 w-[262px] h-[528px] 2xl:w-[262px] 2xl:h-[528px] xl:w-[262px] xl:h-[528px] lg:w-[222px] lg:h-[447px] md:w-[222px] md:h-[447px] sm:w-[192px] sm:h-[387px] max-sm:w-[192px] max-sm:h-[387px] mx-[-35px] sm:mx-[-45px] md:mx-[-70px]',
							lowerSmViewPort ? 'max-sm:mt-[10%] max-sm:w-[147.44px] max-sm:h-[70%]' 
							: 'max-sm:mt-[30px]',
							)}
							src={IMAGES.landingPageHome1}
							alt='Landing Image'
						/>
						<img
							className={classNames('z-10 w-[236px] h-[476px] 2xl:w-[236px] 2xl:h-[476px] xl:w-[236px] xl:h-[476px] lg:w-[196px] lg:h-[395px] md:w-[196px] md:h-[395px] sm:w-[166px] sm:h-[334px] max-sm:w-[166px] max-sm:h-[334px]',
							lowerSmViewPort ? 'max-sm:mt-[10%] max-sm:w-[122.22px] max-sm:h-[60%]' 
							: 'max-sm:mt-[30px]',
							)}
							src={IMAGES.landingPageHome3}
							alt='Landing Image'
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LandingPageHome;
