import React, { useEffect, useState } from 'react';

import Editor from 'components/Common/TextEditor';
import { FTerms } from '../../../../store/CustomizeApp/Features/terms';
import { IMAGES } from 'utility';
import { useAtom } from 'jotai';
import { TermsUseQuery } from '../UseQuery/TermsOfUseQueries';
import Tooltip from 'components/Common/Tooltip';

const TermsOfUse: React.FC = () => {
	
	TermsUseQuery();

	const [termsValue, setTermsValue] = useAtom(FTerms);
	const [editorHeight, setEditorHeight] = useState(10);
	useEffect(() => {
		const quill = document.querySelector('.ql-container');
		const resizeObserver = new ResizeObserver(entries => {
			if (entries.length > 0) {
				const editorHeight = entries[0].contentRect.height;
				setEditorHeight(editorHeight);
			}
		});
		if(quill) resizeObserver.observe(quill);

		return () => {
			if(quill) resizeObserver.unobserve(quill);
		};
	}, []);

	return (
		<div className='bg-white w-full rounded-lg my-8'>
			<div className='bg-white px-3 mx-3 rounded'>
				<div className='py-5 flex-row max-sm:pb-[270px]'>
					<div className='flex justify-between items-center py-2'>
						<span className='text-[#707070] font-bold text-2xl'>Terms Of Use</span>
                       
						<div className='flex gap-3 items-center'>
							{/* -----------Video Tutorial and Help Icons------------ */}
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.CameraIcon} alt='camera icon' />
							</Tooltip>
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.InfoIcon} alt='info icon' />
							</Tooltip>
							{/* ---------------------------------------------------- */}
						</div>
					</div>
					<div className='flex-row py-5'>
						<div className='flex-row py-2 w-full xl:w-[58.5rem] 2xl:w-[68rem] 3xl:w-full'>
							<span className='text-sm text-black font-bold'>Description</span>
							<div className='w-full py-4 h-[22rem] sm:h-[21rem] md:h-[19rem] lg:h-[19rem] mb-10' style={{height: editorHeight}}>
								<Editor value={termsValue} setValue={setTermsValue}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TermsOfUse;