import { FContents } from 'store/CustomizeApp/Features/contentAtom';
/* eslint-disable no-mixed-spaces-and-tabs */
import { FHomeDescription, FHomePreviewImage } from 'store/CustomizeApp/Features/homeAtom';
import { classNames, convertToTailwind } from 'utility';
import { useAtomValue, useSetAtom } from 'jotai';

import { FAbout } from 'store/CustomizeApp/Features/aboutAtom';
import { FAccessibility } from 'store/CustomizeApp/Features/accessibility';
import { FContact } from 'store/CustomizeApp/Features/contact';
import { FHelp } from 'store/CustomizeApp/Features/helpAtom';
import { FPrivacy } from 'store/CustomizeApp/Features/privacy';
import { FTerms } from 'store/CustomizeApp/Features/terms';
import Gallery from './Gallery';
import { Layouts } from 'enums/appearance/layouts';
import Notification from './Notification';
import Offers from './Offers';
import React, { useEffect, useState } from 'react';
import { SFeaturesTab } from 'store/CustomizeApp/Features';
import { SIsSidebarOpen } from 'store/CustomizeApp/Appearance/sideBarAtom';
import { SLayoutType } from 'store/CustomizeApp/Appearance/layoutAtom';
import SocialMedia from './SocialMedia';

const Body: React.FC = () => {

	const layoutType                       = useAtomValue(SLayoutType);
	const selectedBackgroundForHome        = useAtomValue(FHomePreviewImage);
	const featTab 						   = useAtomValue(SFeaturesTab);
	const contents						   = useAtomValue(FContents);

	const homeDescription   	= useAtomValue(FHomeDescription);
	const aboutDescription  	= useAtomValue(FAbout);
	const privacyDescription 	= useAtomValue(FPrivacy);
	const termsDescription 		= useAtomValue(FTerms);
	const accessDescription 	= useAtomValue(FAccessibility);
	const contactDescription 	= useAtomValue(FContact);
	const helpDescription = useAtomValue(FHelp);
	
	const [contentDescription, setContentDescription] 	= useState('');

	
	const description  = convertToTailwind(
		featTab === 'Home' ? homeDescription :
			featTab === 'About' ? aboutDescription :
				featTab === 'Privacy and Policy' ? privacyDescription :
					featTab === 'Terms of Use' ? termsDescription :
						featTab === 'Accessibility' ? accessDescription :
							featTab === 'Contact Us' ? contactDescription :
								featTab === 'Help' ? helpDescription :
									featTab.startsWith('Content') ? contentDescription ? contentDescription : ''
										: ''
	);
	
	const setSidebarOpen = useSetAtom(SIsSidebarOpen);

	useEffect(() => {
		const index = featTab.match(/\d+/);

		index && setContentDescription(contents[+index]?.description);
	}, [contents, featTab]);
	
	return(
		<>
			{layoutType === Layouts.Matrix ?
				featTab === 'Gallery' ? 
					<Gallery/>
					:
					featTab === 'Offers' ?
						<Offers/>
						:
						featTab === 'Notifications' ?
							<Notification/>
							:
							featTab === 'Social Media' ?
								<SocialMedia/>
								:
								<div onClick={() => {setSidebarOpen(false);}} className={classNames(featTab === 'Home' || featTab === 'Store' || featTab === 'About' || featTab === 'Content[0]' ? 'h-3/5' : 'h-full', 'w-full bg-[#F7F7F7] rounded-2xl pt-6 pb-10')}>
									<div className='w-full h-full relative'>
										<div className={classNames('absolute w-full h-full overflow-scroll no-scrollbar break-words mt-4')}>
											{featTab === 'Home' && <div className={selectedBackgroundForHome ? 'flex items-center justify-center h-[190px] px-3 mt-2' : ''}>
												{selectedBackgroundForHome && <img src={selectedBackgroundForHome} className='h-full'/>}
											</div>} 
												
											{featTab === 'Store' && <div className='flex justify-center items-center py-[180px]'>
												<p className='overflow-hidden text-center text-x font-medium text-xl'>Your<br /><b className='text-[#007CA8] text-3xl'>Shopify</b><br />storefront<br />here</p>
											</div>}
												
											<div className='px-3 pt-3 pb-8' dangerouslySetInnerHTML={{__html: description}}/>      			
										</div>
									</div>
								</div>
			 :
				layoutType === Layouts.ButtonsAndSideBar ?
					featTab === 'Gallery' ? 
						<Gallery/>
						:
						featTab === 'Offers' ?
							<Offers/>
							:
							featTab === 'Notifications' ?
								<Notification/>
								:
								featTab === 'Social Media' ?
									<SocialMedia/>
									:
									<div onClick={() => {setSidebarOpen(false);}} className='w-full h-full bg-[#F7F7F7] rounded-2xl pt-6 pb-10'>
										<div className='w-full h-full relative'>
											<div className={classNames('absolute w-full h-full overflow-scroll no-scrollbar break-words mt-4')}>
												{featTab === 'Home' && <div className={selectedBackgroundForHome ? 'flex items-center justify-center h-[190px] px-3 mt-2' : ''}>
													{selectedBackgroundForHome && <img src={selectedBackgroundForHome} className='h-full'/>}
												</div>} 
												
												{featTab === 'Store' && <div className='flex justify-center items-center py-[180px]'>
													<p className='overflow-hidden text-center text-x font-medium text-xl'>Your<br /><b className='text-[#007CA8] text-3xl'>Shopify</b><br />storefront<br />here</p>
												</div>}
												
												<div className='px-3 pt-3 pb-8' dangerouslySetInnerHTML={{__html: description}}/>      			
											</div>
										</div>
									</div> 
					: 
					<></>
			}
		</>
	);
};

export default Body;