import React, { useEffect, useState } from 'react';
import EditProfileSideBar from './EditProfileSideBar';
import EditProfilePanel from './EditProfilePanel';
import { getSelectedMenuProfileTabValue } from 'components/Common/Topbar/NavBarModal';
import { UpdateProfile } from 'services/requests/UpdateProfile';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { authAtom } from 'store/authAtom';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { getUserCredentials } from 'services/requests/auth';
import { GetUserCredentialsResponse } from 'services/requests/auth/schema';
import { hasUserCredentials, userCredentialsAtom } from 'store/authAtom';

import { log } from 'utility/log';


export interface ISideBarItems {
	title: string,
	link: string,
	visible: boolean,
}

const UserProfile: React.FC = () => {

	// const [selectedTabMenuOption, setSelectedTabMenuOption] = useState('');

	// let selectedProfileTabInitialValue = 'profile'

	// if (selectedMenuProfileTabValue !== 'profile') {
	// 	selectedProfileTabInitialValue = selectedMenuProfileTabValue;
	// }

	// const defaultProfileOptionValue = 'profile';
	const authData = useAtomValue(authAtom);
	const [userCredential, setUserCredential] = useAtom(userCredentialsAtom);
	const [hasUserCredential, setHasUserCredential] = useAtom(hasUserCredentials);
	
	const [selectedFeaturesOption, setSelectedFeaturesOption] = useState(getSelectedMenuProfileTabValue());
	const [sideBarItems,setSideBarItems] = useState<ISideBarItems[]>([]);
	
	//const [hasIdentityProvider, setHasIdentityProvider] = useState<boolean>(false);
	const userEmail = localStorage.getItem('email')?.replaceAll('"','');

	UpdateProfile();

	const { data: userCredentials, isLoading: userCredentialLoading, refetch: refetchUserCreds} = useQuery<GetUserCredentialsResponse, AxiosError>({
		queryKey: ['userCredentials'],
		queryFn: () => getUserCredentials(userEmail),
		onSuccess: (data) => {
			setUserCredential(data);

			if (data && JSON.stringify(data) !== '{}') { //check if data is not empty object
				setHasUserCredential(true);
			}
			else
			{
				console.log('user credential data is empty');
				setHasUserCredential(false);
			}
		},
		onError: (err) => {
			setUserCredential(undefined);
			setHasUserCredential(false);
			console.log('error from userCreds: ', err);
		}
	});


	useEffect(() => {
		if (getSelectedMenuProfileTabValue() !== 'profile') {
			setSelectedFeaturesOption(getSelectedMenuProfileTabValue);
			// setSelectedFeaturesOption(selectedMenuProfileTabValue)
		}
		// else{
		// 	setSelectedFeaturesOption('profile');
		// 	// setSelectedFeaturesOption(selectedMenuProfileTabValue)
		// }
	}, []);

	useEffect(() => {

		
		console.log('hasUserCredential: ', hasUserCredential);

		if(hasUserCredential) {
			setSideBarItems([
				{
					title: 'Profile',
					link: 'profile',
					visible: true,
				},
				{
					title: 'Password Settings',
					link: 'passwordReset',
					visible: true,
				},
				{
					title: 'Card Management',
					link: 'cardManagement',
					visible: true,
				}
			]);
			return;
		}
		else
		{
			setSideBarItems([
				{
					title: 'Profile',
					link: 'profile',
					visible: true,
				},
				
				{
					title: 'Card Management',
					link: 'cardManagement',
					visible: true,
				}
			]);
		}
		
		
	},[hasUserCredential]);


	{ /* 
	if (localStorage.getItem('federatedIdentity') != 'true') {
		sidebarItems.push({
			title: 'Password Settings',
			link: 'passwordReset'
		});	*/
	}

	return (
		<div className="h-fit w-full min-md:w-full max-md:w-full min-sm:w-full max-sm:w-full mx-auto">
			<div className='min-md:flex-col max-md:flex-col min-md:pr-4 max-md:pr-4 min-sm:flex-col max-sm:flex-col min-sm:pr-4 max-sm:pr-4 flex pl-12 2xl:pl-12 2xl:pr-0 xl:pl-12 xl:pr-0 lg:pl-4 lg:pr-4 md:pl-4 md:pr-4 sm:pl-4 max-sm:pl-4 py-12'>
				<EditProfileSideBar selectedOption={selectedFeaturesOption} setSelectedOption={setSelectedFeaturesOption} items={sideBarItems} />
				<EditProfilePanel selectedOption={selectedFeaturesOption} />
			</div>

		</div>
	);
};

export default UserProfile;