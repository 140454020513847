import {
	ArrowLeftOnRectangleIcon,
} from '@heroicons/react/24/solid';
import React, { useEffect, useRef, useState } from 'react';
import {
	SEditProfileFirstName,
	SEditProfileImage,
	SEditProfileImagePreview,
	SEditProfileLastName,
	SEditProfileUserId,
} from 'store/EditProfile/editProfileAtom';

import { IMAGES } from 'utility/constant';
import { capitalizeFirstLetter, capitalizeWords } from 'utility';
import { useAtom } from 'jotai';
import { string } from 'zod';
import { useQuery } from 'react-query';
import { getUserById, getUserByToken } from 'services/requests/user';
import { UserTokenResponse } from 'services/requests/user/schema';
import { AxiosError } from 'axios';

interface DropdownOption {
	value: string;
	label: string;
	icon: React.ReactNode;
}

interface DropdownProps {
	options: DropdownOption[];
	onSelect: (value: string) => void;
}

const UserTopbar = ({ options, onSelect }: DropdownProps) => {
	const [selectedOption, setSelectedOption] = useState<
		DropdownOption | undefined
	>(undefined);
	const [isOpen, setIsOpen] = useState(false);
	const [splitName, setSplitName] = useState<string[]>([]);
	const [editFirstName, setEditFirstName] = useAtom(SEditProfileFirstName);
	const [editLastName, setEditLastName] = useAtom(SEditProfileLastName);
	const [editImage, setEditImage] = useAtom(SEditProfileImagePreview);
	const dropDownRef = useRef<HTMLDivElement>(null);
	const [editUser, setEditUser] = useAtom(SEditProfileUserId);
	const token = localStorage.getItem('bearerToken');

	useEffect(() => {
		// Load data from local storage if available
		const firstName = localStorage.getItem('editFirstName');
		const lastName = localStorage.getItem('editLastName');

		// Set the values to the atoms if loaded from local storage
		if (firstName) {
			setEditFirstName(firstName);
			setSplitName(firstName.split(' '));
		}
		if (lastName) setEditLastName(lastName);

		const handleClickOutside = (event: MouseEvent) => {
			if (
				dropDownRef.current &&
				!dropDownRef.current.contains(event.target as Node)
			) {
				setIsOpen(false);
			}
		};
		window.addEventListener('click', handleClickOutside);

		return () => {
			window.removeEventListener('click', handleClickOutside);
		};
	}, []);

	const handleOptionClick = (option: DropdownOption) => {
		setIsOpen(false);
		onSelect(option.value);
	};

	// Save the values to local storage whenever they change
	useEffect(() => {
		localStorage.setItem('editFirstName', editFirstName || '');
		localStorage.setItem('editLastName', editLastName || '');

		const firstName = localStorage.getItem('editFirstName');
		const lastName = localStorage.getItem('editLastName');

		if (firstName) {
			setEditFirstName(firstName);
			setSplitName(firstName.split(' '));
		}
		if (lastName) setEditLastName(lastName);
	}, [editFirstName, editLastName]);

	const { data: editProfileByToken } = useQuery<UserTokenResponse, AxiosError>({
		queryKey: ['editProfileByToken'],
		queryFn: () => getUserByToken({ token: token }),
		retry: 0,
	});

	const { data: editProfileData } = useQuery({
		queryKey: ['user', editUser],
		queryFn: () => getUserById(editUser),
		retry: 0,
		enabled: editUser != undefined
	});

	useEffect(() => {
		if (editProfileByToken) {
			setEditUser(editProfileByToken.userID);
		}
		if (editProfileData) {
			localStorage.setItem('userData', JSON.stringify(editProfileData));
			setEditFirstName(editProfileData.user.firstName);
			setEditLastName(editProfileData.user.lastName);
			setEditImage(editProfileData.user.imageURL);
			setEditImage(editProfileData.user.imageURL);
		}
	}, [editProfileByToken, editProfileData]);

	return (
		<div className='relative inline-block' ref={dropDownRef}>
			<div
				className='rounded-md py-2 cursor-pointer'
				onClick={() => setIsOpen(!isOpen)}
			>
				{selectedOption ? (
					selectedOption.label
				) : (
					<>
						<div className='flex items-center text-xs text-[#464255]'>
							{ splitName[0] && 
								`Hi, ${
									splitName[0] && splitName[0].length > 10 ?
										capitalizeWords(splitName[0]).substring(0, 10) + '...'
										: capitalizeWords(splitName[0])
								}`
							}
							<span className='relative w-[27px] h-[27px] ml-3 rounded-full border'>
								{editImage === '' || editImage === undefined ? (
									editFirstName !== undefined ? (
										<p className='flex justify-center items-center w-full h-full rounded-full text-center bg-primary text-white font-semibold'>
											{capitalizeFirstLetter(editFirstName)}
										</p>
									) : null
								) : (
									<img
										className='w-full h-full rounded-full'
										src={editImage}
										alt='user-icon'
									/>
								)}
							</span>
						</div>
					</>
				)}
			</div>
			{isOpen && (
				<ul className='w-[200px] list-none absolute z-50 top-full right-0 border border-gray-300 rounded-md shadow-md py-1 bg-white'>
					{options.map((option) => (
						<li
							key={option.value}
							className='flex flex-row py-2 text-end cursor-pointer rounded-sm w-full -ml-3 hover:bg-gray-100'
							onClick={() => handleOptionClick(option)}
						>
							<div className='flex w-full gap-4'>
								<div>{option.icon}</div>
								<div className='font-normal'>{option.label}</div>
							</div>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default UserTopbar;
