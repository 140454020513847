import React, { useRef } from 'react';
import { IMAGES } from 'utility';
import SocialMediaCards from './Cards';
import { Button } from 'components/Common/Buttons';
import { useAtom, useAtomValue } from 'jotai';
import { FDeleteSocialIdsAtom, FSocialMediaInitVal, FSocialMediasAtom } from 'store/CustomizeApp/Features/socialMediaAtom';
import { useParams } from 'react-router';
import { SocialMediaUseQuery } from '../UseQuery/SocialMediaQueries';
import Tooltip from 'components/Common/Tooltip';

const SocialMedia: React.FC = () => {

	SocialMediaUseQuery();

	const [socialMedias, setSocialMedias] = useAtom(FSocialMediasAtom);
	const [socialMediaIds, setSocialMediaIds] = useAtom(FDeleteSocialIdsAtom);

	const defaultInit = useAtomValue(FSocialMediaInitVal);
	const appIdParams = useParams();
	const dragItem = useRef();
	const dragOverItem = useRef();


	const handleAddMore = () => {
		setSocialMedias(prev => [...prev, defaultInit]);
	};

	const handleOnDelete = (index: number) => {
		const temp = [...socialMedias];
		setSocialMediaIds(prev => [...prev, {
			appId: String(temp[index].appId),
			socialMediaID: String(temp[index].socialMediaID)
		}]);
		temp.splice(index, 1);
		setSocialMedias(temp);
		//
	};

	const handleOnChange = (label: string, webLink: string, icon: string, index: number) => {
		const temp = [...socialMedias];
		if (!temp[index].appId && !temp[index].socialMediaID) {
			temp[index] = {
				icon: icon,
				label: label,
				WebsiteLink: webLink,
				sortOrder: index
			};
		} else {
			temp[index] = {
				appId: temp[index].appId,
				socialMediaID: temp[index].socialMediaID,
				icon: icon,
				label: label,
				WebsiteLink: webLink,
				sortOrder: index
			};
		}

		setSocialMedias(temp);
		//
	};

	const dragStart = (e: React.DragEvent<HTMLDivElement>, position: any) => {
		dragItem.current = position;
	};

	const dragEnter = (e: React.DragEvent<HTMLDivElement>, position: any) => {
		dragOverItem.current = position;
	};

	const drop = () => {
		const copyListItems = [...socialMedias];
		const dragItemContent = copyListItems[dragItem.current === undefined ? 0 : dragItem.current];
		copyListItems.splice(dragItem.current === undefined ? 0 : dragItem.current, 1);
		copyListItems.splice(dragOverItem.current === undefined ? 0 : dragOverItem.current, 0, dragItemContent);
		dragItem.current = undefined;
		dragOverItem.current = undefined;
		setSocialMedias(copyListItems);
	};

	return (
		<div className='bg-white w-full rounded-lg px-6 my-8 '>
			<div className='bg-white rounded'>
				<div className='flex-row py-[16.3px] text-white font-semibold max-sm:pb-[270px]'>
					<div className='flex flex-col justify-between'>
						<div className='flex gap-3 items-center w-full float-right justify-between'>
							<span className='text-[#707070] font-bold text-2xl'>Social Media</span>
							{/* -----------Video Tutorial and Help Icons------------ */}
							<div className='flex flex-row gap-2'>
								<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
									<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.CameraIcon} alt='camera icon' />
								</Tooltip>
								<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
									<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.InfoIcon} alt='info icon' />
								</Tooltip>
							</div>
							{/* ---------------------------------------------------- */}
						</div>
						<div className='flex justify-end items-center mt-3'>
							<Button className=' font-semibold text-[16px] py-[7px] px-[16px] max-sm:py-[4px] max-sm:px-[13px] max-sm:text-[12px]'
								variant='primary'
								onClick={() => handleAddMore()}
							> + Add More
							</Button>
						</div>

					</div>
					<div className={`${socialMedias.length > 2 ? 'h-[460px] p-4' : 'no-scrollbar'} overflow-y-scroll `}>
						{socialMedias.map((socialMedia, index) => (
							<div key={index} className={'mt-7 '}>
								<SocialMediaCards
									cardData={socialMedia}
									index={index}
									handleDelete={handleOnDelete}
									handleOnChange={handleOnChange}
									onDragEnter={dragStart}
									onDragStart={dragEnter}
									onDragEnd={drop} />
							</div>
						))}
					</div>

				</div>
			</div>
		</div>
	);
};

export default SocialMedia;
