import React, { useEffect } from 'react';
import { IMAGES } from 'utility';
import Footer from 'components/Common/Footer';

const PrivacyPolicy: React.FC =() => {

	useEffect(() => {
		window.scrollTo(0,0);
	},[]);
      
	return (
		<div className='w-full h-full'>
			<div id='my-header' className='bg-white flex justify-center items-center flex-col'>
				<div className='bg-white relative w-full h-[50vh] flex justify-center items-center'>
					<img className='object-cover w-full h-full' src={IMAGES.PrivacyPolicyImage}/>
					<span className='absolute font-bold text-white text-[60px] max-sm:text-[42px] text-center tracking-wider'>Privacy Policy</span>
				</div>
				<div className='bg-white relative w-full h-full pl-24 pr-24 max-sm:pl-8 max-sm:pr-8 tracking-wider mt-16'>
					<p className='font-bold text-[#403E3C]'>Introduction</p>
					<p className='mt-5'>This Privacy Policy describes how SnapToApp (“we,” “us,” or “our”) collects, uses, and shares information when you use our mobile app building platform (“Service” or “SnapToApp”). We are committed to protecting your privacy and the security of your personal information.</p>
					<p className='mt-5'>Information We Collect</p>
					<p className='mt-5'>We collect information about you when you use our Service, such as:</p>
					<p className='mt-5'>- Information you provide: When you sign up for an account or use our Service, we may collect your name, email address, and other contact information. </p>
					<p>- Information from your device: We may collect information about your device, such as your IP address, browser type, and operating system, to help us provide you with a better user experience. </p>
					<p>- Information from cookies and similar technologies: We may use cookies and other technologies to collect information about your use of our Service, such as pages you visit, links you click, and time spent on our Service.</p>
				</div>
				<div className='bg-white relative w-full h-full pl-24 pr-24 max-sm:pl-8 max-sm:pr-8 tracking-wider mt-6'>
					<p className='font-bold text-[#403E3C]'>How We Use Your Information</p>
					<p className='mt-5'>We may use your information to:</p>
					<p className='mt-5'>- Provide and improve our Service: We use your information to provide our Service to you, to personalize your experience, and to improve our Service.</p>
					<p>- Communicate with you: We may use your contact information to communicate with you about our Service, such as to send you notifications or updates. </p>
					<p>- Analyze and aggregate data: We may analyze and aggregate data to understand trends and usage patterns, and to improve our Service.</p>
					<p>- Enforce our policies: We may use your information to enforce our policies and terms of service.</p>
				</div>
				<div className='bg-white relative w-full h-full pl-24 pr-24 max-sm:pl-8 max-sm:pr-8 tracking-wider mt-6'>
					<p className='font-bold text-[#403E3C]'>Sharing Your Information</p>
					<p className='mt-5'>We do not sell your personal information to third parties. We may share your information with:</p>
					<p className='mt-5'>- Service providers: We may share your information with service providers who help us operate our Service, such as hosting providers, payment processors, and analytics providers.</p>
					<p>- Other users: We may share information about you with other users of our Service, such as your name and profile information.</p>
					<p>- Legal and safety purposes: We may disclose your information to comply with applicable laws or regulations, to respond to a subpoena, court order, or other lawful request, or to protect the rights, property, or safety of us, our users, or others.</p>
					<p>- Enforce our policies: We may use your information to enforce our policies and terms of service.</p>
				</div>
				<div className='bg-white relative w-full h-full pl-24 pr-24 max-sm:pl-8 max-sm:pr-8 tracking-wider mt-6'>
					<p className='font-bold text-[#403E3C]'>Your Choices</p>
					<p className='mt-5'>You can choose not to provide certain information to us, but this may limit your ability to use our Service. You can also choose to opt-out of certain communications from us.</p>
				</div>
				<div className='bg-white relative w-full h-full pl-24 pr-24 max-sm:pl-8 max-sm:pr-8 tracking-wider mt-6'>
					<p className='font-bold text-[#403E3C]'>Security</p>
					<p className='mt-5'>We take reasonable measures to protect your information from unauthorized access, use, disclosure, alteration, or destruction. However, no security measure is completely foolproof, and we cannot guarantee the security of your information.</p>
				</div>
				<div className='bg-white relative w-full h-full pl-24 pr-24 max-sm:pl-8 max-sm:pr-8 tracking-wider mt-6'>
					<p className='font-bold text-[#403E3C]'>Changes to This Privacy Policy</p>
					<p className='mt-5'>We may update this Privacy Policy from time to time. If we make material changes to this Privacy Policy, we will notify you by email or by posting a notice on our Service.</p>
				</div>
				<div className='bg-white relative w-full h-full pl-24 pr-24 max-sm:pl-8 max-sm:pr-8 tracking-wider mt-6'>
					<p className='font-bold text-[#403E3C]'>Contact Us</p>
					<p className='my-10 mb-40'>If you have any questions or concerns about our Privacy Policy, please contact us at <span><a id='nav-link' className='text-[#C34115] font-semibold underline' href="mailto:info@snaptoapp.com">info@snaptoapp.com</a></span></p>
				</div>
			</div>
		</div>
	);

};

export default PrivacyPolicy;