import Button from 'components/Common/Buttons/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Error403:React.FC = () => {

	const navigate = useNavigate();
	return(
		<div className='w-full h-full relative'>
			<img src='https://snaptoapp-dev.s3.us-west-2.amazonaws.com/public/error_background/man-holding-broken-phone.png' className='w-full absolute'/>
			<div className='absolute text-center mt-52 ml-36'>
				<span className='text-[#007CA8] font-bold text-9xl block py-2'>403</span>
				<span className='text-[#007CA8] font-bold text-4xl block py-2'>You don&apos;t have</span>
				<span className='text-[#007CA8] font-bold text-4xl block py-2'>permission to access this server</span>
				<Button className='my-2 border-none rounded-md bg-[#007CA8]' onClick={() => navigate('/dashboard')}>
					<span className='text-white font-bold text-xl block py-2 px-4'>Back to home</span>
				</Button>
			</div>
		</div>
	);
};

export default Error403;