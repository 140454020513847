import {
	AppFeatIsSaving,
	SAppName,
	SAppNameChanges,
	SAppearanceID,
	SAppearanceTab,
	SBackgroundID,
	SBackgroundIDLive,
	SColorID,
	SColorIDLive,
	SFaviconID,
	SFaviconIDLive,
	SFlashScreenID,
	SFlashScreenIDLive,
	SFontID,
	SFontIDLive,
	SIndustryID,
	SIsAppRefresh,
	SIsAppearanceChanges,
	SIsAppearanceRefresh,
	SIsBackgroundChanges,
	SIsColorChanges,
	SIsFaviconChanges,
	SIsFontChanges,
	SIsLayoutChanges,
	SIsLeavingSectionOpen,
	SIsNavigationChanges,
	SIsOgTagsChanges,
	SIsSideBarChanges,
	SIsThemeChanges,
	SIsThemeLayoutRefresh,
	SNavigationID,
	SNavigationIDLive,
	SOgTagsID,
	SOgTagsLive,
	SSideBarID,
	SSideBarIDLive,
	SUserID,
	SisAppDone,
	SisBackgroundDone,
	SisColorDone,
	SisFaviconDone,
	SisFlashDone,
	SisFontDone,
	SisNavigationDone,
	SisOgTagsDone,
	SisSideBarDone,
	SisThemeLayoutDone,
} from 'store/CustomizeApp/Appearance';
import { FIsAboutChanges, FIsAccessChanges, FIsContactChanges, FIsContentChanges, FIsFeaturesChange, FIsGalleryChanges, FIsHelpChanges, FIsHomeChanges, FIsNotifChanges, FIsOffersChanges, FIsPrivacyChanges, FIsSocialMediaChanges, FIsStoreChanges, FIsTermsChanges, SFeaturesTab } from 'store/CustomizeApp/Features';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
	SIndustrySubCategory,
	SIsFlashRefresh,
	SIsSubCatFetched,
} from 'store/CustomizeApp/Appearance/flashScreenAtom';
import { SSubCategErrorMessage, SSubCategErrorModalShow } from 'store/subCategEmptyAtom';
import {
	getAppearanceByAppID,
	getAppearanceByAppIDLive,
} from 'services/requests/appearance';
import { stepperAtom, tempStepper } from 'store/CustomizeApp/Stepper';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import About from './Features/About';
import { AboutUseQuery } from './Features/UseQuery/AboutQueries';
import Accessibility from './Features/Accessibility';
import { AccessibilityUseQuery } from './Features/UseQuery/AccessibilityQueries';
import AppMutation from './Appearance/UseMutation/App';
import AppPreview from '../AppPreview';
import AppPreviewPublish from 'components/AppPreview/Publish';
import AppearanceTab from './Appearance';
import { AxiosError } from 'axios';
import BackgroundPage from './Appearance/Background';
import Color from './Appearance/Color';
import Contact from './Features/Contact';
import { ContactUseQuery } from './Features/UseQuery/ContactQueries';
import Content from './Features/Content';
import { ContentQuery } from './Features/UseQuery/ContentQueries';
import CustomizeStepper from 'components/Steppers/CustomizeStepper';
import Error403 from 'components/Common/Error/Error403';
import { FContentError, FShowContentErrorModal, FContents, FContentsRefresh, FContentErrorDescription } from 'store/CustomizeApp/Features/contentAtom';
import { FIsAboutRefresh } from 'store/CustomizeApp/Features/aboutAtom';
import { FIsAccessibilityRefresh } from 'store/CustomizeApp/Features/accessibility';
import { FIsContactRefresh } from 'store/CustomizeApp/Features/contact';
import { FIsGalleryRefresh } from 'store/CustomizeApp/Features/galleryAtom';
import { FIsHelpRefresh } from 'store/CustomizeApp/Features/helpAtom';
import { FIsHomeRefresh } from 'store/CustomizeApp/Features/homeAtom';
import { FIsNotifcationRefresh } from 'store/CustomizeApp/Features/notificationAtom';
import { FIsOfferRefresh } from 'store/CustomizeApp/Features/offersAtom';
import { FIsPrivacyRefresh } from 'store/CustomizeApp/Features/privacy';
import { FIsStoreRefresh } from 'store/CustomizeApp/Features/storeAtom';
import { FIsTermsRefresh } from 'store/CustomizeApp/Features/terms';
import FeaturesTab from './Features';
import FlashScreenPage from './Appearance/FlashScreen';
import FontPage from './Appearance/Font';
import { FsocialMediasRefreshAtom } from 'store/CustomizeApp/Features/socialMediaAtom';
//Home
import Gallery from './Features/Gallery';
import {
	GetAppResponse,
} from 'services/requests/apps/schema';
import {
	GetAppearanceByAppIdResponse,
} from 'services/requests/appearance/schema';
import Help from './Features/Help';
import HomePage from './Features/Home';
import { HomeUseQuery } from './Features/UseQuery/HomeQueries';
import Images from './Appearance/Images';
import Layout from './Appearance/Layout';
import { LeaveSectionModal } from 'components/Common/Modals/LeaveSectionModal';
import LoadingScreen from 'components/Common/Loading';
import Navigation from './Appearance/Navigation';
import Notification from './Features/Notification';
import Offers from './Features/Offers';
import PaymentTab from './Payment';
import { PaymentUseQueries } from './Payment/UserQueries';
import { PencilIcon } from '@heroicons/react/24/outline';
import PrivacyAndPolicy from './Features/PrivacyandPolicy';
import { PrivacyUseQuery } from './Features/UseQuery/PrivacyQueries';
import PublishTab from './Publish';
import { SBBackgroundRefresh } from 'store/CustomizeApp/Appearance/backgroundAtom';
import { SIsColorRefresh } from 'store/CustomizeApp/Appearance/colorAtom';
import { SIsFontRefresh } from 'store/CustomizeApp/Appearance/fontAtom';
import { SIsNavRefresh } from 'store/CustomizeApp/Appearance/navigationAtom';
import { SIsSidebarRefresh } from 'store/CustomizeApp/Appearance/sideBarAtom';
import { SLayoutId } from 'store/CustomizeApp/Appearance/layoutAtom';
import { SThemeId } from 'store/CustomizeApp/Appearance/themeAtom';
import SideBar from './Appearance/SideBar';
import SocialMedia from './Features/SocialMedia';
import StorePage from './Features/Store';
import { SubCategoryModal } from 'components/Common/Modals/SubCategoryModal';
import TermsOfUse from './Features/TermsOfUse';
import { TermsUseQuery } from './Features/UseQuery/TermsOfUseQueries';
import Theme from './Appearance/Theme';
import { UseAppearanceQueries } from './Appearance/UseQueries';
import { classNames } from 'utility';
import { getAppsById } from 'services/requests/apps';
import { useQuery } from 'react-query';
import { NotificationQuery } from './Features/UseQuery/NotificationQueries';
import { OfferUseQuery } from './Features/UseQuery/OfferQueries';
import { SocialMediaUseQuery } from './Features/UseQuery/SocialMediaQueries';
import { GalleryUseQuery } from './Features/UseQuery/GalleryQueries';
import { HelpUseQuery } from './Features/UseQuery/HelpQueries';
import { PIsJustPublished } from 'store/CustomizeApp/Publish/publishAtom';
import PartialFooter from 'components/Common/Footer/PartialFooter';
import AlertModal from 'components/Common/Modals/AlertModal';
import { SIsFaviconRefresh } from 'store/CustomizeApp/Appearance/imagesAtoms/favicon';
import { SIsOgTagsRefresh } from 'store/CustomizeApp/Appearance/imagesAtoms/ogTagsAtoms';
import { FEditorError, FShowEditorErrorModal } from 'store/createAppAtom';
import { StoreUseQuery } from './Features/UseQuery/StoreQueries';

const CustomizeApp: React.FC = () => {
	const [currentStep, setCurrentStep] = useAtom(stepperAtom);
	const tempStep = useAtomValue(tempStepper);
	const selectedAppearanceTab = useAtomValue(SAppearanceTab);
	const [selectedFeaturesOption] = useAtom(SFeaturesTab);
	const setAppearanceId = useSetAtom(SAppearanceID);
	const setIndustryID = useSetAtom(SIndustryID);
	const setUserID = useSetAtom(SUserID);

	const [isSaving, setIsSaving] = useAtom(AppFeatIsSaving);
	//Atoms for Flash Screen
	const setSelectedFlashScreenID =useSetAtom(SFlashScreenID);
	const setSelectedBackgroundID = useSetAtom(SBackgroundID);
	const setSelectedNavigationID = useSetAtom(SNavigationID);
	const setSelectedSideBarID = useSetAtom(SSideBarID);
	const setSelectedColorID = useSetAtom(SColorID);
	const setSelectedFontID = useSetAtom(SFontID);
	const setSelectedFaviconID = useSetAtom(SFaviconID);
	const setSelectedOgTagsID = useSetAtom(SOgTagsID);

	const [isAppDone, setAppDone ]= useAtom(SisAppDone);
	const isFlashDone = useAtomValue(SisFlashDone);
	const isThemeLayoutDone = useAtomValue(SisThemeLayoutDone);
	const isBackgroundDone = useAtomValue(SisBackgroundDone);
	const isNavDone = useAtomValue(SisNavigationDone);
	const isSidebarDone = useAtomValue(SisSideBarDone);
	const isColorDone = useAtomValue(SisColorDone);
	const isFontDone = useAtomValue(SisFontDone);
	const isFaviconDone = useAtomValue(SisFaviconDone);
	const isOgTagsDone = useAtomValue(SisOgTagsDone);
	const isSubCatFetched = useAtomValue(SIsSubCatFetched);

	const setSelectedFlashScreenIDLive =useSetAtom(SFlashScreenIDLive);
	const setSelectedBackgroundIDLive = useSetAtom(SBackgroundIDLive);
	const setSelectedNavigationIDLive = useSetAtom(SNavigationIDLive);
	const setSelectedSideBarIDLive = useSetAtom(SSideBarIDLive);
	const setSelectedColorIDLive = useSetAtom(SColorIDLive);
	const setSelectedFontIDLive = useSetAtom(SFontIDLive);
	const setSelectedFaviconLive = useSetAtom(SFaviconID);
	const setSelectedOgTagsLive = useSetAtom(SOgTagsID);

	const [isFlash ,setIsFlashRefresh] = useAtom(SIsFlashRefresh);
	const setThemeLayoutRefresh = useSetAtom(SIsThemeLayoutRefresh);
	const setSelectedLayoutID = useSetAtom(SLayoutId);
	const setSelectedThemeID = useSetAtom(SThemeId);
	const setIsBackgroundRefresh = useSetAtom(SBBackgroundRefresh);
	const setIsNavigationRefresh = useSetAtom(SIsNavRefresh);
	const setIsSideBarRefresh = useSetAtom(SIsSidebarRefresh);
	const setIsColorRefresh = useSetAtom(SIsColorRefresh);
	const setIsFontRefresh = useSetAtom(SIsFontRefresh);
	const setIsFaviconRefresh = useSetAtom(SIsFaviconRefresh);
	const setIsOgTagsRefresh = useSetAtom(SIsOgTagsRefresh);

	const setHomeRefresh = useSetAtom(FIsHomeRefresh);
	const setStoreRefresh = useSetAtom(FIsStoreRefresh);
	const setAboutRefresh = useSetAtom(FIsAboutRefresh);
	const setContentRefresh = useSetAtom(FContentsRefresh);
	const setPrivacyRefresh = useSetAtom(FIsPrivacyRefresh);
	const setTermsRefresh = useSetAtom(FIsTermsRefresh);
	const setAccessRefresh = useSetAtom(FIsAccessibilityRefresh);
	const setContactRefresh = useSetAtom(FIsContactRefresh);
	const setGalleryRefresh = useSetAtom(FIsGalleryRefresh);
	const setHelpRefresh = useSetAtom(FIsHelpRefresh);
	const setOfferRefresh = useSetAtom(FIsOfferRefresh);
	const setNotifRefresh = useSetAtom(FIsNotifcationRefresh);
	const setSocMedRefresh = useSetAtom(FsocialMediasRefreshAtom);

	const setAppChanges = useSetAtom(SAppNameChanges);
	const setFlashScreenChanges = useSetAtom(SIsAppearanceChanges);
	const setLayoutChanges = useSetAtom(SIsLayoutChanges);
	const setThemeChanges = useSetAtom(SIsThemeChanges);
	const setBackgroundChanges = useSetAtom(SIsBackgroundChanges);
	const setNavChanges = useSetAtom(SIsNavigationChanges);
	const setSidebarChanges = useSetAtom(SIsSideBarChanges);
	const setColorChanges = useSetAtom(SIsColorChanges);
	const setFontChanges = useSetAtom(SIsFontChanges);
	const setFaviconChanges = useSetAtom(SIsFaviconChanges);
	const setOgTagsChanges = useSetAtom(SIsOgTagsChanges);

	const setHomeChanges = useSetAtom(FIsHomeChanges);
	const setStoreChanges = useSetAtom(FIsStoreChanges);
	const setAboutChanges = useSetAtom(FIsAboutChanges);
	const setContentChanges = useSetAtom(FIsContentChanges);
	const setPrivacyChanges = useSetAtom(FIsPrivacyChanges);
	const setTermsChanges = useSetAtom(FIsTermsChanges);
	const setAccessChanges = useSetAtom(FIsAccessChanges);
	const setContactChanges = useSetAtom(FIsContactChanges);
	const setGalleryChanges = useSetAtom(FIsGalleryChanges);
	const setHelpChanges = useSetAtom(FIsHelpChanges);
	const setOfferChanges = useSetAtom(FIsOffersChanges);
	const setNotifChanges = useSetAtom(FIsNotifChanges);
	const setSocMedChanges = useSetAtom(FIsSocialMediaChanges);

	const [isJustPublished, setJustPublished] = useAtom(PIsJustPublished);

	const contents = useAtomValue(FContents);

	const navigate = useNavigate();

	const renderAppearanceBody = () => {
		if (selectedAppearanceTab === 'flashScreen') return <FlashScreenPage />;
		if (selectedAppearanceTab === 'layout') return <Layout />;
		if (selectedAppearanceTab === 'theme') return <Theme />;
		if (selectedAppearanceTab === 'background') return <BackgroundPage />;
		if (selectedAppearanceTab === 'navigation') return <Navigation />;
		if (selectedAppearanceTab === 'sideBar') return <SideBar />;
		if (selectedAppearanceTab === 'color') return <Color />;
		if (selectedAppearanceTab === 'font') return <FontPage />;
		if (selectedAppearanceTab === 'images') return <Images />;
		return;
	};

	const renderFeaturesBody = () => {
		if (selectedFeaturesOption === 'Home') return <HomePage />;
		if (selectedFeaturesOption === 'Store') return <StorePage />;
		if (selectedFeaturesOption === 'About') return <About />;
		if (selectedFeaturesOption.startsWith('Content')) return <Content />;
		// if (selectedFeaturesOption === 'Content') return <Content />;
		// if (selectedFeaturesOption === 'content2') return <Content2 />;
		// if (selectedFeaturesOption === 'content3') return <Content3 />;
		// if (selectedFeaturesOption === 'content4') return <Content4 />;
		// if (selectedFeaturesOption === 'content5') return <Content5 />;
		if (selectedFeaturesOption === 'Contact Us') return <Contact />;
		if (selectedFeaturesOption === 'Help') return <Help />;
		if (selectedFeaturesOption === 'Offers') return <Offers />;
		if (selectedFeaturesOption === 'Notifications') return <Notification />;
		if (selectedFeaturesOption === 'Privacy and Policy') return <PrivacyAndPolicy />;
		if (selectedFeaturesOption === 'Terms of Use') return <TermsOfUse />;
		if (selectedFeaturesOption === 'Accessibility') return <Accessibility />;
		if (selectedFeaturesOption === 'Gallery') return <Gallery />;
		if (selectedFeaturesOption === 'Social Media') return <SocialMedia />;
		return;
	};

	const { id } = useParams();
	const inputRef = useRef<HTMLInputElement>(null);
	const [isEditable, setIsEditable] = useState(false);
	const [appName, setAppName] = useAtom(SAppName);
	const [subcategoryID, setSubcategoryID]	= useAtom(SIndustrySubCategory);
	const [open, setOpen] = useAtom(SIsLeavingSectionOpen);
	const [isAppearanceRefresh, setIsAppearanceRefresh] = useAtom(SIsAppearanceRefresh);
	const [isAppRefresh, setIsAppRefresh] = useAtom(SIsAppRefresh);

	const [contentErrorModalShow, setContentErrorModalShow] = useAtom(FShowContentErrorModal);
	const [contentError, setContentError] = useAtom(FContentError);
	const [contentErrorDescription, setContentErrorDescription] = useAtom(FContentErrorDescription);

	const [showEditorErrorModal, setShowEditorErrorModal] = useAtom(FShowEditorErrorModal);
	
	const handleEditClick = () => {
		setIsEditable(true);
		if (inputRef.current) {
			inputRef.current.focus();
		}
	};

	const handleTitleChange = (e: any) => {
		setAppName(e.target.value);
	};

	const handleTitleBlur = () => {
		setIsEditable(false);
	};
	
	//App
	const { data: appData, refetch: refetchApp, isFetched: fetchedApp } = useQuery<GetAppResponse, AxiosError>({
		queryKey: ['app'],
		queryFn: () => getAppsById(id),
		enabled: true,
		onSuccess: (data) => {
			if(data?.app){
				setIsValidID(true); 
				setShowElement(false);
				setIndustryID(data.app.industryID);
				setAppName(data.app.name);
				setSubcategoryID(data.app.subcategoryID);
				setIsAppRefresh(false);
				setAppDone(true);
				if (data?.app?.isJustPublished === undefined){
					setJustPublished(false);					
				} else {
					setJustPublished(true);
				}
			}
		},
		onError: () => {
			setIsValidID(false);
		}
	});
	

	//AppearanceLive
	const { refetch: refetchAppearanceLive } = useQuery<GetAppearanceByAppIdResponse, AxiosError>({
		queryKey: ['appearancesLive'],
		queryFn: () => getAppearanceByAppIDLive(id, { limit: 100 }),
		enabled: false,
		onSuccess: (data) => {
			if (data) {
				data?.rows?.map((a) => {
					if (id) {
						if (a.appID === parseInt(id)) {
							setSelectedFlashScreenIDLive(a.welcomeScreenID);
							setSelectedBackgroundIDLive(a.backgroundID);
							setSelectedNavigationIDLive(a.navigationID);
							setSelectedSideBarIDLive(a.sidebarID);
							setSelectedColorIDLive(a.colorSchemeID);
							setSelectedFontIDLive(a.fontSettingID);
							// setSelectedFaviconLive(a.faviconID);
							// setSelectedOgTagsLive(a.OGTagID);
						}
					}
				});
			}
		}
	});

	//Appearance
	const { data, refetch, isFetched } = useQuery<GetAppearanceByAppIdResponse, AxiosError>({
		queryKey: ['appearances'],
		queryFn: () => getAppearanceByAppID(id, { limit: 100 }),
		enabled: false,
		onSuccess: (data) => {
			if (data) {
				data?.rows?.map((a) => {
					if (id) {
						if (a.appID === parseInt(id)) {
							setAppearanceId(a.appearanceID);
							setSelectedFlashScreenID(a.welcomeScreenID);
							setSelectedLayoutID(a.layoutID);
							setSelectedThemeID(a.themeID);
							setSelectedBackgroundID(a.backgroundID);
							setSelectedNavigationID(a.navigationID);
							setSelectedSideBarID(a.sidebarID);
							setSelectedColorID(a.colorSchemeID);
							setSelectedFontID(a.fontSettingID);
							// setSelectedFaviconID(a.faviconID);
							// setSelectedOgTagsID(a.OGTagID);
							setUserID(a.whoAdded);
						}
					}
				});
				setIsAppearanceRefresh(false);
			}
		}
	});	
	
	// ---------------------------------------------- SAVE AND CANCEL ---------------------------------------------- //
	const {saveApp} = AppMutation();
	const [isAppNameChange, setAppNameChanges] = useAtom(SAppNameChanges);
	const [isAppearanceChange, setAppearanceChanges] = useAtom(SIsAppearanceChanges);
	const [isFeatureChange, setFeaturesChanges]   = useAtom(FIsFeaturesChange);
	const [isCancelClick, setCancelClick] = useState(false);

	const [editorError, setEditorError] 	= useAtom(FEditorError);
	
	const onClickCancel = () => {
		setCancelClick(true);
		if(isAppNameChange || isAppearanceChange || isFeatureChange){
			setOpen(true);
		}else {navigate('/dashboard'); setOpen(false);}
	};
	
	const onClickSave = () => {
		if (editorError) {
			setShowEditorErrorModal(true);
		} else saveApp();
	};

	const onClickStay = () => {
		setOpen(false);
		if(isCancelClick) setCancelClick(false);
	};
	
	const resetChanges = () => {
		setAppChanges(false);
		setFlashScreenChanges(false);
		setLayoutChanges(false);
		setThemeChanges(false);
		setBackgroundChanges(false);
		setNavChanges(false);
		setSidebarChanges(false);
		setColorChanges(false);
		setFontChanges(false);
		setFaviconChanges(false);
		setOgTagsChanges(false);

		setHomeChanges(false);
		setStoreChanges(false);
		setAboutChanges(false);
		setContentChanges(false);
		setPrivacyChanges(false);
		setTermsChanges(false);
		setAccessChanges(false);
		setContactChanges(false);
		setGalleryChanges(false);
		setHelpChanges(false);
		setOfferChanges(false);
		setNotifChanges(false);
		setSocMedChanges(false);
	};

	const onClickLeave = () => {
		if(isCancelClick){
			navigate('/dashboard');
			setCancelClick(false);
			setOpen(false);
			setSubCategModalShow(false);
		}
		if(currentStep === 0){
			setOpen(false);
			setAppearanceChanges(false);
			setIsAppRefresh(true);
			setIsFlashRefresh(true);
			if(data){
				data.rows.map((app) => {
					setSelectedLayoutID(app.layoutID);
					setSelectedThemeID(app.themeID);
				});
			}
			setThemeLayoutRefresh(true);
			setIsBackgroundRefresh(true);
			setIsNavigationRefresh(true);
			setIsSideBarRefresh(true);
			setIsColorRefresh(true);
			setIsFontRefresh(true);
			setIsFaviconRefresh(true);
			setIsOgTagsRefresh(true);
			if(!appData?.app?.subcategoryID){
				setSubCategModalShow(true);
				setSubcategoryID(0);
			}
			if(tempStep === 1) setCurrentStep(1);
			if(tempStep === 2) setCurrentStep(2);
			if(tempStep === 3) setCurrentStep(3);
		}
		if(currentStep === 1){
			setOpen(false);
			setFeaturesChanges(false);
			setHomeRefresh(true);
			setStoreRefresh(true);
			setAboutRefresh(true);
			setContentRefresh(true);
			setPrivacyRefresh(true);
			setTermsRefresh(true);
			setAccessRefresh(true);
			setContactRefresh(true);
			setGalleryRefresh(true);
			setHelpRefresh(true);
			setOfferRefresh(true);
			setNotifRefresh(true);
			setSocMedRefresh(true);
			if(tempStep === 0) setCurrentStep(0);
			if(tempStep === 2) setCurrentStep(2);
			if(tempStep === 3) setCurrentStep(3);
		}
		resetChanges();
	};

	

	// ---------------------------------------------- USEEFFECTS ---------------------------------------------- //

	useEffect(() => {
		window.scrollTo(0,0);
	},[]);

	useEffect(() => {
		setContentError('');
		setEditorError(false);
		setCurrentStep(0);
	}, []);

	useEffect(() => {
		if (appData?.app && currentStep === 0) {				
			appData.app.status === 'TRI' ? setCurrentStep(2) : setCurrentStep(0);
		} 
		else if (appData?.app.status !== 'TRI'){
			setCurrentStep(0);
		}
	}, [appData?.app.appID]);

	useEffect(() => {
		if(appData?.app){
			if(appName.length > 0 && appData?.app?.name !== appName && appName.trim() || subcategoryID !== appData?.app?.subcategoryID){
				setAppNameChanges(true);
			}else if(subcategoryID === appData?.app?.subcategoryID || appName.length <= 0 && appData?.app?.name === appName && !appName.trim()){
				setAppNameChanges(false);
			}
		}
	},[appData, appName, subcategoryID]);

	useEffect(() => {
		if(isAppearanceRefresh){
			refetch();
			refetchAppearanceLive();
		}
	},[isAppearanceRefresh]);

	useEffect(() => {
		if(isAppRefresh){
			refetchApp();
		}
	},[isAppRefresh]);

	useLayoutEffect(() => {
		document.body.style.backgroundColor = '#F6F6F6';
	});

	UseAppearanceQueries();
	HomeUseQuery();
	ContactUseQuery();
	PrivacyUseQuery();
	AccessibilityUseQuery();
	TermsUseQuery();
	AboutUseQuery();
	ContentQuery();
	PaymentUseQueries();

	// TODO: optimize
	NotificationQuery();
	OfferUseQuery();
	SocialMediaUseQuery();
	GalleryUseQuery();
	HelpUseQuery();
	StoreUseQuery();
	
	const [showElement,setShowElement] = useState(true);
	const [isValidID, setIsValidID] = useState(true);
	
	const [ subCategModalShow, setSubCategModalShow ] = useAtom(SSubCategErrorModalShow);
	const [ subCategErrorMessage, setSubCategErrorMessage ] = useAtom(SSubCategErrorMessage);
	
	const handleClick = () => {
		setSubCategErrorMessage(true);
		setSubCategModalShow(false);
	};

	const handleAlertModalClose = () => {
		setContentError('');
		setContentErrorDescription('');
		setContentErrorModalShow(false);
	};

	const handleEditorModalClose = () => {
		setShowEditorErrorModal(false);
	};

	if(!isValidID){
		return <Error403/>;
	}

	return (
		
		!showElement && data && isFetched && isAppDone && appData && fetchedApp && isFlashDone && isThemeLayoutDone && isBackgroundDone && isNavDone && isSidebarDone
			&& isColorDone && isFontDone ?
			<>
				<div className='bg-[#F9F9F9] h-max '>
					{/* bg-[#F9F9F9] h-screen px-8 py-6 lg:px-8 md:px-0 sm:px-0 max-sm:px-0 */}
					{/* This is temporarily set until it has no scrollbar */}
					<div className='bg-[#F97316] h-2 drop-shadow-[0_3px_6px_rgba(0,0,0,0.29)] w-[100%]'></div>
					{/* onClose={setApproveModalShow}  */}
					<SubCategoryModal isSubCategModalShow={subCategModalShow} setSubCategModalShow={setSubCategModalShow} onClickOK={handleClick} />
					<div className='flex py-6 px-8 w-full 2xl:flex-row 2xl:justify-normal 2xl:items-start 2xl:px-8 xl:flex-row xl:justify-normal xl:items-start lg:justify-center lg:items-center lg:flex-col-reverse md:justify-center md:items-center md:flex-col-reverse md:px-4 sm:justify-center sm:items-center sm:flex-col-reverse max-sm:justify-center max-sm:items-center max-sm:flex-col-reverse'>
						{/* lg:flex-row lg:w-full md:w-[110%] md:justify-center md:items-center md:flex-col-reverse sm:justify-center sm:items-center sm:flex-col-reverse max-sm:justify-center max-sm:items-center max-sm:flex-col-reverse */}
						<div className='flex flex-col w-10/12 2xl:w-10/12 2xl:-ml-0 xl:w-10/12 xl:-ml-0 lg:w-[92%] lg:-ml-[130px] md:w-full md:-ml-0 sm:w-full sm:-ml-0 max-sm:w-[80%] max-sm:-ml-0 max-sm:text-lg'>
							{(currentStep === 0 || currentStep === 1 ) && <div className='items-center justify-center 2xl:block xl:block lg:block md:hidden sm:hidden max-sm:hidden'>
								<label className='max-sm:text-lg'>
									{isEditable ? (
										<input
											type='text'
											value={appName}
											onChange={handleTitleChange}
											onBlur={handleTitleBlur}
											ref={inputRef}
											maxLength={20}
											pattern='[a-zA-Z\s&\d]'
											className='font-semibold text-[25px] text-[#464255] outline-primary pl-2 text-center' />
									) : (
										<div className='flex flex-row pl-10'>
											<span className='font-semibold text-[25px] text-[#464255]'>
												{appName}
											</span>
											<PencilIcon
												className='w-6 text-[#B35916] ml-3 cursor-pointer'
												onClick={handleEditClick} />
										</div>
									)}
								</label>
								<div className='text-[14px] text-gray-500 pl-10'>
								Let&apos;s Customize Your App!
								</div>
							</div>}
							<div className='flex-row w-[100%] pr-10 xl:w-[97%] lg:w-[112%] lg:pr-1 md:w-full sm:w-[100%] sm:pr-0 max-sm:w-[130%] max-sm:pr-0 max-sm:items-center max-sm:-translate-x-[10vw] max-sm:justify-center'>
								{/*  max-sm:items-center max-sm:left-1/2 max-sm:-translate-x-1/2  */}
								<div className='w-full'>
									<CustomizeStepper />
								</div>
								{currentStep === 0 ? (
									<>
										<div className=''>
											<div className='sm:overflow-x-auto max-sm:overflow-x-auto'>
												<AppearanceTab />
											</div>
											{renderAppearanceBody()}
										</div>
									</>
								) : currentStep === 1 ? (
									<>

										<FeaturesTab />
										{renderFeaturesBody()}
									</>
								) : currentStep === 2 ? (
									<>
										<PaymentTab />
									</>
								) : currentStep === 3 ? (
									<>
										<PublishTab />
									</>
								) : (
									<></>
								)}
							</div>
						</div>
						<div>
							{currentStep > 1 ? (
								<div className={classNames(currentStep === 2 ? 'w-[21.875rem] lg:w-[21.875rem]' : '')}>
								</div>
							) : (
								<div>
									<div className='2xl:hidden xl:hidden lg:hidden md:block sm:block max-sm:block'>
										<label className='max-sm:text-lg'>
											{isEditable ? (
												<input//----------------
													type='text'
													value={appName}
													onChange={handleTitleChange}
													onBlur={handleTitleBlur}
													ref={inputRef}
													maxLength={20}
													pattern='[a-zA-Z\s&\d]'
													className='font-semibold text-[25px] text-[#464255] outline-primary pl-2 text-center w-full' />
											) : ( 
												<div className='flex flex-row items-center justify-center'>
													<span className='font-semibold text-[25px] text-[#464255] max-sm:text-lg'>
														{appName}
													</span>
													<PencilIcon
														className='w-6 text-[#B35916] ml-3 cursor-pointer'
														onClick={handleEditClick} />
												</div>
											)}
										</label>
										<div className='flex items-center justify-center text-[14px] text-gray-500'>
									Let&apos;s Customize Your App!
										</div>
									</div>
									<AppPreview
										onClickCancel={onClickCancel}
										onClickSave={onClickSave}
										isSaving={isSaving} 
									/>
								</div>
							)}
							{currentStep === 3 ? (
								<div>
									<AppPreviewPublish />
								</div>
							) : (
								<></>
							)}
						</div>		
					</div>
				</div>
				<LeaveSectionModal 
					isLeaveSectionModalShow={open}
					setLeaveSectioShow={setOpen}
					onClickLeave={() => onClickLeave()}
					onClickStay={() => onClickStay()} 
				/>
				<AlertModal
					title={contentError}
					description={contentErrorDescription}
					isVisible={contentErrorModalShow}
					setIsVisible={setContentErrorModalShow}
					onClose={handleAlertModalClose}
				/>
				<AlertModal
					title='WYSIWYG Content Limit Exceeded'
					description='You&apos;ve reached the maximum file size of 10MB. Please reduce the file size to continue.'
					isVisible={showEditorErrorModal}
					setIsVisible={setShowEditorErrorModal}
					onClose={handleEditorModalClose}
				/>
			</>
			:
			<LoadingScreen/>
	);
};

export default CustomizeApp;