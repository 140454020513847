import { Album } from 'components/Gallery/Album';
import { FGalleryImageAtom } from 'store/CustomizeApp/Features/galleryAtom';
import React from 'react';
import { useAtomValue } from 'jotai';
const Gallery: React.FC = () => {
	const accordionImages 							= useAtomValue(FGalleryImageAtom);
	return(
		<div className='absolute w-full h-[100%] xl:h-[98%] 2xl:h-[99%] overflow-y-auto overflow-x-hidden no-scrollbar'>
			<div className='py-[60px] px-3 grid grid-cols-2 gap-2 '>
				{
					accordionImages && accordionImages.map(({title, images}, id) => (
						<div key={id}>
							{ 
								images && images?.length === 0 || images?.length === undefined ? 
									<div>
										<div className='border-1 bg-gray-200 w-[100%] h-[7rem] mr-[8px] flex items-center justify-center text-xs'>
											Add Image
										</div>

										<h2 className='text-xs text-[#707070] font-bold mt-[2px]'>{title}</h2>
										<p className='text-[8px] text-[#707070] mt-[2px]'>{images && images.length ? images.length : 0}</p>
									</div>
									:
									<div key={id}>
										{images && images.slice(0,1).map((image) => (
											<Album key={id} coverImage={image.url} />
										))}
										<h2 className='text-xs text-[#707070] font-bold mt-[2px]'>{title}</h2>
										<p className='text-[8px] text-[#707070] mt-[2px]'>{images && images.length}</p>
									</div>
							}
						</div>
					))}
			</div>
		</div>
	);
};

export default Gallery;