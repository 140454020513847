import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { stepperAtom } from 'store/CustomizeApp/Stepper';

import React, { useState } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { LeaveSectionModal } from 'components/Common/Modals/LeaveSectionModal';
import { SAppNameChanges, SIsAppearanceChanges, SIsBackgroundChanges, SIsColorChanges, SIsFaviconChanges, SIsFontChanges, SIsLayoutChanges, SIsNavigationChanges, SIsOgTagsChanges, SIsSideBarChanges, SIsThemeChanges, SIsUnsavedModalOpen } from 'store/CustomizeApp/Appearance';
import { FIsAboutChanges, FIsAccessChanges, FIsContactChanges, FIsContentChanges, FIsFeaturesChange, FIsGalleryChanges, FIsHelpChanges, FIsHomeChanges, FIsNotifChanges, FIsOffersChanges, FIsPrivacyChanges, FIsSocialMediaChanges, FIsStoreChanges, FIsTermsChanges } from 'store/CustomizeApp/Features';

interface PartialFooterProps {
	isLogin: boolean;
}

const PartialFooter: React.FC<PartialFooterProps> = ({ isLogin }) => {
	const stepper = useAtomValue(stepperAtom);
	const ApplySMmbClass = /^\/edit(\/.*)?$/.test(location.pathname) && (stepper === 0 || stepper === 1);

	const [open, setShowUnsavedModal] = useAtom(SIsUnsavedModalOpen);
	const [isThereAppearanceChanges, setAppearanceChanges] = useAtom(SIsAppearanceChanges);
	const [isThereFeaturesChanges, setFeatureChanges] = useAtom(FIsFeaturesChange);
	const [nav, setNav] = useState('');
	const navigate = useNavigate();
	const {id} = useParams();

	const [isAppNameChanges, setAppChanges] = useAtom(SAppNameChanges);
	const setFlashScreenChanges = useSetAtom(SIsAppearanceChanges);
	const setLayoutChanges = useSetAtom(SIsLayoutChanges);
	const setThemeChanges = useSetAtom(SIsThemeChanges);
	const setBackgroundChanges = useSetAtom(SIsBackgroundChanges);
	const setNavChanges = useSetAtom(SIsNavigationChanges);
	const setSidebarChanges = useSetAtom(SIsSideBarChanges);
	const setColorChanges = useSetAtom(SIsColorChanges);
	const setFontChanges = useSetAtom(SIsFontChanges);
	const setFaviconChanges = useSetAtom(SIsFaviconChanges);
	const setOgTagsChanges = useSetAtom(SIsOgTagsChanges);

	const setHomeChanges = useSetAtom(FIsHomeChanges);
	const setStoreChanges = useSetAtom(FIsStoreChanges);
	const setAboutChanges = useSetAtom(FIsAboutChanges);
	const setContentChanges = useSetAtom(FIsContentChanges);
	const setPrivacyChanges = useSetAtom(FIsPrivacyChanges);
	const setTermsChanges = useSetAtom(FIsTermsChanges);
	const setAccessChanges = useSetAtom(FIsAccessChanges);
	const setContactChanges = useSetAtom(FIsContactChanges);
	const setGalleryChanges = useSetAtom(FIsGalleryChanges);
	const setHelpChanges = useSetAtom(FIsHelpChanges);
	const setOfferChanges = useSetAtom(FIsOffersChanges);
	const setNotifChanges = useSetAtom(FIsNotifChanges);
	const setSocMedChanges = useSetAtom(FIsSocialMediaChanges);

	const resetChanges = () => {
		setAppChanges(false);
		setFlashScreenChanges(false);
		setLayoutChanges(false);
		setThemeChanges(false);
		setBackgroundChanges(false);
		setNavChanges(false);
		setSidebarChanges(false);
		setColorChanges(false);
		setFontChanges(false);
		setFaviconChanges(false);
		setOgTagsChanges(false);

		setHomeChanges(false);
		setStoreChanges(false);
		setAboutChanges(false);
		setContentChanges(false);
		setPrivacyChanges(false);
		setTermsChanges(false);
		setAccessChanges(false);
		setContactChanges(false);
		setGalleryChanges(false);
		setHelpChanges(false);
		setOfferChanges(false);
		setNotifChanges(false);
		setSocMedChanges(false);
	};

	const onClickStay = () => {
		setShowUnsavedModal(false);
	};

	const onClickLeave = () =>{
		setShowUnsavedModal(false);
		setAppearanceChanges(false);
		setFeatureChanges(false);
		resetChanges();
		if(nav === 'Contact Us') navigate('/signed/contact-us');
		if(nav === 'Terms of Use') navigate('/signed/terms-of-use');
		if(nav === 'Privacy Policy') navigate('/signed/privacy-policy');
		if(nav === 'Accessibility') navigate('/signed/accessibility');
		setNav('');
	};

	const handleSelect = (value: string) => {
		if(isThereAppearanceChanges || isThereFeaturesChanges || isAppNameChanges) {
			setShowUnsavedModal(true);
			setNav(value);
		}
	};

	const handleRedirection = (link: string) => {
		return (isThereAppearanceChanges || isThereFeaturesChanges || isAppNameChanges) ? `/edit/${id}` : link;
	};
	
	
	return (
		<div className={`lg:z-50 flex flex-col mt-auto w-full md:-z-10 ${ApplySMmbClass ? '-z-10 2xl:mb-0 xl:mb-0 lg:mb-0 md:mb-16 max-sm:mb-16 sm:mb-16' : '2xl:mb-0 xl:mb-0 lg:mb-0 md:mb-0 sm:mb-0 max-sm:mb-0'}`}>
			<footer className='bg-[#2D2D2D] text-white py-2 flex 2xl:flex-row 2xl:justify-between 2xl:px-10 lg:flex-row lg:justify-between lg:px-10 max-sm:flex-col-reverse sm:flex-col-reverse sm:items-center md:flex-col-reverse md:items-center'>
				<div className='flex items-center max-sm:mx-auto max-sm:mt-2'>
					<span className='font-thin'>Copyright &copy; 2023 SnapToApp</span>
				</div>
				<div className='flex max-sm:flex-col items-center max-sm:space-y-2 sm:flex-row md:flex-row'>
					<div className='flex max-sm:px-10 max-sm:justify-between lg:space-x-4 md:space-x-2 max-sm:w-full'>
						<NavLink
							id='nav-link'
							to={isLogin ? handleRedirection('/signed/contact-us') : '/contact-us'}
							className='hover:border-b-2 hover:border-[#FF7F20] font-light max-sm:w-1/2 max-sm:border-r-2 max-sm:text-end max-sm:pr-2'
							onClick={() => handleSelect('Contact Us')}
						>
							Contact Us
						</NavLink>
						<span className='max-sm:hidden'>|</span>
						<NavLink
							id='nav-link'
							to={isLogin ? handleRedirection('/signed/terms-of-use') : '/terms-of-use'}
							className='hover:border-b-2 hover:border-[#FF7F20] font-light max-sm:w-1/2 max-sm:text-start max-sm:pl-2'
							onClick={()=>handleSelect('Terms of Use')}
						>
							Terms of Use
						</NavLink>
						<span className='max-sm:hidden'>|</span>
					</div>
					<div className='flex max-sm:px-10 lg:space-x-4 md:space-x-2 max-sm:w-full pl-4'>
						<NavLink
							id='nav-link'
							to={isLogin ? handleRedirection('/signed/privacy-policy') : '/privacy-policy'}
							className='hover:border-b-2 hover:border-[#FF7F20] font-light max-sm:w-1/2 max-sm:border-r-2 max-sm:text-end max-sm:pr-2'
							onClick={()=>handleSelect('Privacy Policy')}
						>
							Privacy Policy
						</NavLink>
						<span className='max-sm:hidden'>|</span>
						<NavLink
							id='nav-link'
							to={isLogin ? handleRedirection('/signed/accessibility') : '/accessibility'}
							className='hover:border-b-2 hover:border-[#FF7F20] font-light max-sm:w-1/2 max-sm:text-start max-sm:pl-2'
							onClick={()=>handleSelect('Accessibility')}
						>
							Accessibility
						</NavLink>
					</div>
				</div>
			</footer>
			<LeaveSectionModal 
				isLeaveSectionModalShow={open}
				setLeaveSectioShow={setShowUnsavedModal}
				onClickLeave={() => onClickLeave()}
				onClickStay={() => onClickStay()}
			/>
		</div>
	);
};

export default PartialFooter;
