import { GetAllAppResponse, GetAllAppResponseNA, GetAppResponse, UpdateAppResponse, UpdateAppSchema } from './schema';

import { axiosInstance } from 'services/axios';

export const getAppsById = async (id?: string) => {
	const response = await axiosInstance.get(`/organizations/apps/${id}`);
	return response.data as GetAppResponse;
};

export const getAllApps = async () => {
	const response = await axiosInstance.get('/organizations/apps', {params: {limit: 1000}});
	return response.data as GetAllAppResponse;
};

export const getAllAppsNA = async () => {
	const response = await axiosInstance.get('/organizations/appsNA');
	return response.data as GetAllAppResponseNA;
};

export const editAppByID = async (appID?: string, param?: UpdateAppSchema) => {
	const response = await axiosInstance.put(`/organizations/apps/${appID}`, param);
	return response.data as UpdateAppResponse;
};

export const publishApp = async (id?: string) => {
	const response = await axiosInstance.post(`/organizations/apps/${id}/publish`);
	return response.data;
};

export const rePublishApp = async (id?: string) => {
	const response = await axiosInstance.put(`/organizations/apps/${id}/live`);
	return response.data;
};