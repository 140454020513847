import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router';
import { verifyEmailToken } from 'services/requests/auth';
import { VerifyEmailResponseType, VerifyEmailType } from 'services/requests/auth/schema';
import { showFailedAlert } from 'utility/alerts';

const VerifyEmail: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const tokenID = searchParams.get('id');

	const { 
		mutate: verifyEmailMu 
	} = useMutation<VerifyEmailResponseType, AxiosError,VerifyEmailType>(
		(data) => verifyEmailToken(data),
		{
			onSuccess:(data) => {
				if(data.success && data.email){
					navigate('/login');
				}
			},
			onError:() => {
				showFailedAlert('Something went wrong!');
			}
		}
	);

	useEffect(()=>{

		if(tokenID && tokenID?.length > 0){

			const params = {
				tokenID: tokenID
			};

			verifyEmailMu(params);

		}
	},[]);

	return null;
};

export default VerifyEmail;