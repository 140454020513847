import { CameraIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { SEditProfileFirstName, SEditProfileImage, SEditProfileImagePreview, SEditProfileLastName } from 'store/EditProfile/editProfileAtom';
import { UploadEditProfileSchema, UploadUserByAppIDParamasSchema, UploadUserByAppIDResponse, UploadUserByAppIDResponse2 } from 'services/requests/user/schema';
import { showFailedAlert, showSuccessAlert } from 'utility/alerts';
import { useMutation, useQueryClient } from 'react-query';
import { IMAGES } from 'utility/constant';

import { Axios, AxiosError } from 'axios';
import { Button } from 'components/Common/Buttons';
import FormInput from 'components/Common/Forms/Inputs';
import { capitalizeFirstLetter } from 'utility';
import { editUserProfile } from 'services/requests/user';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { zodResolver } from '@hookform/resolvers/zod';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Modal } from 'components/Common/Modals';
import { authAtom } from 'store/authAtom';
import { hasUserCredentials } from 'store/authAtom';
import { DisconnectSsoResponse, DisconnectSsoRequest } from 'services/requests/auth/schema';
import { disconnectSso } from 'services/requests/auth';


const ProfilePanel: React.FC = () => {

	const userDataHolder = localStorage.getItem('userData');
	const userParse = userDataHolder ? JSON.parse(userDataHolder) : null;
	const queryClient = useQueryClient();
	const [editFirstName, setEditFirstName] = useAtom(SEditProfileFirstName);
	const [editLastName, setEditLastName] = useAtom(SEditProfileLastName);
	const [editImage, setEditImage] = useAtom(SEditProfileImage);
	const [editImagePreview, setEditImagePreview] = useAtom(SEditProfileImagePreview);

	const hasPassword = useAtomValue(hasUserCredentials);

	const [xClick, setXClick] = useState(false);
	const [inputValue, setInputValue] = useState<string>(userParse?.user.birthDate);
	const [displayWarning, setDisplayWarning] = useState(false);
	const [formShow, setFormShow] = useState(false); //for onClose() since it requires me to put a value. 
	const [authData, setAuthData] = useAtom(authAtom);
	const [hasIdentityProvider, setHasIdentityProvider] = useState<boolean>(false);
	const [showSsoDisconnectModal, setShowSsoDisconnectModal]  =useState<boolean>(false);
	const setHasUserCredential = useSetAtom(hasUserCredentials);
	
	const userEmail = localStorage.getItem('email')?.replaceAll('"','');

	useEffect(() => {
		console.log(authData);
		console.log('checking identity provider');
		if(!authData?.identityProvider)
		{
			console.log('no identity provider');
			setHasIdentityProvider(false);
		}

		authData?.identityProvider?.map((identProv) => {
			console.log('has identity provider: ', identProv.toLocaleLowerCase());
			if(identProv.toLocaleLowerCase() === 'google'){
				setHasIdentityProvider(true);
				return;
			}
		});
	},[]);

	useEffect(() => {
		setEditImage(userParse?.user.imageURL);
		setEditImagePreview(userParse?.user.imageURL);

		if (userParse?.user.birthDate) {
			let dateObject;
			// Convert birthDate to a Date object
			const date = new Date(userParse?.user.birthDate);
			// Check if the date is valid
			if (!isNaN(date.getTime())) {
				dateObject = date;
			} else {
				// If date is invalid, set it to current date
				dateObject = new Date();
			}
			// Format the date object to a string in the format expected by the input element
			const formattedDate = dateObject.toISOString().split('T')[0].replace(/-/g, '/');
			const inputValueReplacer = formattedDate.replace(/\//g, '-');
			setInputValue(inputValueReplacer);
		}
	}, []);


	const {
		mutate: updateProfileMU,
	} = useMutation<UploadUserByAppIDResponse2, AxiosError, UploadUserByAppIDParamasSchema>(
		data => editUserProfile(userParse.user.userID, data, editImage, userParse.user.imageUploadID), {
			onSuccess: (data) => {
				queryClient.invalidateQueries('user');
				setEditFirstName(data.user.firstName);
				setEditLastName(data.user.lastName);
				showSuccessAlert('Updated Successfully!');
			},
			onError: (error) => {
				showFailedAlert('Update failed');
			},
			retry: 1
		}
	);

	const { control, handleSubmit, formState: { errors, isDirty } } = useForm<UploadUserByAppIDResponse>({
		mode: 'onBlur',
		resolver: zodResolver(UploadEditProfileSchema),
		defaultValues: {
			firstName: userParse?.user.firstName,
			lastName: userParse?.user.lastName,
			phoneNumber: userParse?.user.phoneNumber,
			birthDate: userParse?.user.birthDate === undefined ? userParse?.user.birthDate : inputValue,
			gender: userParse?.user.gender
		}
	});

	const { mutate: disconnectSsoMu, isLoading: loadingDisconnect } = 
		useMutation<DisconnectSsoResponse,AxiosError, string>(
			(email) => disconnectSso(email), {
				onSuccess: (res) => {
					setHasIdentityProvider(false);
					showSuccessAlert('SSO is successfully disconnected.');
					delete authData?.identityProvider;
					const newAuthData = authData;
					setAuthData(newAuthData);
					queryClient.invalidateQueries(['userCredentials']);
					
					console.log('SSO DISCONNECT response: ', res);
				},
				onError: (err) => {
					showSuccessAlert('Failed to disconnect SSO.');
					console.log('SSO DISCONNECT error: ', err);
				}
			}
		);

	const onSubmit = (data: UploadUserByAppIDResponse) => {
		updateProfileMU(data);
	};

	const handleOnClickDisconnect = () => {
		console.log('hasPassword:', hasPassword);
		if (!hasPassword) {
			setDisplayWarning(true);
			return;
		} 

		setShowSsoDisconnectModal(true);
		console.log('handleOnClickDisconnect clicked!');
	};


	const handleConfirmDisconnect = () => {
		console.log('Disconnecting. . . .');
		disconnectSsoMu(userEmail ? userEmail : '');
		setShowSsoDisconnectModal(false);
		console.log('Disconnecting done.');
	};

	const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;

	const handleSelectImage = (event: any) => {
		const file = event.target.files[0];
		const reader = new FileReader();

		reader.onload = (event: any) => {
			const image: any = new Image();
			image.src = event.target.result;

			if (file.type.match(imageTypeRegex)) {
				image.onload = () => {
					if (file.size > 1 * 1024 * 1024) {
						showFailedAlert('Please upload an image file that is less than 1MB in size.');
					} else {
						const url = URL.createObjectURL(file);
						setEditImage(file);
						setEditImagePreview(url);
						setXClick(true);
					}
				};
			} else {
				showFailedAlert('File must be JPEG or PNG.');
			}

		};

		reader.readAsDataURL(file);
	};
	//here The
	const DisconnectModal = () =>{
		return( 
			<div className='text-[#FAFAFA]' >
				<Modal  open={true} onClose={setFormShow} className='sm:max-w-xl'>
					<div>
						<div className='h-[267px] w-[555px] relative'>
							<div className='leading-[28px] font-semibold mt-[39.5px] text-gray-900'>
								<h3 className='text-[20px ] text-[#707070] ml-[37.5px] mr-[105.5px] mb-[15px]'>Are you sure you want to disconnect the Google account &ldquo;{userParse?.user.firstName} {userParse?.user.lastName}&rdquo;?</h3>
								<p className='text-[15px] mx-[37.5px] text-[#707070] leading-[32px]'>You&apos;ll need to use your email address {userEmail} and password next time you log in to SnapToApp.</p>
							</div>
							<div className=" absolute ml-[239.5px] inline-block font-semibold mb-[16.5px] mt-[34px]">
								<button
									type="button"
									className="inline-flex mr-2 w-[117px] h-[43px] justify-center rounded-md border-[1px] border-solid border-[#464255] px-3 py-2 text-[16px] text-[#464255]"
									onClick={() => setShowSsoDisconnectModal(false)}
								>
									Cancel
								</button>
								<button
									type="button"
									className=" inline-flex justify-center text-center rounded-md bg-[#E0781D] px-3 py-2 text-[16px] text-[#FFFFFF] w-[151px] h-[43px]"
									onClick={handleConfirmDisconnect}
								>
									Disconnect
								</button>	
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	};

	
	const Warning = () => {
		return (
			<div className=' bg-[#FBC3C6] border-blue-500 text-[#171414] rounded-[4px] py-3 sm:h-[66px] md:h-[70px] md:py-10 my-5 lg:py-6 flex items-center flex-row gap-x-2 px-3 pr-2' role="alert">
				<ExclamationTriangleIcon className='h-fit w-[20.6px] font-semibold inline-block'/>
				<p className='text-[12px] h-fit w-full'> Before we can disconnect your account, please make sure you&apos;ve set an email address and password for login.</p>				
			</div>);
	};
	

	return (
		<>
			<div className='w-full p-10 2xl:p-10 xl:p-10 lg:p-5 md:p-5 sm:p-10 max-sm:p-10'>
				<div className='text-[#707070] text-[18px] font-bold'>Profile</div>
				<hr className='mt-4' />
				<div className='2xl:flex-row 2xl:mt-0 xl:flex-row xl:mt-0 lg:flex-col-reverse lg:mt-0 md:flex-col-reverse md:mt-4 sm:flex-col-reverse sm:mt-4 max-sm:flex-col-reverse max-sm:mt-4  flex flex-row justify-center items-center w-full'>
					<form className='flex mt-4 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-row max-sm:flex-col w-full justify-center items-center 2xl:w-[65%] xl:w-[65%] lg:w-full md:w-full' onSubmit={handleSubmit(onSubmit)}>
						<div className='w-full 2xl:p-7 xl:p-7 lg:p-7 md:p-2 max-sm:p-2 flex-1 flex-col justify-start items-start'>
							<div>
								<Controller
									control={control}
									name='firstName'
									render={({ field: { name, onChange }, fieldState: { error } }) => (
										<FormInput value={userParse?.user.firstName} onChange={onChange} name={name} errorEdit={error?.message} label2='First Name *' type='text' />
									)}
								/>
							</div>


							<div className='hidden mt-6 max-sm:block'>
								<Controller
									control={control}
									name='lastName'
									render={({ field: { name, onChange }, fieldState: { error } }) => (
										<FormInput value={userParse?.user.lastName} onChange={onChange} name={name} errorEdit={error?.message} label2='Last Name *' type='text' />
									)}
								/>
							</div>

							<div className='block pt-6 max-sm:hidden'>
								<FormInput className='cursor-not-allowed focus:cursor-auto disabled:bg-slate-50 disabled:text-slate-600 disabled:border-slate-100 disabled:shadow-none' disabled={true} value={userParse?.user.email} label2='Email Address' type='email' />
							</div>


							<div className='mt-6'>
								<Controller
									control={control}
									name='birthDate'
									render={({ field: { name, onChange }, fieldState: { error } }) => (
										<FormInput value={userParse?.user.birthDate === undefined ? userParse?.user.birthDate : inputValue} onChange={onChange} name={name} error={error?.message} label2='Date of Birth' type='date' className='w-full'/>
									)}
								/>
							</div>
						</div>
						<div className='flex-1 flex-col justify-start items-start p-7 2xl:p-7 xl:p-7 lg:p-7 md:w-full md:p-0 sm:w-full sm:p-7 max-sm:w-full max-sm:p-2 '>

							<div className='block max-sm:hidden'>
								<Controller
									control={control}
									name='lastName'
									render={({ field: { name, onChange }, fieldState: { error } }) => (
										<FormInput value={userParse?.user.lastName} onChange={onChange} name={name} errorEdit={error?.message} label2='Last Name *' type='text' />
									)}
								/>
							</div>

							<div className='hidden max-sm:block'>
								<FormInput className='bg-[#D9D7D5] border border-1 border-[#9F9F9F]' disabled={true} value={userParse?.user.email} label2='Email Address' type='email'/>
							</div>


							<div className='mt-6'>
								<Controller
									control={control}
									name='phoneNumber'
									render={({ field: { name, onChange }, fieldState: { error } }) => (
										<FormInput className='bg-[#D9D7D5] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none' maxLength={16} placeholder='+1 513-452-617' value={userParse.user.phoneNumber ? userParse.user.phoneNumber : 'No number attached'} onChange={onChange} name={name} errorEdit={error?.message} label2='Phone Number' type='number' />
									)}
								/>
							</div>
							<div className='mt-6'>
								<label className='text-[#363636] text-xs font-semibold'>Gender</label>
								<Controller
									control={control}
									name="gender"
									defaultValue={userParse?.user.gender ?? ''}
									rules={{ required: 'Please select a gender' }}
									render={({ field }) => (
										<div className='pt-0.5 w-full h-[40px] border rounded-md border-gray-300 items-center focus-within:border-input-active'>
											<select
												{...field}
												className={`text-[#183B56] pl-2 h-[35px] w-[98%] rounded-md text-sm focus:outline-none border-transparent
              							 					focus:ring-0 focus:border-input-active`}
											>
												<option hidden value=''>Select gender</option>
												<option value='male'>Male</option>
												<option value='female'>Female</option>
												<option value='prefernovalue'>Prefer not to say</option>
											</select>
										</div>
									)}
								/>
								{errors.gender && <span className="text-red-700 text-xs pl-1 py-1">{errors.gender.message?.toString()}</span>}
							</div>
						</div>
					</form>
					<div className='flex-1 flex flex-col justify-center items-center 2xl:mt-0 xl:mt-0 lg:mt-5 md:mt-0 sm:mt-0 max-sm:mt-0'>
						<button className='relative'>
							{
								editImagePreview === '' || editImagePreview === undefined ?
									editFirstName !== undefined ?
										<p className='w-[150px] h-[150px] flex justify-center items-center rounded-full text-center bg-primary text-white font-bold text-[86px]'>{capitalizeFirstLetter(editFirstName)}</p>
										:
										null
									:
									<img alt='user icon' className='w-[150px] h-[150px] bg-slate-300 rounded-full border' src={editImagePreview} />
							}

							<div className='absolute flex justify-center items-center rounded-full border border-[#FF7F20] w-[40px] h-[40px] bg-white right-0 bottom-2'>
								<CameraIcon fill='#FF7F20' className='w-[25px] h-[25px]' />
							</div>
							<input
								type="file"
								accept="image/*"
								className="absolute inset-0 rounded-lg w-full h-full opacity-0 cursor-pointer"
								onChange={(e) => handleSelectImage(e)} onClick={(event) => {
									event.currentTarget.value = '';
								}}
							/>
							<XMarkIcon className={`${xClick === false ? 'hidden' : 'block'} absolute w-5 h-5 cursor-pointer z-30 right-0 top-0`} onClick={() => { setEditImagePreview(userParse.user.imageURL); setEditImage(userParse.user.imageURL); setXClick(false); }} />
						</button>
						<div className='mt-2 text-[#707070] text-[14px]'>
							<p>File size: maximum 1 MB</p>
							<p>File extension: JPEG, PNG</p>
						</div>
					</div>
				</div>
				<div className='flex 2xl:justify-start xl:justify-start lg:justify-center md:justify-center sm:justify-center max-sm:justify-center items-center ml-8 mt-2'>
					{
						xClick ?
							<Button onClick={handleSubmit(onSubmit)} className='min-md:mt-4 max-md:mt-4 min-md:w-3/4 max-md:w-3/4 min-sm:mt-4 max-sm:mt-4 min-sm:w-3/4 max-sm:w-3/4 w-[14%] py-1.5 font-semibold' type='submit' variant='primary'>Save</Button>
							:
							<Button onClick={handleSubmit(onSubmit)} className='w-[180px] 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-10 sm:mt-4 max-sm:mt-4 py-1.5 font-semibold' type='submit' variant='primary'>Save</Button>
					}
				</div>	
				<div hidden={!hasIdentityProvider} className='w-full xl:w-[65%]'>
					<div className='mt-5 py-4 border-t border-gray-200'>
						<p>
							<span className='mt-12 py-6 text-[14px] w-[188px] h-[20px] gap-x-[346.5px] gap-y-[459px] text-[#363636] font-semibold font-poppins inline-bock'>Connected Social Account</span>
						</p>
						<div className='h-fit'>
							{displayWarning && <Warning/>}
							{showSsoDisconnectModal && <DisconnectModal/>}
						</div>
						<div className='w-full mt-4 h-[68px] border-[#9F9F9F] border-[1px] relative mb-[27.46px] '>
							<img className=' h-[24px] absolute m-5' src={IMAGES.GoogleButtonIcon} alt="" />
							<span className=' h-[17px] text-[#464255] absolute ml-[64px] mt-[18px] text-[12px]'>Google</span> <br />
							<span className=' h-[17px] text-[#464255] absolute ml-[64px] mt-[8px] text-[12px]'>{userParse?.user.firstName} {userParse?.user.lastName}</span>
							<button className='bg-[#ECEAEA] w-[141px] h-[43px] rounded absolute right-[25.5px] top-[13px] bottom-[12px]' 
								onClick={handleOnClickDisconnect}
							>
								Disconnect
							</button>
						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default ProfilePanel;

