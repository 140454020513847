import React, {useState} from 'react';

const AccordionFAQs = (props: { datas: any; }) => {

	const [item, setItem] = useState(props.datas);
	const handletoggleActive = () => {
		const newActive = item.active === 1 ? 0 : 1;
		setItem({...item, active: newActive});
	};

	return(
		<div 
			className={`bg-white p-2 border mb-5 border-white rounded-md w-[900px] max-sm:w-[320px] duration-500 group 
			${item.active === 1 ? 'is-active bg-white': ''}`}>
			<div className='flex items-center gap-5'>
				<div className='w-full duration-500 group-[.is-active]: font-bold bg-white text-[#c34115]'>
					{item.question}
				</div>
				<div className='text-xl rotate-90 duration-500 cursor-pointer group-[.is-active]:rotate[270deg]'
					onClick={handletoggleActive}>{'>'}
				</div>
			</div>
			<div className='overflow-hidden bg-white max-h-0 duration-500 group-[.is-active]:max-h-[100px]'>
				{item.answer}
			</div>
		</div>
	);
};
export default AccordionFAQs;