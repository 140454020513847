import React, { useState } from 'react';

import Button from 'components/Common/Buttons/Button';
import Modal from '../Modal';

interface IDeleteModal {
	isDeleteModalShow: boolean;
	type: string;
	setDeleteModalShow: React.Dispatch<React.SetStateAction<boolean>>;
	onClickCancel: (e: any) => void;
	onClickDelete: (e: any) => void;
}

export const DeleteModal: React.FC<IDeleteModal> = ({isDeleteModalShow, type, setDeleteModalShow, onClickCancel, onClickDelete}) => {
	
	const renderDeleteModal = (): JSX.Element => {
		return(
			<>
				<div className='sm:mx-auto sm:w-full sm:max-w-md'>
					<div className='py-[28px] px-[60px] sm:rounded sm:px-10'>
						<div className='flex items-center justify-center'>
							<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='#e98923' className='w-[70px] h-[70px]'>
								<path strokeLinecap='round' strokeLinejoin='round' d='M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z' />
							</svg>
						</div>
						<p className='text-base text-[#707070] text-center mt-[15px]'> 
							Are you sure you want to delete this {type}?<br />This action cannot be undone.
						</p>
						<div className='flex flex-row gap-[42px] justify-center mt-[25px]'>
							<Button variant='secondary' className='px-[42px] py-1.5' onClick={onClickCancel}>Cancel</Button>
							<Button variant='primary' className='px-[42px] py-1.5' onClick={onClickDelete}>Delete</Button>
						</div>
					</div>
				</div>
			</>
		);
	};

	return (
		<div>
			<Modal open={isDeleteModalShow} onClose={setDeleteModalShow} className='sm:max-w-md'>
				{renderDeleteModal()}
			</Modal>
		</div>
	);
};

