import {
	FDeleteAlbumImagesAtom,
	FGallery,
	FGalleryAlbumIdAtom,
	FGalleryImageAtom,
	FUpdateGallery,
	IImageUrls,
} from 'store/CustomizeApp/Features/galleryAtom';
import React, { useState } from 'react';
import { atom, useAtom, useAtomValue } from 'jotai';
import Accordion from 'components/Accordion/AccordionGallery';
import { Button } from 'components/Common/Buttons';
import { DeleteModal } from 'components/Common/Modals/DeleteModal';
import { GalleryUseQuery } from '../UseQuery/GalleryQueries';
import { IFile } from 'components/Common/Uploader';
import { TrashIcon } from '@heroicons/react/24/outline';
import { AppFeatIsSaving } from 'store/CustomizeApp/Appearance';
import Spinner from 'components/Common/Spinner';
import { GetGalleryResponseType } from 'services/requests/Features/Gallery/schema';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { IMAGES} from 'utility';
import Tooltip from 'components/Common/Tooltip';


export type AccordionDataType = {
	title: string;
	images: IFile[];
};

export const selectedImages = atom<IImageUrls[] | []>([]);

const Gallery: React.FC = () => {
	GalleryUseQuery();

	const { id } = useParams();

	const queryClient = useQueryClient();
	const cachedGallery = queryClient.getQueryData<GetGalleryResponseType>(['gallery', id]);

	const [selected, setSelected] = useAtom(selectedImages);
	const [accordionDatas, setAccordionDatas] = useAtom(FGallery);
	const selectedImg = useAtomValue(selectedImages);
	const [galleryAlbumId, setGalleryAlbumId] = useAtom(FGalleryAlbumIdAtom);
	const [toUpdate, setToUpdate] = useAtom(FUpdateGallery);
	const [accordionImages, setAccordionImages] = useAtom(FGalleryImageAtom);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [accordionIndex, setAccordionIndex] = useState(0);
	const [, setImageUploadId] = useAtom(FDeleteAlbumImagesAtom);

	const isSaving = useAtomValue(AppFeatIsSaving);

	const handleAddNewAccordion = () => {
		accordionDatas && accordionDatas.length > 0
			? setAccordionDatas([...accordionDatas, { title: 'Album', images: [] }])
			: setAccordionDatas([{ title: 'Album', images: [] }]);
		
		accordionImages && accordionImages.length > 0
			? setAccordionImages([...accordionImages, { title: 'Album', images: [], isTitleNew: true, hasImgToUpload: false, hasImgToDelete: false }])
			: setAccordionImages([{ title: 'Album', images: [], isTitleNew: true, hasImgToUpload: false, hasImgToDelete: false }]);
	};

	const handleImageUpload = (file: IFile[], index: number) => {
		const oldAlbums = accordionImages.filter((album) => album.albumId);
		const offset = oldAlbums.length;

		if (file.length > 0) {
			if (accordionImages[index].albumId !== undefined) {
				//to dispatch update request
				const toUpdateTmp = [...toUpdate];
				if (toUpdateTmp[index].images) {
					toUpdateTmp[index].images.push(...file);
				} else {
					toUpdateTmp[index].images = file;
				}
				setToUpdate(toUpdateTmp);
				accordionImages[index].hasImgToUpload = true;
			} else {
				//to dispatch create request
				const tmp = [...accordionDatas];
				if (tmp[index - offset] === undefined) return;
				if (tmp[index - offset].images) {
					tmp[index - offset].images.push(...file);
				} else {
					tmp[index - offset].images = file;
				}
				setAccordionDatas(tmp);
			}
			//set copy to display
			const Imagetmp = [...accordionImages];
			if (Imagetmp[index].images && Imagetmp[index].images.length > 0) {
				const newImages = file
					.filter(
						(img) =>
							!Imagetmp[index].images.some(
								(existingImg) => existingImg.url === img.url
							)
					)
					.map((img) => ({ name: img.name, url: img.url }));

				Imagetmp[index].images = [
					...Imagetmp[index].images,
					...newImages,
				];
			} else {
				Imagetmp[index].images = file.map((img) => {
					return { name: img.name, url: img.url };
				});
			}
			setAccordionImages(Imagetmp);
		}
	};

	const handleChange = (value: string, index: number) => {
		const oldAlbums = accordionImages.filter((album) => album.albumId);
		const offset = oldAlbums.length;

		// if (value.length > 0) {
		if (accordionImages[index].albumId !== undefined) {
			//to dispatch update request
			const toUpdateTmp = [...toUpdate];
			toUpdateTmp[index].title = value;
			setToUpdate(toUpdateTmp);

			// check changes
			if (cachedGallery) {
				const cachedIndex = cachedGallery.rows.findIndex((album) => album.albumID === toUpdate[index].albumId);

				if (cachedGallery.rows[cachedIndex]?.title === toUpdate[index]?.title) {
					accordionImages[index].isTitleNew = false;
				} else {
					accordionImages[index].isTitleNew = true; 
				}
			}				
		} else {
			//to dispatch create request
			const tmp = [...accordionDatas];
			tmp[index - offset].title = value;
			setAccordionDatas(tmp);
		}
		//set copy to display
		const Imagetmp = [...accordionImages];
		Imagetmp[index].title = value;
		setAccordionImages(Imagetmp);
		// }
	};

	const handleDeletePhoto = (index: number, images: IImageUrls[]) => {
		const tmp = [...accordionImages];
		const tmpFile = accordionImages[index].images.filter(
			(item) => ![...selectedImg].includes(item)
		); // copy
		tmp[index].images = tmpFile;
		setAccordionImages(tmp);

		const toDelete = selected.filter((img) =>
			images.some((item) => img === item)
		);

		for (let i = 0; i < toDelete.length; i++) {
			setImageUploadId((prev) => [
				...prev,
				toDelete[i].imageUploadId as number,
			]);

			const updatedData = selected.filter(
				(img) => !images.some((item) => img === item)
			);
			setSelected(updatedData);
		}

		if (accordionDatas[index] === undefined) {
			//to dispatch update request
			const tmpDatas = [...toUpdate];
			if (tmpDatas[index] === undefined) return;

			const matchingUrls = selectedImg.map((selected) => {
				return tmpDatas[index].images.find((image) => {
					return (
						selected.name === image.name &&
						selected.url === image.url
					);
				});
			});

			matchingUrls.forEach((matchingUrl) => {
				const foundFileIndex = tmpDatas[index].images.indexOf(
					matchingUrl as IFile
				);
				if (foundFileIndex !== -1) {
					tmpDatas[index].images.splice(foundFileIndex, 1);
				}
			});

			setToUpdate(tmpDatas);
		} else {
			const oldAlbums = accordionImages.filter((album) => album.albumId);
			const offset = oldAlbums.length;
			
			//to dispatch create request
			const tmpDatas = [...accordionDatas];
			// Check if tmpDatas[index] exists, and if not, return early from the function.
			if (tmpDatas[index - offset] === undefined) return;

			// Create an array of matching URLs between selectedImg and images in tmpDatas[index]
			const matchingUrls = selectedImg.map((selected) => {
				return tmpDatas[index - offset].images.find((image) => {
					return (
						selected.name === image.name &&
						selected.url === image.url
					);
				});
			});

			// Iterate over the matching URLs and remove the corresponding image objects from tmpDatas[index].images
			matchingUrls.forEach((matchingUrl) => {
				// Find the index of the matching image object in tmpDatas[index].images
				const foundFileIndex = tmpDatas[index - offset].images.indexOf(
					matchingUrl as IFile
				);
				// If the matching image object was found, remove it from tmpDatas[index].images
				if (foundFileIndex !== -1) {
					tmpDatas[index - offset].images.splice(foundFileIndex, 1);
				}
			});

			// Update the state with the modified tmpDatas array
			setAccordionDatas(tmpDatas);
		}

		// check changes
		if (cachedGallery) {
			const cachedIndex = cachedGallery.rows.findIndex((album) => album.albumID === toUpdate[index].albumId);

			const currentImgIds = accordionImages[index]?.images.map((img) => img.imageUploadId);

			const imgToDelete = cachedGallery?.rows[cachedIndex]?.images
				? cachedGallery?.rows[cachedIndex]?.images.filter((img) => ![...currentImgIds].includes(img.imageUploadID))
				: [];

			const imgToUpload = accordionImages[index]?.images.filter((img) => img.imageUploadId === undefined);

			if (imgToDelete.length > 0) {
				accordionImages[index].hasImgToDelete = true;
			} else accordionImages[index].hasImgToDelete = false;

			if (imgToUpload.length > 0) {
				accordionImages[index].hasImgToUpload = true;
			} else accordionImages[index].hasImgToUpload = false;
		}	
	};

	const handleDeleteAccordionItem = (index: number) => {
		const oldAlbums = accordionImages.filter((album) => album.albumId);
		const offset = oldAlbums.length;

		if (accordionImages[index]?.albumId !== undefined) {
			const updatedTempItems = [...accordionImages];
			setGalleryAlbumId([
				...galleryAlbumId,
				updatedTempItems[index]?.albumId as number,
			]);
			updatedTempItems.splice(index, 1);
			setAccordionImages(updatedTempItems);
		} else {
			const updatedItems = [...accordionDatas];
			updatedItems.splice(index - offset, 1);
			setAccordionDatas(updatedItems);	

			const updatedTempItems = [...accordionImages];
			updatedTempItems.splice(index, 1);
			setAccordionImages(updatedTempItems);
		}
		
		setIsDeleteModalOpen(false);
	};

	return (
		<div className='bg-white w-full rounded-lg my-8 max-sm:pl-0'>
			<div className='bg-white px-3 mx-3 rounded max-sm:pb-[270px] max-sm:px-0'>
				<div className='py-5 flex-row max-sm:pb-[270px]'>
					<div className='flex justify-between items-center'>
						<h1 className='text-[#707070] font-bold text-2xl'>
						Gallery
						</h1>
						<div className='flex gap-3 items-center'>
							{/* -----------Video Tutorial and Help Icons------------ */}
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.CameraIcon} alt='camera icon' />
							</Tooltip>
							<Tooltip className='p-2 text-xs bg-gray-400 text-white whitespace-nowrap z-10' message={'Coming Soon'}>
								<img onClick={() => ({})} className='w-[20px] h-[20px] cursor-pointer' src={IMAGES.InfoIcon} alt='info icon' />
							</Tooltip>
							{/* ---------------------------------------------------- */}
						</div>
					</div>
					<div className='flex justify-end mt-3'>
						<Button
							className='w-[139px] h-[39px] bg-[#E0781D] text-white font-semibold rounded-md max-sm:py-[4px] max-sm:px-[17px] max-sm:text-[12px]'
							variant='primary'
							onClick={handleAddNewAccordion}
							disabled={isSaving}
						>
							+ Add Album
						</Button>
					</div>
					<div className='flex flex-col gap-[210px] z-10 max-sm:mt-10'>
						<div className='accordion'>
							{accordionImages && accordionImages.map((accordionData, index) => (
								<div
									key={index}
									className='flex flex-row w-full max-sm:mb-5'
								>
									<Accordion
										items={accordionData}
										key={index}
										index={index}
										handleImageUpload={handleImageUpload}
										handleChange={handleChange}
										handleDeletePhoto={handleDeletePhoto}
									/>
									<div className='mb-5 mt-8 mx-2 text-[#B35916] max-sm:mt-4'>
										{
										// accordionImages.length !== 1 &&
											(
												<button
													disabled={isSaving}
													onClick={() => {
														setIsDeleteModalOpen(true);
														setAccordionIndex(index);
													}}
												>
													{
														(isSaving && (accordionData.isTitleNew || accordionData.hasImgToDelete || accordionData.hasImgToUpload))
															? <Spinner color='text-primary' size='w-8 h-8' className='!p-0 !-mt-1.5'/>
															: <div className='w-8 h-8'><TrashIcon className='w-5 h-5' /></div>
													}
												</button>
											)
										}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			<DeleteModal
				isDeleteModalShow={isDeleteModalOpen}
				setDeleteModalShow={() => setIsDeleteModalOpen(false)}
				onClickCancel={() => setIsDeleteModalOpen(false)}
				onClickDelete={() => handleDeleteAccordionItem(accordionIndex)}
				type='album'
			/>
		</div>
	);
};

export default Gallery;
