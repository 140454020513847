import { FOffers, FTempOffers } from 'store/CustomizeApp/Features/offersAtom';
import { SCardBackgroundColor, SCardBorderColor, SCardContentColor, SCardIconColor, SCardTitleColor, STopBarColor, STopBarTextColor } from 'store/CustomizeApp/Appearance/colorAtom';

import React from 'react';
import { classNames } from 'utility/common';
import { useAtomValue } from 'jotai';

const Offers: React.FC = () => {
	const topBarColor = useAtomValue(STopBarColor);
	const topBarTextColor = useAtomValue(STopBarTextColor);

	const cardBackgroundColor = useAtomValue(SCardBackgroundColor);
	const cardBorderColor = useAtomValue(SCardBorderColor);
	const cardIconColor = useAtomValue(SCardIconColor);
	const cardTitleColor = useAtomValue(SCardTitleColor);
	const cardContentColor = useAtomValue(SCardContentColor);

	const offers = useAtomValue(FTempOffers);

	function formatWithCommas(value: string) {
		const numberValue = parseFloat(value);
		if (isNaN(numberValue)) {
			return value;
		}
		return numberValue.toLocaleString(undefined, { maximumFractionDigits: 10 });
	}

	return (
		<div
			style={{ color: `${cardBorderColor}` }}
			className={classNames('py-[60px] px-3 h-full absolute overflow-y-scroll no-scrollbar w-full')}
		>
			{offers?.map((offerData, idx) =>
				offerData?.couponType == 1 ? (
					<div
						style={{
							backgroundColor: `${cardBackgroundColor}`,
							border: `1px solid ${cardBorderColor}`,
						}}
						key={idx}
						className='w-full flex flex-col h-fit rounded-[5px] border border-1 mb-[5px] '
					>
						<div className='flex m-2 '>
							{offerData?.discountType === 'Percentage' ? (
								<div
									style={{
										color: `${cardIconColor}`,
										borderRight: `1px dashed ${cardBorderColor}`,
									}}
									// className='h-[70px] w-[110px] flex flex-row justify-center font-extrabold text-[35px] 2xl:text-[35px] xl:text-[35px] lg:text-[35px] md:text-[35px] sm:text-[30px] max-sm:text-[30px] items-center border-r-2 border-dashed'
									className={`${offerData?.discount && offerData?.discount?.length >= 3 ? ''
										: ''
									} h-[70px] w-[110px] flex flex-row justify-center font-extrabold items-center border-r-2'`}
								>
									<div>
										<p className='text-[30px] sm:text-[27px] md:text-[30px] lg:text-[35px]'>{offerData?.discount}</p>
									</div>
									<div className='flex flex-col items-center'>
										<div>
											<p className='text-[15px] sm:text-[17px] md:text-[20px]'>%</p>
										</div>
										<div>
											<p className='text-[10px] sm:text-[7px] md:text-[10px]'>OFF</p>
										</div>
									</div>
								</div>
							) : (
								<div
									style={{
										color: `${cardIconColor}`,
										borderRight: `1px solid ${cardBorderColor}`,
									}}
									// className='h-[70px] w-[110px] flex flex-col justify-center font-extrabold text-[30px] 2xl:text-[30px] xl:text-[30px] lg:text-[30px] md:text-[30px] sm:text-[25px] max-sm:text-[25px] items-center border-r-2 border-dashed'
									className={`${offerData?.discount && offerData?.discount?.length >= 3 ? 'text-[15px] 2xl:text-[15px] xl:text-[15px] lg:text-[15px] md:text-[15px] sm:text-[12px] max-sm:text-[12px]'
										: 'text-[30px] 2xl:text-[30px] xl:text-[30px] lg:text-[30px] md:text-[30px] sm:text-[25px] max-sm:text-[25px]'
									} h-[70px] w-[110px] flex flex-col justify-center font-extrabold items-center border-r-2'`}
								>
									<div>
										{/* <p className=''>${offerData?.discount}</p> */}
										<p className='pr-[2px]'>
											${offerData?.discount ? formatWithCommas(offerData.discount) : null}
										</p>
									</div>
									<div>
										<p className='text-[15px] md:text-[15px] sm:text-[12px] max-sm:text-[12px]'>OFF</p>
									</div>
								</div>
							)}
							<div
								style={{ color: `${cardContentColor}` }}
								className='w-full ml-2'
							>
								<div className='flex flex-row w-full'>
									<div className='flex-grow'>
										<p style={{ color: `${cardTitleColor}` }} className='text-[12px] max-lg:text-[10px] max-md:text-[9px] max-sm:text-[9px] font-extrabold'>{offerData?.title.length > 12 ? `${offerData?.title.slice(0, 12)}...` : offerData?.title}</p>
									</div>
									<div className='justify-end'>
										<p className='text-[9px] font-semibold text-right 2xl:text-[9px] md:text-[8px] sm:text-[7px] max-sm:text-[7px]'>TC</p>
									</div>
								</div>
								<p style={{ color: `${cardContentColor}` }} className='text-[9px] 2xl:text-[9px] lg:text-[8px] max-md:text-[7px] md:text-[7px] max-sm:text-[7px] sm:text-[7px] font-semibold'>{offerData?.description && offerData?.description?.length > 18 ? `${offerData.description?.slice(0, 18)}...` : offerData?.description}</p>
								{offerData?.code && <div className={offerData?.code && offerData?.code?.length < 12 ? 'flex flex-row w-full mt-[10px] items-center' : 'flex flex-col mt-[10px] w-full'}>
									<div className='flex-grow'>
										<p className='text-[9px] md:text-[8px] sm:text-[7px] max-sm:text-[7px] font-semibold'>PROMO CODE</p>
									</div>
									<div className='justify-end'>
										<p
											style={{
												backgroundColor: `${cardBackgroundColor}`,
												color: `${cardContentColor}`,
												border: `1px solid ${cardBorderColor}`,
											}}
											className={offerData?.code && offerData?.code?.length < 12 ? 'text-[12px] text-white px-1 py-[3px] rounded-[3px] font-normal text-right' : '2xl:text-[10px] xl:text-[10px] lg:text-[10px] md:text-[9px] sm:text-[8px] max-sm:text-[8px] text-white px-1 py-[3px] rounded-[3px] font-normal text-left 2xl:w-full md:w-fit sm:w-fit max-sm:w-fit'}
										>
											{offerData?.code}
										</p>
									</div>
								</div>}
							</div>
						</div>
					</div>
				) : (
					<div
						style={{
							backgroundColor: `${cardBackgroundColor}`,
							color: `${topBarTextColor}`,
							border: `1px solid ${cardBorderColor}`,
						}}
						key={offerData?.offerID}
						className='w-full flex flex-col h-fit rounded-[5px] border border-1 mb-[5px]'
					>
						<div className='flex m-2'>
							<div style={{ color: `${cardIconColor}`, borderRight: `1px solid ${cardBorderColor}` }} className='h-[70px] w-[110px] flex flex-row font-bold text-[35px] 2xl:text-[30px] xl:text-[30px] lg:text-[30px] md:text-[20px] sm:text-[20px] max-sm:text-[20px] mr-2 pr-2 items-center justify-center border-dashed border-r-2'>
								<p className='p-1'>{offerData?.buy}</p>
								<p>+</p>
								<p className='p-1'>{offerData?.get}</p>
							</div>
							<div className='w-full'>
								<div className='flex flex-row w-full'>
									<div className='flex-grow'>
										<p style={{ color: `${cardTitleColor}` }} className='text-[12px] max-lg:text-[10px] max-md:text-[9px] max-sm:text-[9px] font-extrabold'>{offerData?.title.length > 13 ? `${offerData?.title.slice(0, 13)}...` : offerData?.title}</p>
									</div>
									<div className='justify-end'>
										<p style={{ color: `${cardContentColor}` }} className='text-[9px] font-semibold text-right 2xl:text-[9px] xl:text-[9px] max-lg:text-[7px] md:text-[7px] max-sm:text-[7px]'>TC</p>
									</div>
								</div>
								<p style={{ color: `${cardContentColor}` }} className='text-[9px] 2xl:text-[9px] lg:text-[8px] md:text-[6px] max-sm:text-[6px] sm:text-[6px] font-semibold'>{offerData?.description && offerData?.description?.length > 21 ? `${offerData.description?.slice(0, 21)}...` : offerData?.description}</p>
								{offerData?.code && <div className={offerData?.code && offerData?.code?.length < 12 ? 'flex flex-row w-full mt-[10px] items-center' : 'flex flex-col mt-[10px] w-full'}>
									<div className='flex-grow'>
										<p style={{ color: `${cardContentColor}` }} className='text-[10px] md:text-[8px] sm:text-[7px] max-sm:text-[7px] font-semibold'>PROMO CODE</p>
									</div>
									<div className='justify-end'>
										<p
											style={{
												backgroundColor: `${cardBackgroundColor}`,
												color: `${cardContentColor}`,
												border: `1px solid ${cardBorderColor}`,
											}}
											className={offerData?.code && offerData?.code?.length < 12 ? 'text-[12px] text-white px-1 py-[3px] rounded-[3px] font-normal text-right' : 'text-[10px] 2xl:text-[10px] xl:text-[10px] lg:text-[10px] md:text-[9px] sm:text-[8px] max-sm:text-[8px] text-white px-1 py-[3px] rounded-[3px] font-normal text-left 2xl:w-full md:w-fit sm:w-fit max-sm:w-fit'}
										>
											{offerData?.code}
										</p>
									</div>
								</div>}
							</div>
						</div>
					</div>
				)
			)}
		</div>
	);
};

export default Offers;