import React, { RouteObject } from 'react-router-dom';

import AppInReview from 'pages/CustomizeApp/Publish/AppInReview';
import Approver from 'components/Common/Approver';
import { BuildYourApp } from 'pages/LetUsBuildYourApp';
import ChooseIndustryPage from 'pages/ChooseIndustryPage';
import Container from './container';
import CustomizeApp from 'pages/CustomizeApp';
import ManageAppPage from 'pages/ManageAppPage';
import Publish from 'pages/Publish';
import { ResellerPage } from 'pages/ResellerPage';
import UserProfile from 'pages/EditProfile';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import TermsOfUseLanding from 'pages/TermsOfUse';
import ContactUs from 'pages/Contacts';
import Accessibility from 'pages/Accessibility';
import ForgotEmail from 'pages/Auth/Forgot-Password/Input-Email';
import VerifyItsYou from 'pages/EditProfile/EditProfilePanel/ResetPassword/VerifyItsYou';
import ResetYourPassword from 'pages/EditProfile/EditProfilePanel/ResetPassword/ResetYourPassword';
import CreatePassword from 'pages/EditProfile/EditProfilePanel/ResetPassword/CreatePassword';

export const PrivateRoutes: RouteObject = {
	element: <Container />,
	children: [
		{
			path: '/SelectIndustry',
			element: <ChooseIndustryPage />,
		},
		{
			path: '/edit/:id',
			element: <CustomizeApp />,
		},
		{
			path: '/dashboard',
			element: <ManageAppPage />,
		},
		{
			path: '/signed/become-a-reseller',
			element: <ResellerPage />,
		},
		{
			path: '/signed/build',
			element: <BuildYourApp />,
		},
		{
			path:'/signed/privacy-policy',
			element:<PrivacyPolicy />,
		},
		{
			path:'/signed/terms-of-use',
			element:<TermsOfUseLanding />,
		},
		{
			path:'/signed/contact-us',
			element:<ContactUs />,
		},
		{
			path:'/signed/accessibility',
			element:<Accessibility/>,
		},
		{
			path: '/Publish',
			element: <Publish />,
		},
		{
			path: '/approver',
			element: <Approver />,
		},
		{
			path: '/edit-profile',
			element: <UserProfile />,
		},
		{
			path: '/app-in-review/:id',
			element: <AppInReview />,
		},
		{
			path: '/CreatePassword',
			element: <CreatePassword />,
		},
		{
			path: '/VerifyItsYou',
			element: <VerifyItsYou />,
		},
		{
			path: '/ResetYourPassword',
			element: <ResetYourPassword />,
		},
	],
};