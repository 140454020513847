import React, { useState } from 'react';

import { ChevronRightIcon, ChevronDownIcon, PaperAirplaneIcon, GlobeAsiaAustraliaIcon } from '@heroicons/react/24/solid';
import FormInput from 'components/Common/Forms/Inputs';
import { Button } from 'components/Common/Buttons';
import { ContentModal } from 'components/Common/Modals/ContentModal';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { classNames } from 'utility';
import { EditNotificationParams, GetNotificationType } from 'services/requests/Features/Notification/schema';

export type NotifAccordionDataType = {
  notificationID: number,
	appID: number,
	icon: string,
	title: string,
	message: string,
	url: string,
	status: string,
	whoAdded: number,
	whenAdded: string,
	whenUpdated: string
};

type NotifAccordionProps = {
  items: NotifAccordionDataType;
  index: number;
	id: number;
	handleSaved?: (data: GetNotificationType) => void;
	children?: never[];
}

const NotificationAccordion = (props: NotifAccordionProps) => {

	const [isActive, setIsActive] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [notifData,setNotifData]= useState<GetNotificationType>({
		title: props.items.title,
		message: props.items.message,
		icon: props.items.icon,
		url: props.items.url,
		notificationID: props.items.notificationID,
		appID: props.items.appID,
		status: props.items.status,
		whenAdded: props.items.whenAdded,
		whenUpdated: props.items.whenUpdated,
		whoAdded: props.items.whoAdded,
	});

	const handleChange = (data:GetNotificationType) =>
	{
		setNotifData(data);    
		setIsOpen(false);
	};

	const handleSaved = (data: GetNotificationType) =>
	{
		props.handleSaved && props.handleSaved(data);
		setIsOpen(false);
	};

	return(
		<>
			<div className="accordion-item mt-4 w-full ">
				<div className="accordion-title" onClick={() => setIsActive(!isActive)}>
					<div className=' relative cursor-pointer w-full h-[55px] pl-5 flex items-center border border-gray-200 rounded-md bg-white text-center text-lg font-bold  text-gray-500'>{props.items?.title}				
						<div className='w-6 h-6 right-2 absolute '>{isActive ? <ChevronDownIcon/> : <ChevronRightIcon/>}</div>
					</div>
				</div>
				{isActive && <div className="accordion-content -mt-[1px] border min-h-[350px] h-fit p-4">
					<div className='  mt-[35px] ml-7 '> 
						<div className=' mb-[40px] '>
							<label className='block text-[#5A7184] font-medium text-[16px] mb-[10px]' htmlFor='title'>Title<span className='text-red-600'>*</span>
							</label>
							<div className='flex'>
								
								<span 
									onClick={()=>setIsOpen(true)}
									className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500  cursor-pointer">
									{notifData.icon === 'fas fa-globe' 
										? <GlobeAsiaAustraliaIcon className='h-5 w-auto text-gray-600'/>
										: <i className={classNames(notifData.icon, 'text-md text-gray-600')}/>
										
									}
									<div className="px-2 pt-0.5">
										<div className="w-0 h-0 border-l-[6px] border-l-transparent border-t-[8px] border-t-gray-600 border-r-[6px] border-r-transparent"/>
									</div>
								</span>

								
								<FormInput type='text' placeholder='Notification Title' value={notifData.title} className='text-[14px] w-[350px] h-[40px] rounded-l-none' onChange={(value)=>handleChange({...notifData, title:value})} />	
							</div>
						
						</div>
						<div className=' mb-[40px] '>
							<label className='block text-[#5A7184] font-medium text-[16px] mb-[10px]' htmlFor='title'>Message<span className='text-red-600'>*</span>
							</label>
							<FormInput type='text' placeholder='Message' value={notifData.message} className='text-[14px] w-[350px] h-[79px] placeholder-left' onChange={(value)=>handleChange({...notifData, message:value})} />	
						</div>
						<div className=' mb-[40px] '>
							<label className='block text-[#5A7184] font-medium text-[16px] mb-[10px]' htmlFor='title'>URL<span className='text-red-600'>*</span>
							</label>
							<FormInput type='text' placeholder='' value={notifData.url} className='text-[14px] w-[350px] h-[40px]' onChange={(value)=>handleChange({...notifData, url:value})} />	
						</div>
						<Button
							variant='primary'
							className='py-2 px-10'
							onClick={() => handleSaved(notifData)}
							disabled={ notifData.title === '' || notifData.message === '' || notifData.url === '' || 
							notifData.title.trim() === '' || notifData.message.trim() === '' || notifData.url.trim() === ''? true : false }

						>
							<div className='flex'>
								<span className='h-5 w-5 mr-2 -rotate-45'>
									<PaperAirplaneIcon />
								</span>
						Send
							</div>
						
						</Button>
					</div>
				</div>}
			</div>
			<ContentModal 
				isContentModalShow={isOpen}
				setContentModalShow={setIsOpen}
				onClick={(data)=>handleChange({...notifData, icon: data})}
				onClickCancel={() => setIsOpen(false)}
			/>
		</>
	);
};

export default NotificationAccordion;