import React, { useState, useEffect } from 'react';
import FormInputPassword from 'components/Common/Forms/Password';
import { Button } from 'components/Common/Buttons';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { changePasswordAPI } from 'services/requests/EditProfile';
import { Controller, useForm } from 'react-hook-form';
import {
	ChangePasswordResponseType,
	ChangePasswordSchema,
} from 'services/requests/EditProfile/schema';
import { showFailedAlert, showSuccessAlert } from 'utility/alerts';

import { authAtom, hasUserCredentials, userCredentialsAtom } from 'store/authAtom';
import { useAtom, useAtomValue } from 'jotai';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { log } from 'utility/log';



const ResetPassPanel: React.FC = () => {
	const queryClient = useQueryClient();
	
	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const emailString = localStorage.getItem('email');
	const defaultValues = emailString ? { email: JSON.parse(emailString) } : {};
	const [showPassLogin, setShowPassLogin] = useState(false);

	const hasUserCredential = useAtomValue(hasUserCredentials);
	const userCreds = useAtomValue(userCredentialsAtom);
 
	useEffect(() => {
		console.log('hasUserCredential: ', hasUserCredential);
	},[]);


	const { control, handleSubmit } = useForm<ChangePasswordResponseType>({
		mode: 'onBlur',
		resolver: zodResolver(ChangePasswordSchema),
		defaultValues: defaultValues,
	});

	const Submit = (data: ChangePasswordResponseType) => {
		if (currentPassword === newPassword) {
			showFailedAlert('New password must be different from current password.');
		} else {
			changePassword(data);
		}
	};

	const { mutate: changePassword } = useMutation<
		AxiosError,
		unknown,
		ChangePasswordResponseType
	>(
		(data) => {
			return changePasswordAPI(data);
		},
		{
			onSuccess: () => {
				showSuccessAlert('Password changed.');
				setCurrentPassword('');
				setNewPassword('');
				setConfirmPassword('');
				queryClient.invalidateQueries(['userCredentials']);
				setShowPassLogin(false);
			},
			onError: (error) => {
				log(error);
				showFailedAlert('Current password is incorrect. Please try again.');
			},
		}
	);

	//proceed update - Update Button
	const handleClick = () => {
		setShowPassLogin(true);
	};

	//proceed go back - Cancel
	const handleGoBack = () => {
		setShowPassLogin(false);
		setCurrentPassword('');
		setNewPassword('');
		setConfirmPassword('');

	};

	//show if Google Login
	const PassGoogleLogin = () => {
		return (<div className='mt-0 relative flex items-center text-[#464255] w-[90%]'>
			<p>To add a password to your account for the first time, you will need to use the <Link id='nav-link' to='/CreatePassword'  className='text-primary hover:text-primary underline hover:cursor-pointer' >reset password page</Link> so we can verify your identity.</p>
		</div>);
	};

	return (
		<div className='w-full px-10 pt-10 pb-28'>
			<div className='text-[#707070] text-[18px] font-bold'>Password</div>
			<hr className='mt-5' />
			<div className='mt-5 relative flex items-center text-[#464255]'>

				{hasUserCredential ? <div>
					{showPassLogin ? (
						<div>
							<div className=' flex-row justify-center items-center lg:max-w-md w-full'>
								<div className='mt-0 flex-1 flex-col justify-start items-start my-[15.5px]'>
									<div>
										<Controller
											control={control}
											name='currentPassword'
											render={({ field: { name, onChange }, fieldState: { error } }) => (
												<FormInputPassword
													label2='Current Password'
													type='password'
													name={name}
													placeholder='********'
													error={error?.message}
													onChange={(value) => {
														onChange(value);
														setCurrentPassword(value);
													}}
													value={currentPassword}
													IconColor='text-[#4663AC]'
													className='font-normal placeholder:text-[11px]'
												/>
											)}
										/>
									</div>
									<div className='mt-6 text-white'>
										<Controller
											control={control}
											name='newPassword'
											render={({ field: { name, onChange }, fieldState: { error } }) => (
												<FormInputPassword
													onChange={(value) => {
														onChange(value);
														setNewPassword(value);
													}}
													value={newPassword}
													name={name}
													error={error?.message}
													type='password'
													label2='New Password'
													placeholder='********'
													IconColor='text-[#4663AC]'
													className='font-normal placeholder:text-[11px]'
												/>
											)}
										/>
									</div>
									<div className='mt-6'>
										<Controller
											control={control}
											name='confirmPassword'
											render={({ field: { name, onChange }, fieldState: { error } }) => (
												<FormInputPassword
													label2='Confirm New Password'
													type='password'
													name={name}
													placeholder='********'
													error={error?.message}
													onChange={(value) => {
														onChange(value);
														setConfirmPassword(value);
													}}
													value={confirmPassword}
													IconColor='text-[#4663AC]'
													className='font-normal placeholder:text-[11px]'
												/>
											)}
										/>
									</div>
								</div>
							</div>
							<div className='min-md:flex min-md:justify-center min-md:items-center max-md:flex max-md:justify-center max-md:items-center min-sm:flex min-sm:justify-center min-sm:items-center max-sm:flex max-sm:justify-center max-sm:items-center'>
								<button onClick={handleGoBack} className='min-md:mt-4 max-md:mt-4 min-md:w-3/4 max-md:w-3/4 min-sm:mt-4 max-sm:mt-4 min-sm:w-3/4 max-sm:w-3/4 w-[125px] py-1.5 font-semibold bg-[#FFFFFF] hover:#ffab6c text-[#FF7F20] border-primary rounded mr-[10px] border-[1px] border-solid'>
									Cancel
								</button>
								<Button
									className='min-md:mt-4 max-md:mt-4 min-md:w-3/4 max-md:w-3/4 min-sm:mt-4 max-sm:mt-4 min-sm:w-3/4 max-sm:w-3/4 w-[125px] py-1.5 font-semibold bg-[#FF7F20] hover:#ffab6c border-transparent disabled:bg-[#FF7F20] text-white'
									type='submit'
									disabled={
										newPassword !== confirmPassword ||
										newPassword === '' ||
										confirmPassword === ''
									}
									variant='primary'
									onClick={handleSubmit(Submit)}
								>
									Save
								</Button>
							</div>
						</div>
					) : (
						//Time update when user change pass
						<div className='absolute flex flex-row flex-wrap w-full content-center max-sm:flex-col max-md:flex-col max-sm:items-center sm:items-center md:items-center '>
							<div className='relative flex-1 '>
								Password last updated:  {userCreds ? dayjs(userCreds.createdDate).format('MMMM DD, YYYY HH:mm:ss') : ''}
							</div>
							
							<div className='flex-1 relative right-0 max-sm:static md:relative lg:absolute' >
								<button
									className='px-8 py-2 bg-[#ECEAEA] rounded-[5px] font-semibold text-[#545454]'
									onClick={handleClick}
								>
								Update
								</button>
							</div>
							
						</div>
					)}
				</div>
					:
					<PassGoogleLogin />
				}
			</div>
		</div>
	);
};
export default ResetPassPanel;