import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const CreatePassword: React.FC =() => {
    const navigate = useNavigate();

    const CreatePass = () => {
        return(
            <form>
            <div className='flex justify-center w-full h-full my-[150px]'>
                <div className='p-[40px] h-fit w-[532px] bg-[#FFFFFF] rounded-[10px] shadow-xl'>
                <p className='text-[#464255] text-[28px] font-bold font-[montserrat] mt-9 h-fit mb-[27px] flex justify-center items-center leading-[34px]'>Reset Your Password</p>
                <p className='text-[#707070] font-poppins flex justify-center items-center'>Enter your email address and we'll send an email.</p>
                <p className='text-[#707070] font-poppins flex mb-[13px] justify-center items-center'>with instructions to reset your password.</p>
                <p className='text-[#707070] font-poppins flex justify-start mb-2'>Email Address</p>
                <input className='border-[1px] border-gray-300 w-full h-[45px] px-2 rounded font-poppins' type="email"/>
                <button onClick={()=>{navigate('/VerifyItsYou')}} type='submit' className='bg-[#E0781D] w-full flex justify-center mt-8 mb-[35px] rounded py-[10px] text-white'>Continue</button>
                
            {// When the Sign In is clicked it will log out and redirect to Sign in Page 
            } 
              <div className='flex justify-center'>
                <p>Return to <span>		
				<Link
						to={''}				
					><span className='text-[#FF7F20] underline'>Sign In</span></Link>
					</span></p>	
            </div>
                </div>
            </div>

            </form>   
            );           
};
return(
    <div>
        <CreatePass />
    </div>
);
};
export default CreatePassword;