import z from 'zod';

export const GetResellerFormSchema = z.object({
	name:        z.string().regex(/^[a-zA-Z\s]*$/, {message: 'Input must be letters only'}),
	email:       z.string().email(),
	phoneNumber: z.string(),
	website:     z.string().regex(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/, {message: 'Input must be a valid URL'}),
	country:     z.string(),
	message:     z.string(),
	adminEmail:  z.string().email(),
});

export type GetResellerFormType = z.infer<typeof GetResellerFormSchema>;
export type GetResellerFormResponse = z.infer<typeof GetResellerFormSchema>;