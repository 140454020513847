import { axiosInstance } from 'services/axios';
import { LoginVerifiedResponse, loginResponseSchema, LoginType, SignUpType, signUpSchema, VerifyEmailType, forgotEmailSendingSchema, ForgotEmailSchema, forgotResetPasswordSchema, ForgotResetPasswordSchema, ValidateResetPasswordTokenSchema, GetGoogleTokenType, AuthorizeGoogleUserResponse, validateResetPasswordTokenSchema, GetUserCredentialsResponse, DisconnectSsoResponse, disconnectSsoSchema, DisconnectSsoRequest } from './schema';
import { REMOVE_FEDERATED_IDENTITY, USER_CREDENTIALS } from 'services/api';

export const loginApi = async (data: LoginType) => {
	const response = await axiosInstance.post('/auth/login', data);
	const parsedData = loginResponseSchema.parse(response.data);
	localStorage.setItem('email', JSON.stringify(data.username));
	return parsedData;
};

export const signUpApi = async (data: SignUpType) => {
	const validatedSignUp = signUpSchema.parse(data);
	const response = await axiosInstance.post('/auth/createUser', validatedSignUp);
	return response.data;
};

export const verifyEmailToken = async (params: VerifyEmailType) => {
	const response = await axiosInstance.put('/auth/verifyEmail', params);
	return response.data;
};

export const forgotEmailSendingApi = async (data: ForgotEmailSchema ) => {
	const validatedForgotEmail = forgotEmailSendingSchema.parse(data);
	const response = await axiosInstance.post('/auth/forgotPassword', validatedForgotEmail);
	return response.data;
};

export const forgotResetPasswordApi = async (data: ForgotResetPasswordSchema ) => {
	const validatedForgotResetPassword = forgotResetPasswordSchema.parse(data);
	const response = await axiosInstance.put('/auth/resetPassword', validatedForgotResetPassword);
	return response.data;
};

export const validateResetPasswordTokenApi = async (data: ValidateResetPasswordTokenSchema ) => {
	const validatedResetPasswordToken = validateResetPasswordTokenSchema.parse(data);
	const response = await axiosInstance.post('/auth/validateResetPasswordToken', validatedResetPasswordToken);
	return response.data;
};

export const approverLoginApi = async (data: LoginType) => {
	const response = await axiosInstance.post('/auth/approver/login', data);
	const parsedData = loginResponseSchema.parse(response.data);
	localStorage.setItem('email', JSON.stringify(data.username));
	return parsedData;
};

export const getGoogleAuthUrl = async () => {
	const response = await axiosInstance.get('/auth/googleUrl');
	return response.data;
};

export const authorizeGoogleUser = async (params: GetGoogleTokenType) => {
	const response = await axiosInstance.post('/auth/googleToken', params);
	localStorage.setItem('email', JSON.stringify(response.data.email));
	localStorage.setItem('federatedIdentity', 'true');
	return response.data as AuthorizeGoogleUserResponse;
};

export const getUserVerified = async (email?: string) => {
	const response = await axiosInstance.get(`/auth/KeyclockGetUserInfoByEmail/${email}`);
	return response.data as LoginVerifiedResponse;
};

export const linkAccount = async (data: LoginType, token: string) => {
	const response = await axiosInstance.post(`/auth/linkAccount?token=${token}`, data);
	const parsedData = loginResponseSchema.parse(response.data);
	localStorage.setItem('email', JSON.stringify(data.username));
	return parsedData;
};

export const getUserCredentials = async (email: string | undefined) => {
	const response = await axiosInstance.get(`${USER_CREDENTIALS}/${email}`);
	return response.data as GetUserCredentialsResponse;
};

export const disconnectSso = async (email: string | undefined) => {
	const response = await axiosInstance.delete(`${REMOVE_FEDERATED_IDENTITY}/${email}`);
	return response.data as DisconnectSsoResponse;
};