import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useAtomValue } from 'jotai';
import React from 'react';
import { resellerAtom } from 'store/approverAtom';
import { classNames } from 'utility';

function Pagination() {
	const resellerData = useAtomValue(resellerAtom);

	return (
		<div className="flex items-center justify-between  border-gray-200 bg-white px-4 py-3 sm:px-6">
			<div className="flex flex-1 justify-between sm:hidden">
				<a
					id='nav-link'
					href="#"
					className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
				>
      Previous
				</a>
				<a
					id='nav-link'
					href="#"
					className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
				>
      Next
				</a>
			</div>
			<div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
				<div>
					<p className="text-sm text-gray-700">
        Showing <span className="font-medium">{resellerData?.page}</span> to <span className="font-medium">{resellerData?.totalPages}</span> of{' '}
						<span className="font-medium">{resellerData?.totalRows}</span> results
					</p>
				</div>
				<div>
					<nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
						<a
							id='nav-link'
							href="#"
							className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
						>
							<span className="sr-only">Previous</span>
							<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
						</a>

						{resellerData?.rows.map((_,index)=>(
							<>
								{
									index === resellerData.page &&
									<div key={index}>
										<button
											className={classNames(index === resellerData.page ? 'bg-[#3C5491] focus-visible:outline-indigo-600': '','relative z-10 inline-flex items-center px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ')}
										>
											{index}
										</button>
									</div>
								}
							</>
						))}
						<a
							id='nav-link'
							href="#"
							className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
						>
							<span className="sr-only">Next</span>
							<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
						</a>
					</nav>
				</div>
			</div>
		</div>
	);
}

export default Pagination;