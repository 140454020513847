import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const VerifyItsYou: React.FC =() => {
    const navigate =useNavigate();
    const RenderVerification = () => {
        return(
            <div className='flex justify-center w-full h-full my-[150px]'>
                <div className='p-[40px] h-fit w-[532px] bg-[#FFFFFF] rounded-[10px] shadow-xl mx-8'>
                    <button onClick={()=>{navigate('/CreatePassword')}} className='text-[#1C180F] text-xs '>&lt; Back</button>
                    <p className='text-[#464255] text-[28px] font-bold font-[montserrat] mt-9 h-fit mb-[27px] flex justify-center items-center leading-[34px]'>Verify It's You</p>
                    <p className='text-[#707070] font-poppins flex text-center mx-7'>To secure your account, enter the code we just sent to jonathandoe@gmail.com.</p>
                    <p className='text-[#707070] font-poppins flex justify-start mt-4 text-[13px]'>Code</p>
                    <input className='border-[1px] border-[#9F9F9F99] w-full h-[45px] px-2 rounded font-poppins mb-10' type="text"/>
                    <button onClick={()=>{navigate('/ResetYourPassword')}} className='w-full h-10 text-center bg-[#E0781D] rounded text-white text-s'>Continue</button>
                    <p className=' text-center my-8'>Didn't get the code? <button className='underline text-[#FF7F20]'>Resend code</button></p>                
                </div>
            </div>      
            );        
            
            
	};
return(
    <div>
    <RenderVerification />
    </div>
    
);
  
};
export default VerifyItsYou;