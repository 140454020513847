import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { forgotEmailSendingApi } from 'services/requests/auth';
import {
	forgotEmailSendingSchema,
	ForgotEmailSchema,
	ForgotEmailResponse,
} from 'services/requests/auth/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { globalVariableAtom } from 'store/authAtom';

import { Button } from 'components/Common/Buttons';
import FormInput from 'components/Common/Forms/Inputs';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const ForgotEmail: React.FC = () => {
	const [globalVariable, setGlobalVariable] = useAtom(globalVariableAtom);
	const navigate = useNavigate();

	const { control, handleSubmit, formState } = useForm<ForgotEmailSchema>({
		defaultValues: { email: '' },
		mode: 'onBlur',
		resolver: zodResolver(forgotEmailSendingSchema),
	});

	const { mutate: forgotEmailMU } = useMutation<
		ForgotEmailResponse,
		AxiosError,
		ForgotEmailSchema
	>((data) => forgotEmailSendingApi(data), {
		onSuccess: (data) => {
			navigate('/forgot-request-email');
		},
		onError: (error) => {
			if (error.response?.status == 400) {
				setGlobalVariable('User was signed in using Google');
			} else {
				setGlobalVariable('User doesn\'t exist');
			}
		},
	});

	const onSubmit = (data: ForgotEmailSchema) => {
		forgotEmailMU(data);
	};

	return (
		<div className='max-md:p-6 max-sm:p-6 w-full h-[90vh] flex justify-center items-center bg-[#F6F6F6]'>
			<div className='max-md:w-full max-sm:w-full w-[35%] p-6 pl-12 pr-12 bg-white rounded-xl'>
				<h2 className='text-center text-[#464255] text-2xl font-bold'>
					Forgot Your Password?
				</h2>
				<p className='mt-4 text-[#707070] text-[12px] text-center'>
					Enter your registered email address and we&apos;ll send an email with
					instructions to reset your password.
				</p>
				<form className='w-full'>
					<div className='mt-7'>
						<Controller
							control={control}
							name='email'
							render={({ field: { name, onChange }, fieldState: { error } }) => (
								<>
									<FormInput
										label='Email Address'
										onChange={(e) => {
											onChange(e);
											setGlobalVariable('');
										}}
										name={name}
										error={error?.message}
										type='email'
										placeholder='johndoe@gmail.com'
									/>
									<div className='mt-2'>
										{globalVariable === 'User doesn\'t exist' || globalVariable === 'User was signed in using Google' ? (
											<p className='text-red-700 text-xs pl-1'>{globalVariable}</p>
										) : null}
									</div>
								</>
							)}
						/>
					</div>

					<div className='mt-7'>
						<Button
							type='submit'
							variant='primary'
							onClick={handleSubmit(onSubmit)}
							className='w-full py-1.5'
							disabled={!formState.dirtyFields.email || !!formState.errors.email}
						>
							<span className='text-sm font-semibold'>Reset Password</span>
						</Button>
					</div>

					<div className='mt-7 flex justify-center pb-12'>
						<div className='text-sm flex '>
							<p className='text-gray-500 pr-1'>Return to</p>
							<Link
								id='nav-link'
								to='/login    '
								className='text-primary hover:text-primary underline hover:cursor-pointer'
							>
								Sign In.
							</Link>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ForgotEmail;
