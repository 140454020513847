import * as offerApis from '../../../../../services/requests/Features/Offers/index';
import * as offerAtoms from 'store/CustomizeApp/Features/offersAtom';
import * as typeSchemas from 'services/requests/Features/Offers/schema';

import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useMutation, useQueryClient } from 'react-query';

import { AxiosError } from 'axios';
import { FIsOffersChanges } from 'store/CustomizeApp/Features';
import NotificationMutation from '../Notification';
import React from 'react';
import { useParams } from 'react-router-dom';

const OfferMutation = () => {

	const { id } = useParams();
	const setIsOfferRefresh = useSetAtom(offerAtoms.FIsOfferRefresh);
	const offers = useAtomValue(offerAtoms.FTempOffers);
	const [deleteIds, setDeletIds] = useAtom(offerAtoms.FTempIds);
	const isThereChanges = useAtomValue(FIsOffersChanges);
	const {saveNotifications} = NotificationMutation();

	const queryClient = useQueryClient();
    
	const { mutate: addOfferData } = 
	useMutation<typeSchemas.GetOffersResponse, AxiosError, typeSchemas.GetOffersParams>(
		data => offerApis.createOfferApi(id, data),{
			onSuccess: () => {
				queryClient.invalidateQueries('getOffers');
				setIsOfferRefresh(true);
			}
		});

	const { mutate: editOfferData } = 
	useMutation<typeSchemas.GetOffersResponse, AxiosError, typeSchemas.EditOfferParams>(
		data => offerApis.editOfferApi(id, data.offerID, data), {
			onSuccess: () => {
				queryClient.invalidateQueries('getOffers');
				setIsOfferRefresh(true);
			}
		});

	const { mutate: deleteOfferData } =
	useMutation<null, AxiosError, typeSchemas.DeleteOfferType>(
		(data) => offerApis.deleteOfferApi(id, data.offerID), {
			onSuccess: () => {
				queryClient.invalidateQueries('getOffers');
				setIsOfferRefresh(true);
			}
		});

	const saveOffer = () => {
		if(isThereChanges){
			if(offers.length !== 0){
				offers.map((offer, idx) => {
					if(offer.offerID === 0){
						addOfferData(offer);
					}else{
						editOfferData(offer);
					}
					if(idx === offers.length - 1) {
						if(deleteIds.length !== 0){
							deleteIds.map((id, idx) => {
								deleteOfferData(id);
								idx === deleteIds.length - 1 && (
									saveNotifications()
								);
							});
						}else{
							saveNotifications(); 
						}
					}
				});
			}else{
				setIsOfferRefresh(true);
				saveNotifications();
			}
		}else saveNotifications();
		
	};

	return {saveOffer,};
};

export default OfferMutation;