import { AnalyticsRowSchema, DimensionHeadersSchema, DimensionValuesSchema, GetAnalyticsDataRequest, GetRunReportResponse, MetricHeadersSchema, MetricValuesSchema } from 'services/requests/Telemetry/schema';
import React, { useEffect, useRef, useState } from 'react';
import { getAudienceByCountry, getPageViews, getSessionsByChannel, getTotalUsers } from 'services/requests/Telemetry';

import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import { AxiosError } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMAGES } from 'utility/constant';
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';

import { classNames } from 'utility';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';
import { useQuery } from 'react-query';
import { log } from 'utility/log';

// import MetricsGraph from 'components/Common/MetricsCard/MetricsGraph';
// import MetricsNumber from 'components/Common/MetricsCard/MetricsNumber';
// import { useAtom } from 'jotai';



interface AppSidebarTelemetry {
  appID: number;
  appName: string;
  appImage?: string | undefined;
  
}


const AppSideBarTelemetry: React.FC<AppSidebarTelemetry> = ({appID, appName, appImage}) => {
	const [isNoteVisible, setIsNoteVisible] = useState(true);
  
	const calendarScrollRef = useRef<HTMLDivElement>(null);

	const lowerSmViewPort = useMediaQuery({ maxWidth: 475 });

	const [selectedAppID, setSelectedAppID] = useState(appID.toString());
	const [startingDateRange, setStartingDateRange] = useState<string>('today');
	const [endingDateRange, setEndingDateRange] = useState<string>('today');

	const [displayedStartingDateRange, setDisplayedStartingDateRange] = useState('Loading...');
	const [displayedEndingDateRange, setDisplayedEndingDateRange] = useState('Loading...');
	const [selectedDateRageName, setSelectedDateRangeName] = useState('Loading...');

	const[displayDateSelection, setDisplayDateSelection] = useState<number>(0);
	const[appliedDisplayDateSelection, setAppliedDisplayDateSelection] = useState<number>(0);

	const [activeDateButton, setActiveDateButton] = useState<number>(1);

	const[monthDateNames, ] = useState([
		'January', 'February', 'March', 'April', 'May', 
		'June', 'July', 'August', 'September', 'October', 
		'November', 'December'
	]);
  
	const [previousRemainingMonthNumber, setPreviousRemainingMonthNumber] = useState<number>(0);

	const [previousYearName, setPreviousYearName] = useState('2022');
	const [currentYearName, setCurrentYearName] = useState('2023');
	const [futureYearName, setFutureYearName] = useState('2024');

	const xsViewPort = useMediaQuery({ maxWidth: 420 });

	useEffect(() => {

		const currentWholeDate = new Date();
		const currentYear = currentWholeDate.getFullYear();
		const lastMonth = currentWholeDate.getMonth();
		const currentMonth = currentWholeDate.getMonth() + 1;
		const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;

    
		const dateSetLastMonth = currentWholeDate.getMonth() - 1;
		const dateSetCurrentMonth = currentWholeDate.getMonth();
		const dateSetNextMonth = currentWholeDate.getMonth() + 1;


		setPreviousYearName((currentYear - 1).toString());
		setCurrentYearName((currentYear).toString());
		setFutureYearName((currentYear + 1).toString());


		for(let i = 1; i <= dateSetLastMonth; i++){
			setPreviousMonthName(monthDateNames[i]);
		}
		for(let i = 1; i <= dateSetCurrentMonth; i++){
			setCurrentMonthName(monthDateNames[i]);
		}
		for(let i = 1; i <= dateSetNextMonth; i++){
			setFutureMonthName(monthDateNames[i]);
		}

		const currentDateDay = currentWholeDate.getDate();
    
		const lastDateOfLastMonth = new Date(currentYear, lastMonth, 0).getDate();
		const lastDateOfCurrentMonth = new Date(currentYear, currentMonth, 0).getDate();
		const lastDateOfNextMonth = new Date(currentYear, nextMonth, 0).getDate();

		for(let i = 1; i <= lastDateOfLastMonth; i++){
			previousMonthDateNumbers[i] = i.toString();
		}
    
		for(let i = 1; i <= lastDateOfCurrentMonth; i++){
			currentMonthDateNumbers[i] = i.toString();
		}
    
		for(let i = 1; i <= lastDateOfNextMonth; i++){
			futureMonthDateNumbers[i] = i.toString();
		}

		setCurrentDay(parseInt(currentDateDay.toString()));
    
		setPreviousMonthDateNumbers(previousMonthDateNumbers);
		setCurrentMonthDateNumbers(currentMonthDateNumbers);
		setFutureMonthDateNumbers(futureMonthDateNumbers);
  
		totalUsersRefetch();
		startToYesterdayTotalUsersRefetch();
		todaysTotalUsersRefetch();
		audienceByCountryRefetch();
		pageViewsRefetch();
		sessionsByChannelRefetch();

		if(appliedDisplayDateSelection === 0){

			setDateRangeLimit(0);
			setStartingDateRange('today');
			setEndingDateRange('today');
			setSelectedDateRangeName('TODAY');

			setPreviousRemainingMonthNumber(0);
		}
		else if(appliedDisplayDateSelection === 1){

			setDateRangeLimit(1);
			setStartingDateRange('yesterday');
			setEndingDateRange('today');
			setSelectedDateRangeName('YESTERDAY');
			if(currentDay === 1){
				setPreviousRemainingMonthNumber(lastDateOfLastMonth - 1);
			}
			else if(currentDay > 1){
				setPreviousRemainingMonthNumber(0);
			}
			else{
				setPreviousRemainingMonthNumber(0);
			}

		}
		else if(appliedDisplayDateSelection === 7){
			setDateRangeLimit(7);
			setStartingDateRange('7daysAgo');
			setEndingDateRange('today');
			setSelectedDateRangeName('LAST 7 DAYS');
      
      
			if(currentDay === 1){
				setPreviousRemainingMonthNumber(lastDateOfLastMonth - 6);
			}
			else if(currentDay > 1){
				setPreviousRemainingMonthNumber((lastDateOfLastMonth + currentDay) - 6);
			}
			else{
				setPreviousRemainingMonthNumber(0);
			}
      

		}
		else if(appliedDisplayDateSelection === 14){
			setDateRangeLimit(14);
			setStartingDateRange('14daysAgo');
			setEndingDateRange('today');
			setSelectedDateRangeName('LAST 14 DAYS');
			if(currentDay === 1){
				setPreviousRemainingMonthNumber(lastDateOfLastMonth - 13);
			}
			else if(currentDay > 1){
				setPreviousRemainingMonthNumber((lastDateOfLastMonth + currentDay) - 13);
			}
			else{
				setPreviousRemainingMonthNumber(0);
			}

		}
		else if(appliedDisplayDateSelection === 30){
			setDateRangeLimit(30);
			setStartingDateRange('30daysAgo');
			setEndingDateRange('today');
			setSelectedDateRangeName('LAST 30 DAYS');
			if(currentDay === 1){
				setPreviousRemainingMonthNumber(lastDateOfLastMonth - 29);
			}
			else if(currentDay > 1){
				setPreviousRemainingMonthNumber((lastDateOfLastMonth + currentDay) - 29);
			}
			else{
				setPreviousRemainingMonthNumber(0);
			}

		}
	}, [startingDateRange, endingDateRange]);

	//Total User
	const [totalUserValue, setTotalUserValue] = useState('Loading...');
	const [todaysTotalUserValue, setTodaysTotalUserValue] = useState('Loading...');

	const [totalStartPercentageUserValue, setStartPercentageTotalUserValue] = useState('...%');

	const [totalPercentageUserValue, setPercentageTotalUserValue] = useState('...%');

	//Active User
	const [activeUserValue, setActiveUserValue] = useState('Loading...');
	const [todaysActiveUserValue, setTodaysActiveUserValue] = useState('Loading...');
  
	const [activeStartPercentageUserValue, setStartPercentageActiveUserValue] = useState('...%');

	const [activePercentageUserValue, setPercentageActiveUserValue] = useState('...%');

	//New User
	const [newUserValue, setNewUserValue] = useState('Loading...');
	const [todaysNewUserValue, setTodaysNewUserValue] = useState('Loading...');

	const [newStartPercentageUserValue, setStartPercentageNewUserValue] = useState('...%');

	const [newPercentageUserValue, setPercentageNewUserValue] = useState('...%');
  
	const [countryAudience, setCountryAudience] = useState(['...']);
	const updatedAudienceArray = [...countryAudience];
	const [countryPageViews, setCountryPageViews] = useState(['...']);
	const updatedPageViewsArray = [...countryPageViews];
	const [countryBounceRate, setCountryBounceRate] = useState(['...']);
	const updatedBounceRateArray = [...countryBounceRate];

	const [allPageViews, setAllPageViews] = useState('...');
  
	const [sessionDevice, setSessionDevice] = useState(['...']);
	const updatedDeviceArray = [...sessionDevice];
	const [sessionVisits, setSessionVisits] = useState(['...']);
	const updatedVisitsArray = [...sessionVisits];
	const [sessionAVGTime, setSessionAVGTime] = useState(['...']);
	const updatedAVGTimeArray = [...sessionAVGTime];


  
	const [dateRangeLimit, setDateRangeLimit] = useState(0);
	const [currentDate, setCurrentDate] = useState(new Date());


	const formattedDate = currentDate.toLocaleDateString('en-US', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	});

  
	const futureDate = new Date(currentDate);
	futureDate.setDate(futureDate.getDate() - dateRangeLimit);

	// Format the future date in "month day, year" format
	const formattedFutureDate = futureDate.toLocaleDateString('en-US', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	});
  
	const {data: totalUsers, refetch: totalUsersRefetch} = useQuery<GetRunReportResponse, AxiosError>({
		queryKey: ['totalUsers'],
		queryFn: () => getTotalUsers(selectedAppID, startingDateRange, endingDateRange),
		onSuccess: (data) => {

			if(data?.rows && data?.rows.length > 1){
				setActiveUserValue(data?.rows[1]?.metricValues[0].value);
				setNewUserValue(data?.rows[1]?.metricValues[1].value);
				setTotalUserValue(data?.rows[1]?.metricValues[2].value);

			}
			else{
				setTotalUserValue('No Data found');
				setActiveUserValue('No Data found');
				setNewUserValue('No Data found');
			}


      
		},
		onError: () => {
			setTotalUserValue('Failed to load');
			setActiveUserValue('Failed to load');
			setNewUserValue('Failed to load');
		},
		retry:0

	});

	const {data: startToYesterdayTotalUsers, refetch: startToYesterdayTotalUsersRefetch} = useQuery<GetRunReportResponse, AxiosError>({
		queryKey: ['totalUsers'],
		queryFn: () => getTotalUsers(selectedAppID, startingDateRange, 'yesterday'),
		onSuccess: (data) => {

			if(data?.rows && data?.rows.length > 1){
				setStartPercentageActiveUserValue(data?.rows[1].metricValues[0].value);
				setStartPercentageNewUserValue(data?.rows[1].metricValues[1].value);
				setStartPercentageTotalUserValue(data?.rows[1].metricValues[2].value);

			}
			else{
				setTotalUserValue('No Data found');
				setActiveUserValue('No Data found');
				setNewUserValue('No Data found');
			}


      
		},
		onError: () => {
			setTotalUserValue('Failed to load');
			setActiveUserValue('Failed to load');
			setNewUserValue('Failed to load');
		},
		retry:0

	});

	const {data: todaysTotalUsers, refetch: todaysTotalUsersRefetch} = useQuery<GetRunReportResponse, AxiosError>({
		queryKey: ['totalUsers'],
		queryFn: () => getTotalUsers(selectedAppID, 'today', 'today'),
		onSuccess: (data) => {

			if(data?.rows && data?.rows.length > 1){
				setTodaysActiveUserValue(data?.rows[1].metricValues[0].value);
				setTodaysNewUserValue(data?.rows[1].metricValues[1].value);
				setTodaysTotalUserValue(data?.rows[1].metricValues[2].value);

        
				const formattedStartActiveUserPercentage = parseInt(activeStartPercentageUserValue);
				const formattedStartNewUserPercentage = parseInt(newStartPercentageUserValue);
				const formattedStartTotalUserPercentage = parseInt(totalStartPercentageUserValue);

				const formattedTodayActiveUserPercentage = parseInt(todaysActiveUserValue);
				const formattedTodayNewUserPercentage = parseInt(todaysNewUserValue);
				const formattedTodayTotalUserPercentage = parseInt(todaysTotalUserValue);

				const firstCalculationTodayActiveUserPercentage = formattedStartActiveUserPercentage - formattedTodayActiveUserPercentage;
				const firstCalculationTodayNewUserPercentage = formattedStartNewUserPercentage - formattedTodayNewUserPercentage;
				const firstCalculationTodayTotalUserPercentage = formattedStartTotalUserPercentage - formattedTodayTotalUserPercentage;

				const secondCalculationTodayActiveUserPercentage = (formattedStartActiveUserPercentage + formattedTodayActiveUserPercentage) / 2;
				const secondCalculationTodayNewUserPercentage = (formattedStartNewUserPercentage + formattedTodayNewUserPercentage) / 2;
				const secondCalculationTodayTotalUserPercentage = (formattedStartTotalUserPercentage + formattedTodayTotalUserPercentage) / 2;

				const finalCalculationTodayActiveUserPercentage = (firstCalculationTodayActiveUserPercentage / secondCalculationTodayActiveUserPercentage) * 100;
				const finalCalculationTodayNewUserPercentage = (firstCalculationTodayNewUserPercentage / secondCalculationTodayNewUserPercentage) * 100;
				const finalCalculationTodayTotalUserPercentage = (firstCalculationTodayTotalUserPercentage / secondCalculationTodayTotalUserPercentage) * 100;


        
				if(Number.isNaN(finalCalculationTodayActiveUserPercentage)){
					setPercentageActiveUserValue('0%');
				} else {
					setPercentageActiveUserValue(finalCalculationTodayActiveUserPercentage.toString() + '%');
				}

				if(Number.isNaN(finalCalculationTodayNewUserPercentage)){
					setPercentageNewUserValue('0%');
				} else {
					setPercentageNewUserValue(finalCalculationTodayNewUserPercentage.toString() + '%');
				}
				
				if(Number.isNaN(finalCalculationTodayTotalUserPercentage)){
					setPercentageTotalUserValue('0%');
				} else {
					setPercentageTotalUserValue(finalCalculationTodayTotalUserPercentage.toString() + '%');
				}


			}
			else{
				setTotalUserValue('No Data found');
				setActiveUserValue('No Data found');
				setNewUserValue('No Data found');
			}

      
		},
		onError: () => {
			setTotalUserValue('Failed to load');
			setActiveUserValue('Failed to load');
			setNewUserValue('Failed to load');
		},
		retry:0

	});
  
	const {data: audienceByCountry, refetch: audienceByCountryRefetch} = useQuery<GetRunReportResponse, AxiosError>({
		queryKey: ['audienceByCountry'],
		queryFn: () => getAudienceByCountry(selectedAppID, startingDateRange, endingDateRange),
		onSuccess: (data) => {
			if(data?.rows){

				for (let i = 0; i < data?.rows.length; i++) {
					updatedAudienceArray[i] = data?.rows[i].dimensionValues[0].value;
					updatedPageViewsArray[i] = data?.rows[i].metricValues[0].value;
					updatedBounceRateArray[i] = data?.rows[i].metricValues[1].value;
					const percentBounceRate = parseFloat(updatedBounceRateArray[0]) * 100;
					updatedBounceRateArray[i] = percentBounceRate.toString().split('.')[0] + '%';
				}

				setCountryAudience(updatedAudienceArray);
				setCountryPageViews(updatedPageViewsArray);
				setCountryBounceRate(updatedBounceRateArray);
			}
			else{
				setCountryAudience(['No available data']);
				setCountryPageViews(['']);
				setCountryBounceRate(['']);
			}
		},
		onError: () => {
			setCountryAudience(['Failed to load']);
		},
		retry:0

	});

	const {data: pageViews, refetch: pageViewsRefetch} = useQuery<GetRunReportResponse, AxiosError>({
		queryKey: ['pageViews'],
		queryFn: () => getPageViews(selectedAppID, startingDateRange, endingDateRange),
		onSuccess: (data) => {
			if(data?.rows){
				setAllPageViews('Existing Value found');
			}
			else{
				setAllPageViews('No Existing Data found');
			}
		},
		onError: () => {
			setAllPageViews('Failed to load');
		},
		retry:0

	});

	const {data: sessionsByChannel, refetch: sessionsByChannelRefetch} = useQuery<GetRunReportResponse, AxiosError>({
		queryKey: ['sessionsByChannel'],
		queryFn: () => getSessionsByChannel(selectedAppID, startingDateRange, endingDateRange),
		onSuccess: (data) => {
			if(data?.rows){

				for (let i = 0; i < data?.rows.length; i++) {
					updatedDeviceArray[i] = data?.rows[i].dimensionValues[0].value;
					updatedVisitsArray[i] = data?.rows[i].metricValues[0].value;
					const roundedConversionVisit = parseFloat(updatedVisitsArray[i]).toFixed(1);
					updatedVisitsArray[i] = roundedConversionVisit.toString() + '%';
					// .toString().split('.')[0] + '%';
					updatedAVGTimeArray[i] = data?.rows[i].metricValues[1].value;
          
					const capitalizedString = data?.rows[i].dimensionValues[0].value.charAt(0).toUpperCase() + data?.rows[i].dimensionValues[0].value.slice(1);

					updatedDeviceArray[i] = capitalizedString;


					const percentAVGTime = parseInt(updatedAVGTimeArray[i]);


          
					const min = (Math.floor(percentAVGTime / 60)).toString();
					const sec = (percentAVGTime % 60).toString();
					const hour = (Math.floor(parseInt(min) / 60)).toString();

					if(parseInt(min) === 0){
						updatedAVGTimeArray[i] = sec + 's';
					}
					else if(parseInt(hour) === 0){
						updatedAVGTimeArray[i] = min + 'm ' + sec + 's';
					}
					else{
						updatedAVGTimeArray[i] = hour + 'h ' + min + 'm ' + sec + 's';
					}

					// updatedAVGTimeArray[i] = percentAVGTime.toString();

					// updatedAVGTimeArray[i] = percentAVGTime.toString().split('.')[0];

					// updatedAVGTimeArray[i] = data?.rows[i].metricValues[1].value;
				}
        
				setSessionDevice(updatedDeviceArray);
				setSessionVisits(updatedVisitsArray);
				setSessionAVGTime(updatedAVGTimeArray);
			}
			else{
				setSessionDevice(['No available data']);
				setSessionVisits(['']);
				setSessionAVGTime(['']);
			}
		},
		onError: () => {
			setSessionDevice(['Failed to load']);
		},
		retry:0

	});



	useEffect(() => {

		const interval = setInterval(() => {
			setCurrentDate(new Date());
		}, 1000);


		return () => clearInterval(interval);
	}, []);

  

	useEffect(() => {
		//for Setting Date Range by subtraction

		setDisplayedEndingDateRange(formattedDate);
		setDisplayedStartingDateRange(formattedFutureDate);

	}, [formattedDate, formattedFutureDate]);

	const handleDateRangeOptionClick = (value: number) => {
		setActiveDateButton(value);  
    
		if(value === 1){
			setDisplayDateSelection(0);

		}
		else if(value === 2){
			setDisplayDateSelection(1);

		}
		else if(value === 3){
			setDisplayDateSelection(7);
		}
		else if(value === 4){ 
			setDisplayDateSelection(14);

		}
		else if(value === 5){ 
			setDisplayDateSelection(30);

		}
      
	};

	const handleAppliedDateRange = () => {

		if(appliedDisplayDateSelection === 0){

			setDateRangeLimit(0);
			setStartingDateRange('today');
			setEndingDateRange('today');
			setSelectedDateRangeName('TODAY');


		}
		else if(appliedDisplayDateSelection === 1){

			setDateRangeLimit(1);
			setStartingDateRange('yesterday');
			setEndingDateRange('today');
			setSelectedDateRangeName('YESTERDAY');
			log(currentDay);
			if(currentDay === 1){
				setPreviousRemainingMonthNumber(1);
			}
			else if(currentDay > 1){
				setPreviousRemainingMonthNumber(currentDay - 1);
			}

		}
		else if(appliedDisplayDateSelection === 7){
			setDateRangeLimit(7);
			setStartingDateRange('7daysAgo');
			setEndingDateRange('today');
			setSelectedDateRangeName('LAST 7 DAYS');
			if(currentDay === 1){
				setPreviousRemainingMonthNumber(7);
			}
			else if(currentDay > 1){
				setPreviousRemainingMonthNumber(currentDay - 7);
			}

		}
		else if(appliedDisplayDateSelection === 14){
			setDateRangeLimit(14);
			setStartingDateRange('14daysAgo');
			setEndingDateRange('today');
			setSelectedDateRangeName('LAST 14 DAYS');
			if(currentDay === 1){
				setPreviousRemainingMonthNumber(14);
			}
			else if(currentDay > 1){
				setPreviousRemainingMonthNumber(currentDay - 14);
			}

		}
		else if(appliedDisplayDateSelection === 30){
			setDateRangeLimit(30);
			setStartingDateRange('30daysAgo');
			setEndingDateRange('today');
			setSelectedDateRangeName('LAST 30 DAYS');
			if(currentDay === 1){
				setPreviousRemainingMonthNumber(30);
			}
			else if(currentDay > 1){
				setPreviousRemainingMonthNumber(currentDay - 30);
			}

		}
    
		setAppliedDisplayDateSelection(displayDateSelection);
		totalUsersRefetch();
		startToYesterdayTotalUsersRefetch();
		todaysTotalUsersRefetch();
		audienceByCountryRefetch();
		pageViewsRefetch();
		sessionsByChannelRefetch();
		setDateRangeToggled(!dateRangeToggled);
	};

	const [previousMonthDateNumbers, setPreviousMonthDateNumbers] = useState<string[]>([]);
	const [previousMonthName, setPreviousMonthName] = useState('');
  


	const [currentMonthDateNumbers, setCurrentMonthDateNumbers] = useState<string[]>([]);
	const [currentMonthName, setCurrentMonthName] = useState('');
	const [currentDay, setCurrentDay] = useState<number>(0);

	const [futureMonthDateNumbers, setFutureMonthDateNumbers] = useState<string[]>([]);
	const [futureMonthName, setFutureMonthName] = useState('');




	const calendarPositionToScroll = 190;

	const [scrolledCalendarToPosition, setScrolledCalendarToPosition] = useState(false);
	const scrollCalendarToPosition = () => {
  
		if (calendarScrollRef.current) {
			calendarScrollRef.current.scrollTo(0, calendarPositionToScroll);
		}
	};


	useEffect(() => {
		// if(scrolledCalendarToPosition){
		// }
		// else 
		if(!scrolledCalendarToPosition){
			scrollCalendarToPosition();
		}
	}, [calendarScrollRef.current]);

	const [dateRangeToggled, setDateRangeToggled] = useState(false);

	const showDateRangeButtonClick = () => {
		setDateRangeToggled((prevState) => !prevState);

		setTimeout(() => {
    
			setScrolledCalendarToPosition(true);
		}, 1);

	};

	const cancelDateRangeButtonClick = () => {
		setDateRangeToggled(false);
	};



	return(
		<div className='flex flex-col ml-[30px] max-sm:ml-5 md:ml-0 md:px-10 max-sm:px-5 lg:mt-0 md:-mt-20 max-sm:-mt-20 bg-white mb-10 '>			
			<div className='fixed bg-white w-full h-fit'>
				<div className='flex w-[90%] flex-row lg:justify-start max-sm:justify-start md:justify-center items-center gap-4'>
					<div className='flex'>
						{/* {image && <img src={image} className='h-full'/>} */}
						{/* {lowerSmViewPort ? 
							<img src={appImage ? appImage : IMAGES.PlaceholderIcon} className='h-[110px] w-[150px] pr-10'/>
							:
							<img src={appImage ? appImage : IMAGES.PlaceholderIcon} className='h-[210px] w-[250px] pr-10'/>
						} */}

						<img src={appImage ? appImage : IMAGES.PlaceholderIcon} className='object-contain h-[6rem] w-[6rem]'/>
					</div>

					<div className='flex flex-col'>
						<div>
							<p className='text-[#464255] text-base mt-[5px] font-bold'>{appName}</p>
						</div>

						<div>
							<p className='text-[#464255] text-sm mt-[5px]'>Dashboard Analytics</p>
						</div>
					</div>

					<div className='flex flex-col'>
						{/* <span>Select a Date Range</span> */}
						{/* <button className='fixed mt-[200px] border h-[50px] w-[200px]'> aa</button> */}
					</div>
				</div>
			</div>

			<div className='flex w-full mt-[7rem] pb-6 justify-end md:justify-start pr-6'>
				<button className='flex flex-row border px-2 py-1 hover:bg-[#FFF8F4]' onClick={() => showDateRangeButtonClick()}>
					<img src={IMAGES.DashboardTelemetryCalendarIcon} className='py-[3.3px]' />
					<span className='px-2'>
						{displayedStartingDateRange + ' - ' + displayedEndingDateRange} 
					</span>
					<ArrowDropDownSharpIcon/>
				</button>
			</div>

			<div className={classNames(isNoteVisible ? '' : 'hidden', 'flex flex-col justify-center items-center bg-[#E1E1E1] p-2 mr-6')}>
				<div className='ml-auto'>
					<XMarkIcon className='w-3 h-3 cursor-pointer stroke-[0.25rem]' onClick={() => setIsNoteVisible(!isNoteVisible)}/>
				</div>

				<div className='flex flex-row justify-center items-center w-full gap-3 px-3 text-sm mb-2'>
					<FontAwesomeIcon icon={faCircleInfo} className='w-6 h-6 text-[#3d4f4f]' />

					<p>
						It usually takes 24-48 hours for the data to reflect from the Google Analytics.
					</p>
				</div>

			</div>

			<div className={classNames(`${dateRangeToggled ? '' : 'hidden'}`)}>
       
				<div className='flex flex-row pr-3 sm:pr-6'>
					<div className='border w-[70%]'>
						<ul 
							className={`${activeDateButton === 1 ? 
								'flex flex-row py-2 text-[#E0781D] hover:bg-[#EEECEC] cursor-pointer text-sm sm:text-base' : 
								'flex flex-row py-2 hover:bg-[#EEECEC] cursor-pointer text-sm sm:text-base'}`} 
							onClick={() => handleDateRangeOptionClick(1)}>
								Today {activeDateButton === 1 ? 
								<img 
									src={IMAGES.DashboardTelemetrySelectedDateCheck} 
									className={classNames(xsViewPort ? 'pl-[37px]' : 'pl-[100px]')} 
								/> : <></>}
						</ul>
						<ul 
							className={`${activeDateButton === 2 ?
								'flex flex-row py-2 text-[#E0781D] hover:bg-[#EEECEC] cursor-pointer text-sm sm:text-base': 
								'flex flex-row py-2 hover:bg-[#EEECEC] cursor-pointer text-sm sm:text-base'}`} 
							onClick={() => handleDateRangeOptionClick(2)}>
								Yesterday {activeDateButton === 2 ? 
								<img 
									src={IMAGES.DashboardTelemetrySelectedDateCheck} 
									className={classNames(xsViewPort ? 'pl-[10px]' : 'pl-[70px]')} 
								/> : <></>}
						</ul>
						<ul 
							className={`${activeDateButton === 3 ? 
								'flex flex-row py-2 text-[#E0781D] hover:bg-[#EEECEC] cursor-pointer text-sm sm:text-base': 
								'flex flex-row py-2 hover:bg-[#EEECEC] cursor-pointer text-sm sm:text-base'}`} 
							onClick={() => handleDateRangeOptionClick(3)}>
								Last 7 days {activeDateButton === 3 ? 
								<img 
									src={IMAGES.DashboardTelemetrySelectedDateCheck} 
									className={classNames(xsViewPort ? 'pl-[4px]' : 'pl-[63px]')} 
								/> : <></>}
						</ul>
						<ul 
							className={`${activeDateButton === 4 ? 
								'flex flex-row py-2 text-[#E0781D] hover:bg-[#EEECEC] cursor-pointer text-sm sm:text-base': 
								'flex flex-row py-2 hover:bg-[#EEECEC] cursor-pointer text-sm sm:text-base'}`} 
							onClick={() => handleDateRangeOptionClick(4)}>
								Last 14 days {activeDateButton === 4 ? 
								<img 
									src={IMAGES.DashboardTelemetrySelectedDateCheck} 
									className={classNames(xsViewPort ? 'pr-2 pl-[5px]' : 'pl-[57px]')} 
								/> : <></>}
						</ul>
						<ul 
							className={`${activeDateButton === 5 ? 
								'flex flex-row py-2 text-[#E0781D] hover:bg-[#EEECEC] cursor-pointer text-sm sm:text-base': 
								'flex flex-row py-2 hover:bg-[#EEECEC] cursor-pointer text-sm sm:text-base'}`} 
							onClick={() => handleDateRangeOptionClick(5)}>
								Last 30 days {activeDateButton === 5 ? 
								<img 
									src={IMAGES.DashboardTelemetrySelectedDateCheck} 
									className={classNames(xsViewPort ? 'pr-2 pl-[5px]' : 'pl-[54px]')}
								/> : <></>}
						</ul>
					</div>

					<div className='flex flex-col border w-full py-1'>
						<span className='px-5 mt-1 text-sm sm:text-base'>{selectedDateRageName}</span>
						<div className='py-2 px-3 text-xs sm:text-base'>
							<span className='mr-3 px-1 bg-[#FFF7EA] rounded-md'>
								{displayedStartingDateRange}
							</span>
                    -
							<span className='ml-2 px-1'>
								{displayedEndingDateRange} 
							</span>
						</div>
                  
						<div className='px-1 pl-3 bg-[#F5F5F5]'>
                    
							<div className='px-2 grid grid-cols-7 text-sm sm:text-base'>
								<span>S</span>
								<span>M</span>
								<span>T</span>
								<span>W</span>
								<span>T</span>
								<span>F</span>
								<span>S</span>
							</div>
                    
						</div>

						<div className='sm:px-1 pl-2 sm:pl-3 py-2 h-[250px] overflow-y-scroll text-xs sm:text-base' ref={calendarScrollRef}>
                  
							<div className='pb-2'>
								<span>{previousMonthName + ' '} </span>
								{
									currentMonthName != 'January' ? 
										<span>{currentYearName}</span>
										:
										<span>{previousYearName}</span>
								}
							</div>
							<div className='ml-[-6px] px-2 grid grid-cols-7 pb-6'>
								{previousMonthDateNumbers.map((item, index) => (
                        
									<div key={index} className='py-1'>
                        
                          
										{index === previousRemainingMonthNumber ?
											<span className={'border border-[#E0781D] bg-[#F88D30] rounded-full px-[5px] py-[1px] text-white'} key={index}> {item} </span>
											:
                          
											previousRemainingMonthNumber > 0 ?
                            
											//right end
												index === previousMonthDateNumbers.length - 1? 
													<div className='px-2 bg-[#FFF7EA] w-8' style={{borderRadius: '0 10px 10px 0'}}>
														<span key={index}> {item} </span>
													</div>
													:
												//left end
													index === previousRemainingMonthNumber + 1 ? 
														<div className='px-2 bg-[#FFF7EA]' style={{borderRadius: '10px 0 0 10px'}}>
															<span key={index}> {item} </span>
														</div>
														:
														index <= previousRemainingMonthNumber ?
															<div className='px-2 bg-white'>
																<span key={index}> {item} </span>
															</div>
															:
															<div className='px-2 bg-[#FFF7EA]'>
																<span key={index}> {item} </span>
															</div>
												:
                            
												<span className={'px-2 text-black'} key={index}> {item} </span>
										}
									</div>
                        


								))}
                    
							</div>

							<div className='pb-2'>
								<span>{currentMonthName + ' '}</span>
								<span>{currentYearName}</span>
							</div>
							<div className='ml-[-6px] px-2 grid grid-cols-7 pb-6'>
								{currentMonthDateNumbers.map((item, index) => (
                        
									<div key={index} className='py-1'>
										{ index < currentDay - appliedDisplayDateSelection ?
											<span className='px-2' key={index}> {item} </span>
                          
											:
                          
											index === currentDay - appliedDisplayDateSelection ?
												<span className={'border border-[#E0781D] bg-[#F88D30] rounded-full px-[5px] py-[1px] text-white'} key={index}> {item} </span>
												:
                            
												index > currentDay - appliedDisplayDateSelection && index < currentDay ?
                              
													index === currentDay - 1 ? 
														<div className='px-2 bg-[#FFF7EA] w-8' style={{borderRadius: '0 10px 10px 0'}}>
															<span key={index}> {item} </span>
														</div>
														:
														index === currentDay - appliedDisplayDateSelection + 1 ? 
															<div className='px-2 bg-[#FFF7EA]' style={{borderRadius: '10px 0 0 10px'}}>
																<span key={index}> {item} </span>
															</div>
															:
															<div className='px-2 bg-[#FFF7EA]'>
																<span key={index}> {item} </span>
															</div>
                              
													:

													index === currentDay ?
                                    
														<span className={'border border-[#E0781D] rounded-full px-[5px] py-[1px]'} key={index}> {item} </span>
														:


														<span className={'px-2 text-[#C1C1C1]'} key={index}> {item} </span>
                        
										}
									</div>



								))}
                    
							</div>

							<div className='pb-2'>
								<span>{futureMonthName + ' '}</span>
								{
									currentMonthName != 'December' ? 
										<span>{currentYearName}</span>
										:
										<span>{futureYearName}</span>
								}
							</div>
							<div className='px-2 grid grid-cols-7'>
								{futureMonthDateNumbers.map((item, index) => (
									<span className={'text-[#C1C1C1]'} key={index}> {item} </span>
								))}
                    
							</div>



						</div>


					</div>
				</div>
				<div className='border h-10 w-[96%] text-end px-5 py-1'>
					<span className='px-2 text-[#707070] font-semibold cursor-pointer' onClick={cancelDateRangeButtonClick}>
              Cancel
					</span>
					<span className='px-2 text-[#E0781D] font-semibold cursor-pointer' onClick={() => handleAppliedDateRange()}>
              Apply
					</span>
				</div>
			</div>

			<div className='pt-[20px] grid grid-cols-2 gap-10 max-sm:grid-cols-1 max-sm:gap-7'>
				<div className='flex flex-col w-[90%] border border-gray-200 rounded-lg p-5 '>
					<p className='text-[#464255] text-base mt-[5px] font-bold'>Total Users</p>
					<p className='text-primary text-base mt-[5px] font-bold'>{ totalUserValue }</p>
					<div className='flex flex-row gap-1'>
						{totalPercentageUserValue === '0%' || totalPercentageUserValue === '...%' ?
                
							<img />
                
							:
                
							<img src={IMAGES.TelemetryRateUpIcon} />

						}
						<p className='text-[#464255] text-base mt-[5px] font-bold'>{totalPercentageUserValue}</p>
						<p className='text-[#464255] text-sm mt-[5px]'>Today</p>
					</div>
				</div>
          
				<div className='flex flex-col w-[90%] border border-gray-200 rounded-lg p-5 '>
					<p className='text-[#464255] text-base mt-[5px] font-bold'>Active Users</p>
					<p className='text-primary text-base mt-[5px] font-bold'>{ activeUserValue }</p>
					<div className='flex flex-row gap-1'>
						{activePercentageUserValue === '0%' || activePercentageUserValue === '...%' ?
                
							<img />
                
							:
                
							<img src={IMAGES.TelemetryRateUpIcon} />

						}
						<p className='text-[#464255] text-base mt-[5px] font-bold'>{activePercentageUserValue}</p>
						<p className='text-[#464255] text-sm mt-[5px]'>Today</p>
					</div>
				</div>
          
				<div className='flex flex-col w-[90%] border border-gray-200 rounded-lg p-5 '>
					<p className='text-[#464255] text-base mt-[5px] font-bold'>New Users</p>
					<p className='text-primary text-base mt-[5px] font-bold'>{ newUserValue }</p>
					<div className='flex flex-row gap-1'>
						{newPercentageUserValue === '0%' || newPercentageUserValue === '...%' ?
                
							<img />
                
							:
                
							<img src={IMAGES.TelemetryRateUpIcon} />

						}
						<p className='text-[#464255] text-base mt-[5px] font-bold'>{newPercentageUserValue}</p>
						<p className='text-[#464255] text-sm mt-[5px]'>Today</p>
					</div>
				</div>

				{/* <div className='flex flex-col w-[90%] border border-gray-200 rounded-lg p-5 '>
            <p className='text-[#464255] text-base mt-[5px] font-bold'>User Retention</p>
            <p className='text-primary text-base mt-[5px] font-bold'>6,542</p>
            <div className='flex flex-row gap-1'>
              <img src={IMAGES.TelemetryRateUpIcon} />
              <p className='text-[#464255] text-base mt-[5px] font-bold'>4.6%</p>
              <p className='text-[#464255] text-sm mt-[5px]'>Today</p>
            </div>
          </div> */}

			</div>

			<div className='flex flex-col w-[90%] border border-gray-200 rounded-lg p-5 mt-10'>
				<div className='font-bold font-poppins'>
					<span>AUDIENCE BY COUNTRY</span>
				</div>
				<div className='grid grid-cols-3 w-full mt-6 text-[#969DAB] text-sm font-semibold font-poppins max-sm:text-xs'>
					<span>COUNTRY</span>
					<span>PAGE VIEWS</span>
					<span>BOUNCE RATE</span>
				</div>

				<hr className="border-t border-gray-300 my-2" />
            

				{countryAudience[0] ?
					<div className='grid grid-cols-3 w-full mt-2 max-sm:text-xs'>
						<div>
							{/* <img src={IMAGES.PaypalPay} /> */}
							<span>{countryAudience[0]}</span>
						</div>
						<span>{countryPageViews[0]}</span>
						<span>{countryBounceRate[0]}</span>
					</div>
            
					: <div/>
				}

				{countryAudience[1] ?
					<div className='grid grid-cols-3 w-full mt-2 max-sm:text-xs'>
						<div>
							{/* <img src={IMAGES.PaypalPay} /> */}
							<span>{countryAudience[1]}</span>
						</div>
						<span>{countryPageViews[1]}</span>
						<span>{countryBounceRate[1]}</span>
					</div>
            
					: <div />
				}

				{countryAudience[2] ?
					<div className='grid grid-cols-3 w-full mt-2 max-sm:text-xs'>
						<div>
							{/* <img src={IMAGES.PaypalPay} /> */}
							<span>{countryAudience[2]}</span>
						</div>
						<span>{countryPageViews[2]}</span>
						<span>{countryBounceRate[2]}</span>
					</div>
          
					: <div/>
				}

				{countryAudience[3] ? 
					<div className='grid grid-cols-3 w-full mt-2 max-sm:text-xs'>
						<div>
							{/* <img src={IMAGES.PaypalPay} /> */}
							<span>{countryAudience[3]}</span>
						</div>
						<span>{countryPageViews[3]}</span>
						<span>{countryBounceRate[3]}</span>
					</div>
            
					: <div/>
				}

				{countryAudience[4] ?
					<div className='grid grid-cols-3 w-full mt-2 max-sm:text-xs'>
						<div>
							{/* <img src={IMAGES.PaypalPay} /> */}
							<span>{countryAudience[4]}</span>
						</div>
						<span>{countryPageViews[4]}</span>
						<span>{countryBounceRate[4]}</span>
					</div>
            
					: <div/>
				}

			</div>

			<div className='flex flex-col w-[90%] border border-gray-200 rounded-lg p-5 mt-10'>
				<div className='font-bold font-poppins'>
					<span>SESSIONS BY DEVICE</span>
				</div>
				<div className='grid grid-cols-3 w-full mt-6 text-[#969DAB] text-sm font-semibold font-poppins max-sm:text-xs'>
					<span>DEVICE</span>
					<span>VISITS</span>
					<span>AVG. TIME</span>
				</div>

				<hr className="border-t border-gray-300 my-2" />
            
				{sessionDevice[0] ?
					<div className='grid grid-cols-3 w-full gap-2 mt-2 max-sm:text-xs'>
						<span>{sessionDevice[0]}</span>
						<span>{sessionVisits[0]}</span>
						<span>{sessionAVGTime[0]} </span>
					</div>

					: <div/>
				}

            
            
				{sessionDevice[1] ?
					<div className='grid grid-cols-3 w-full gap-2 mt-2 max-sm:text-xs'>
						<span>{sessionDevice[1]}</span>
						<span>{sessionVisits[1]}</span>
						<span>{sessionAVGTime[1]} </span>
					</div>
            
					: <div/>
				}

				{sessionDevice[2] ?
					<div className='grid grid-cols-3 w-full gap-2 mt-2 max-sm:text-xs'>
						<span>{sessionDevice[2]}</span>
						<span>{sessionVisits[2]}</span>
						<span>{sessionAVGTime[2]} </span>
					</div>

          
					: <div/>
				}
			</div>


			{/* <footer className='sticky bg-white bottom-0 h-[30px]'>
      </footer> */}

			<footer className='h-[30px] max-sm:h-[200px]'>
			</footer>
		</div>
	);
}; 

export default AppSideBarTelemetry;