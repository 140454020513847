import React from 'react';
import { useAtom, useSetAtom } from 'jotai';
import Button from 'components/Common/Buttons/Button';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { FIsAlreadySubscribed } from 'store/CustomizeApp/Payment/subscription';
import { stepperAtom } from 'store/CustomizeApp/Stepper';
import { ActiveStep } from 'store/CustomizeApp/Payment/activeStepAtom';
import { couponCodeApply, saveCardInfo } from 'store/Payment/successPaymentAtom';
import * as subscriptionAtom from '../../../../store/CustomizeApp/Payment/subscription';

const SuccessPaymentCard : React.FC = ( ) => {

	const [, setCurrentStep]                   = useAtom(stepperAtom);
	const [, setActiveStep]                    = useAtom(ActiveStep);
	const setIsAlreadySubscribed               = useSetAtom(FIsAlreadySubscribed);
	const [, setIsSavedCard]                   = useAtom(saveCardInfo);
	const [, setChangePlan]					   = useAtom(subscriptionAtom.changePlanID);
	const [, setCouponAmount]				   = useAtom(subscriptionAtom.couponAmountCode);
	const [, setCouponPercent]				   = useAtom(subscriptionAtom.couponPercentCode);
	const [, setPaymentMethodID]			   = useAtom(subscriptionAtom.paymentMethodID);
	const setCouponApplied		               = useSetAtom(couponCodeApply);
	const setDefaultSelected		           = useSetAtom(subscriptionAtom.isDefaultSelected);

	
	const onClickNext = () => {
		setIsAlreadySubscribed(true);
		setCurrentStep(3);
		setActiveStep(0);
		setChangePlan(false);
		setCouponAmount(0);
		setCouponPercent(0);
		setPaymentMethodID('');
		setIsSavedCard(false);
		setCouponApplied(false);
		setDefaultSelected(false);
	};

	return (
		<div className='bg-white m-4 rounded-[0.5rem] w-[80%] max-sm:w-full max-sm:m-0 shadow-lg'>
			<div className='h-96 flex justify-center items-end'>
				<div className='mb-8 w-full flex-col'>
					<div className='mb-4 flex justify-center'>
						<CheckCircleIcon className='h-28 w-28 text-[#2F8113]'/>
					</div>
					<div>
						<p className='text-[#2F8113] text-2xl text-center font-bold'>
								Payment Successful 
						</p>
						<p className='mt-5 text-gray-600 text-sm text-center'>
								Your payment was successful
						</p>
						<p className='text-gray-600 text-sm text-center'>
								You can now Publish your mobile app
						</p>
					</div>
					<div className='mt-4 flex justify-center'>
						<Button
							variant='primary'
							className='my-5 px-20 py-2 rounded-lg text-white'
							onClick={onClickNext}
						>
								Next
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SuccessPaymentCard;