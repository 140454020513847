import { Button } from 'components/Common/Buttons';
import React, { useLayoutEffect, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import {  useNavigate } from 'react-router-dom';
import { getAllPricing } from 'services/requests/Pricing';

const Pricing: React.FC = () => {
	const navigate = useNavigate();
	const [ pricing, setPricing ] = useState(0);
	const { data } = useQuery('pricing-plans', getAllPricing);

	useLayoutEffect(() => {
		document.body.style.backgroundColor = '#F6F6F6';
	});

	useEffect(() => {
		window.scrollTo(0,0);
	},[]);

	return (
		<div className='py-10 max-md:justify-center max-md:items-center max-md:text-center max-md:p-6 max-sm:justify-center max-sm:items-center max-sm:text-center max-sm:p-6'>
			<div className='flex-row mb-8'>
				<span className='text-[28px] lg:text-[32px] flex justify-center font-semibold text-gray-600 py-2'>Choose your plan</span>
				<span className='text-xs lg:text-sm flex justify-center text-gray-600 py-2'>Our service charges only one fee that covers the entire production process. Plus, we&apos;ll handle <br/>
				publishing your work in all the major stores so that you can focus on creating great content!</span>
			</div>
			<div className='flex max-md:flex-col max-md:justify-center max-md:items-center max-sm:flex-col max-sm:justify-center max-sm:items-center justify-center gap-[30px] lg:gap-[50px] xl:gap-[90px]'>
				<div className=' flex flex-col gap-[28px]'>
					<h1 className='text-[25px] lg:text-[30px] text-primary font-semibold'>
					Create your app now <br />and select a plan at <br />a later time.
					</h1>
					{data?.rows.slice(0,1).map((plan) => (
						<Button key={plan.planID} className='bg-[#3EAB45] py-[25px] w-[255px] lg:w-[355px] text-[14px] lg:text-[16px] font-semibold text-white hover:bg-[#37cc41]' onClick={() => {
							setPricing(plan.planID);
							navigate('/signup');
						}}>START YOUR FREE TRIAL</Button>
					))
					}
				</div>
				<div className='flex max-md:flex-col max-md:justify-center max-md:items-center max-sm:flex-col max-sm:justify-center max-sm:items-center gap-[40px] lg:gap-[50px] xl:gap-[70px] justify-center'>
					{data?.rows.slice(1).map((plan) => (
						<div key={plan.planID} className='group cursor-pointer'>
							<div className='bg-white max-md:w-full max-sm:w-full w-[206px] h-[403px] lg:w-[246px] lg:h-[440px] shadow-lg rounded-[16px] py-4 px-8 flex-row justify-center'>
								<center><img className='w-9 h-[61px] lg:w-16' src={plan.imageURL} alt='Pricing Logo' /></center> 
								<span className='text-md lg:text-xl flex justify-center font-semibold text-gray-600 py-1 group-hover:text-black mt-2'>{plan.name}</span>
								<span className='text-3xl lg:text-4xl flex justify-center font-bold text-gray-600 py-3 lg:py-4 group-hover:text-black'>${plan.price}</span>
								<span className='text-xs lg:text-xs flex text-center font-semibold text-gray-600 py-2 px-[0.47rem] group-hover:text-black'>{plan.rateDescription}</span>
								<span className='text-xs lg:text-sm flex text-center font-light text-gray-500 py-2 pb-7 px-2 group-hover:text-black'>{plan.description}</span>   
								<Button type='submit' className='w-full py-1 lg:py-2 bg-white text-primary rounded-[4px] border-primary group-hover:border-white group-hover:bg-primary group-hover:text-white font-semibold' onClick={() => {
									setPricing(plan.planID);
									navigate('/signup');
								}}>Register</Button>          
							</div>
						</div>
					))}
				</div>
			</div> 
		</div>
	);
};

export default Pricing;
