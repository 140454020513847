import { Button } from 'components/Common/Buttons';
import React, {useState} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import FormInputPassword from 'components/Common/Forms/Password';
import {
	ChangePasswordResponseType,
	ChangePasswordSchema,
} from 'services/requests/EditProfile/schema';
const ResetYourPassword: React.FC = () => {
    const [NewPassword, setNewPassword] = useState('');
	const [ConfirmNewPassword, setConfirmNewPassword] = useState('');
    const { control} = useForm<ChangePasswordResponseType>({
	});

    return( 
    <div className='flex justify-center item-center w-full h-full my-[150px] max-md:flex-shrink'>
        <div className='p-[40px] h-fit w-[532px] mx-8 bg-[#FFFFFF] rounded-[10px] shadow-xl'>
            <Link to={'/VerifyItsYou'} className='no-underline '>
                <button className='text-[#1C180F] text-xs '>&lt; Back</button>
            </Link> 
            <div className='text-center mt-4 mx-4'>
            <p className='text-[#464255] text-[28px] font-bold font-[montserrat] h-fit mb-[27px] leading-[34px]'>Reset Your Password</p>
            <p className='text-[#707070] font-poppins flex mx-7'>Please set a new password for your account.</p>
                </div> 
            <div className='font-light my-8'>
                <Controller
                    control={control}
                    name='newPassword'
                    render={({ field: { name, onChange } }) => (
                        <FormInputPassword
                            onChange={(value) => {
                                onChange(value);
                                setNewPassword(value);
                            }}
                            name={name}
                            placeholder='********'
                            value={NewPassword}
                            type='password'
                            label='New Password'
                            className='font-normal placeholder:text-[11px]'
                        />
                    )}
                />
            </div>
            <div className='font-light mb-6'>
                <Controller
                    control={control}
                    name='confirmPassword'
                    render={({ field: { name, onChange } }) => (
                        <FormInputPassword
                            onChange={(value) =>{
                                onChange(value);
                                setConfirmNewPassword(value);}}
                            name={name}
                            placeholder='********'
                            value={ConfirmNewPassword}
                            type='password'
                            label='Confirm New Password'
                            className='font-normal placeholder:text-[11px]'
                        />
                    )}
                />
            </div>
            <div className='mt-5 pb-5'>
                <Button
                    type='submit'
                    variant='primary'
                    className='w-full py-1.5'
                    disabled={
                        NewPassword !== ConfirmNewPassword ||
                        NewPassword === '' ||
                        ConfirmNewPassword === ''
                    }
                >
                    <span className='text-sm'>Reset Password</span>
                </Button>
            </div>
            <p className='text-[#707070] font-poppins flex text-center mx-16 text-[14px]'>Session will expire on: 9/25/2023, 11:15:41 AM</p>
        </div>
    </div>
)
            }

export default ResetYourPassword;
